// Invite.js
import { useNavigate } from "react-router-dom";
import Menu from "comps/Menu/Menu";
import Footer from "comps/Footer/Footer";
import fairytaleImg from "assets/images/invites/fairytale.png";
import fantasyImg from "assets/images/invites/fantasy.png";
import jungleImg from "assets/images/invites/jungle.png";
import dividerImg from "assets/images/general/divider.svg";
import dividerSmallImg from "assets/images/general/divider_small.svg";
import "./Invite.css";

const cards = [
    {
        src:fairytaleImg,
        type:"fairytale",
        title: "Fairytale Magic"
    },
    {
        src:fantasyImg,
        type:"fantasy",
        title: "Knights & Dragons"
    },
    {
        src:jungleImg,
        type:"jungle",
        title: "Jungle Adventures"
    }
]

const Invite = ()=>{
    // inits
    const navigate = useNavigate();

    // comps
    const Header = ()=>{
        return(
            <div className="flex-col-center hero-container full-width padding-20">
                <Menu page="Free"/>
            </div>
        )
    }
    const Title = ()=>{
        return(
            <div className="flex-col-center margin-20" style={{
                width: "800px",
                maxWidth: "95vw"
            }}>
                <h1 className="font-30 title-font text-center" style={{color:"#1a76d2"}}>
                        Free Personalized Kids Birthday Party Invitation with Name, Face & Age!
                </h1>
                <div className="margin-10" style={{
                    height: "2px",
                    width: "500px",
                    maxWidth:"80vw",
                    backgroundColor: "#1a76d2"
                }}/>
                <h2 className="font-25 title-font text-center" style={{color:"#1a76d2"}}>
                        Lots of clothes, hair styles & skin colors to choose from!
                </h2>
            </div>
            
        )
    }
    const Prevs = ()=>{
        const Prev = ({card})=>{
            const action = ()=>{
                if(window.makeInvite){
                    window.exportData.movie.type = card.type;
                    navigate('/editor/b/1');
                }
                else window.open("/editor/a?invite="+card.type,"_self");
            }
            return(
                <div className="relative flex-col-center margin-10 blue-bg padding-20 b-r-20 link grow-small" onClick={action} style={{
                    width: "400px",
                    maxWidth: "95vw"
                }}>
                    <div className="font-30 text-white ul">
                        {card.title}
                    </div>
                    <img src={card.src} className="full-width"/>
                </div>
            )
        }
        return(
            <div className='flex-row-center flex-wrap margin-10'>
                {cards.map((card, index)=><Prev card={card} key={index}/>)}
            </div>
        )
    }
    // render
    return(
        <div className="flex-col-center">
            {window.makeInvite?"":<>
                <Header/>
                <Title/>
            </>}
            <img src={dividerImg} height="50"/>
            <div className="font-30 text-center margin-10" style={{
                color:"#1a76d2",
                width: "800px",
                maxWidth: "95vw"
            }} >
                Pick the card you want to personalize:
            </div>  
            <Prevs/>
            <img src={dividerSmallImg} className="margin-20" height="30"/>
            <Footer/>
        </div>
    )
}
export default Invite;

// end
