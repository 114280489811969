// Notme.js

const Notme = ()=>{
    document.cookie = "me=false";
    return(
        <div>
            Cookie was unset.
        </div>
    )
}

export default Notme;

// end
