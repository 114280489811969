// Me.js

const Me = ()=>{
    document.cookie = "me=true";
    return(
        <div>
            Cookie was set.
        </div>
    )
}

export default Me;

// end
