// App.js
import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Config from "assets/modules/Config";
import Me from 'comps/Me/Me';
import Notme from 'comps/Notme/Notme';
import Land from 'comps/Land/Land';
import Dashboard from 'comps/Dashboard/Dashboard';
import Editor from 'Editor/Editor';
import Finish from 'comps/Finish/Finish';
import About from 'comps/About/About';
import Pricing from 'comps/Pricing/Pricing';
import Terms from 'comps/Terms/Terms';
import Refund from 'comps/Refund/Refund';
import Privacy from 'comps/Privacy/Privacy';
import MoviePage from 'comps/MoviePage/MoviePage';
// import Checkout from 'comps/Checkout/Checkout';
import Thanks from 'comps/Thanks/Thanks';
import Blog from 'comps/Blog/Blog';
import Faq from 'comps/Faq/Faq';
import Invite from 'comps/Invite/Invite';
import logoImg from "assets/images/general/logo.png";
import Test from 'comps/Test/Test';
import "./App.css";

// comps
const Wait = ()=>{
    return(
        <div className="flex-col-center" style={{
            height:"100vh"
        }}>
            <img src={logoImg} className='logo-breath'/>
        </div>
    )
}
const RouterComp = ()=>{
    return(
        <Router>
            <Routes>
                <Route path='/test' element={<Test />} />
                <Route path='/me' element={<Me />} />
                <Route path='/notme' element={<Notme />} />
                <Route path='/free-birthday-party-invitations' element={<Invite />} />
                <Route path='/faq' element={<Faq />} />
                <Route path='/kids-birthday-ideas-blog' element={<Blog />}/>
                <Route path='/kids-birthday-ideas-blog/:postPath' element={<Blog />}/>
                <Route path='/thanks' element={<Thanks />} />
                {/* <Route path='/checkout' element={<Checkout />} /> */}
                <Route path='/about' element={<About />} />
                <Route path='/pricing' element={<Pricing />} />
                <Route path='/dashboard' element={<Dashboard />} />
                <Route path='/finish' element={<Finish />} />
                <Route path='/movie' element={<MoviePage />} />
                <Route path='/terms' element={<Terms />} />
                <Route path='/refund' element={<Refund />} />
                <Route path='/privacy' element={<Privacy />} />
                <Route path='/' element={<Land />} />
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </Router>
    )
}

// main
const App = ()=>{
    const [wait, setWait] = useState(true);
    const init = async()=>{
        try{
            while(!window.price) await window.sleep(0.2);
            setWait(false);
        }
        catch(err){
            console.log(err.message);
        }
    }
    useEffect(()=>{init()},[])
    return(
        wait?
        <Wait/>
        :
        (window.location.pathname.includes('maker') || window.location.pathname.includes('editor'))?<Editor/>:<RouterComp/>
    )
}

export default App;

// end
