// Header.js
import { useGlobalState } from "../../GlobalState";
import Logo from "comps/Logo/Logo";
import "./Header.css";

const Header = (props)=>{
    //inits
    const [globalState, updateGlobalState] = useGlobalState();
    //comps
    const Squares = ()=>{
        const colors = [
            '#CECECE',
            '#9BBF29',
            '#FE5973',
            '#FFDD5E',
            '#BEE8EA',
            '#D4E993'
        ]
        const Square = (props)=>{
            var color = colors[0];
            if(globalState.stage==props.index) color = colors[props.index];
            return(
                <div className="relative flex-col-center margin-5" style={{
                    width:"35px",
                    height:"50px",
                    backgroundColor: color,
                    borderRadius:"10px 0px 10px 0px"
                }}>
                    <div className="absolute title-font text-white font-30 z-1">
                        {props.index}
                    </div>
                </div>
            )
        }
        return(
            <div className="flex-row-center margin-20">
                <Square index={1} stage={props.stage}/>
                <Square index={2} stage={props.stage}/>
                <Square index={3} stage={props.stage}/>
                <Square index={4} stage={props.stage}/>
                <Square index={5} stage={props.stage}/>
            </div>
        )
    }

    return (
        <div className="sticky-top flex-col-center bg-green full-width-screen z-10">
            <a href="/" target="_blank" className="margin-10" style={{
                    width:"60px",
                    left:"0px"
            }}>
                    <Logo/>
            </a>
        </div>
    )
}
export default Header;

// end
