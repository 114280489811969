// Terms.js
import Menu from "comps/Menu/Menu";
import Footer from "comps/Footer/Footer";
import "./Terms.css";

const Terms = ()=>{
    const Header = ()=>{
        return(
            <div className="flex-col-center hero-container full-width padding-20">
                <Menu/>
            </div>
        )
    }
    const Text = ()=>{
        return(
            <div className='flex-col-center text-dark-blue' style={{width:"90%",maxWidth:"700px"}}>
                <div className='full-width text-center title-font font-30 margin-20'>
                    Terms of Use
                </div>
                <div className="full-width" style={{textAlign:"left"}}>
                    <ol>
                        <li><h6>Introduction</h6>
                            <ol>
                                <li>
                                    The use of the website “ToonKids.com”, a platform which enables you, among other things, to create Videos (the “Website” or the “Service”) is in accordance to the following sections and terms of use (the “Terms”) including the Privacy Policy of the Website and according to any applicable law. By using the Website, You signify that You agree to be bound by the Terms.
                                </li>
                                <li>
                                    The use of the Website is restricted solely to legal purposes and is restricted to individuals who are legally qualified or corporations.
                                </li>
                                <li>
                                    The purpose of the Terms is to regulate the relations between the operator of the Website, TOON KIDS (the “Operator”) and any person and/or entity using and/or accessing the Website and the Service of the Operator. By using the Website, You agree to be bound by the Terms. If You do not agree with all or part of the Terms, please leave the Website and refrain from any use of the Website. The Operator reserves its right to terminate Your use of the Website if You will not comply with any or all of the Terms.
                                </li>
                                <li>
                                    The Terms determine Your access to the Website and will apply to any of Your use in the Website including, but not only, use of data, content and services in the Website or links to other sources of information and on any transmission of data and constitutes the legal basis to any of your actions and/or communications during any use of the Website.
                                </li>
                                <li>
                                    The Operator reserves its right to terminate Your use of the Website if You fail to comply with any or all of the Terms and reserves its right to update the Terms from time to time at its sole discretion and without providing any prior notice. It is clarified that the binding terms will be the Terms available at the Website at the time of Your use of the Website. Therefore and prior to any use of the Website, You are advised to read thoroughly the Terms and the Privacy Policy. If You do not agree with all or part of the Terms, please refrain from any use of the Website.
                                </li>
                                <li>
                                    The Terms constitute the entire agreement between You and the Operator and supersedes all prior and contemporaneous agreements, proposals or representations, written or oral, concerning its subject matter. In the event of any contradiction or inconsistency between the Terms and any content appearing in the Website, the provisions of the Terms shall prevail.
                                </li>
                                <li>
                                    It is clarified that the Operator reserves its right to change and/or terminate the activity of the Website, fully or partially, at its sole discretion and without any prior notice or consent and You will not have any claims against the Operator in such case.
                                </li>
                                <li>
                                    The Service is active and accessible at all hours of the day at every day, and You may use it at all times. However, the activity of the Website may be interrupted from time to time due to errors which are not controlled by the Operator and/or due to maintenance reasons and/or due to any other reason, and You will not have any claims against the Operator in such case.
                                </li>
                                <li>
                                    If You have any questions regarding the Terms and/or Privacy Policy, you may contact the Operator by email: contact@toonkids.com‏. It is clarified that the Operator’s contact details and the Operator’s availability are subject to change at its sole discretion and without any prior notice or consent and You will not have any claims against the Operator in such case.
                                </li>
                                <li>
                                    Accessing the Service is free of charge, though receiving a Video is subject to a fee as will be determined by the operator.
                                </li>
                                <li>
                                    The Operator may suggest, offer and/or recommend certain methods of creating Your Videos. However, the final decision regarding the creation and/or use of a Video is Yours, and therefore it is hereby clarified that the Operator is not liable for any use of the Videos since the Operator serves solely as a platform which helps its Users to create Videos, and the Operator does not control the final version of any Video You create using the Service.
                                </li>
                            </ol>
                        </li>
                        <li><h6>Definitions</h6>
                            <ol>
                                <li>
                                    “Content” means the Website, Videos you create and/or edit, online webpages, the sorting and display of information and any other illustrative form in the above and in mailings.
                                </li>
                                <li>
                                    “Service” means the Website, the user account including its instructions, other related products and services, personal support service if provided, and any other modification and/or amendment of the above as may be made by the Operator from time to time under its sole discretion.
                                </li>
                                <li>
                                    “Videos” means any letters or other documents you form with the help of the Service.
                                </li>
                                <li>
                                    “Account” means the account opened by Your name in the Website including Your registration or any other use of the Service without creating an account.
                                </li>
                                <li>
                                    “Website Errors” means any interruption in the Website’s availability due to reasons which are not directly controlled by the Operator.
                                </li>
                                <li>
                                    “You” or “Users” means, inter alia, individuals and entities authorized by the Operator to use the Service and Your employees, consultants, service providers, agents and/or third parties which You have business with.
                                </li>
                                <li>
                                    “Your Data” means all data and/or information in electronic form which You provide or as collected by the Operator in the Website during Your use of the Website.
                                </li>
                            </ol>
                        </li>
                        <li><h6>Liability and Limitation of Liability</h6>
                            <ol>
                                <li>
                                    The Operator solely enables You to create Videos via the website. However, the final decision regarding the creation of a Video is Yours, and therefore it is hereby clarified that the Operator is not liable for any outcomes of the Videos You create using the Service.
                                </li>
                                <li>
                                    The Operator reserves its rights to act against a User which will breach any of the Terms including, but not only, blocking the User’s access to the Website, deleting Videos and shutting down his Account without prior notice and under its sole, and the User will have no claims against the Operator in such case.
                                </li>
                                <li>
                                    The Operator shall have no liability for any damage and/or direct or indirect punitive damages of any kind incurred to You resulting from Your use of the Website and/or resulting from Your reliance on any Content and Service available in the Website including any creation of Videos.
                                </li>
                                <li>
                                    Videos created using our Service are set to 'public but unlisted' by default. This means that while these videos are not indexed or searchable by the general public, they can be accessed by anyone who has the direct link. It is your responsibility to manage the sharing of these links. By using the Service, you acknowledge and agree to this visibility setting for your created content.
                                </li>
                                <li>
                                    The Operator has no relation and/or connection to any of Your actions using the Service and the Operator is not liable for any of Your actions resulting from Your use of the Service and You will not have any claim against the Operator regarding any damage caused to You resulting from Your use of the Service.
                                </li>
                                <li>
                                    The Operator does not warrant that all links available on the Website, including links available on Mailings sent to You by the Operator, are active and/or will lead to an active website, and the existence of such link shall not be considered as any opinion, recommendation or advice regarding any content appearing in such link. In no case shall the Operator have any liability to You regarding such matter. Without derogating from the above, the Operator shall not be liable for any damages, direct or indirect, caused to You resulting from Your use and/or reliance on information and content appearing in any third-party webpages which their links may be available on the Website.
                                </li>
                                <li>
                                    The Services available on the Website may be interrupted and/or may face errors. The Operator does not warrant that the Website will be immune of any illegal access the Operator’s computers and/or any damages, errors, faults relating to hardware, software and/or communication line and/or any other damage which may be caused to the Operator and/or any of its providers. The Operator shall not be liable for any direct or indirect damage caused to You and/or any third party including to Your assets, including, but not only, any damage resulting from loss of information or content uploaded by You.
                                </li>
                                <li>
                                    The information and Services available on the Website may include inaccuracies or mistakes. The Operator does not warrant that the Service will be uninterrupted or error-free or that the Service or the servers providing the Service will be virus-free or free from other harmful components. The Operator does not warrant that the use or the result of the use of the Service or the Videos You create via the Service will be accurate, correct and/or reliable in any manner.
                                </li>
                                <li>
                                    You hereby agree that the Operator will not be liable for any unauthorized access to Your account including any change in content You uploaded and/or sent, whether received or sent by You or not.
                                </li>
                                <li>
                                    The Operator has no responsibility for any of the Videos You create using the Service since the Operator is solely a platform which assists You in creating Videos, and it is Your responsibility to verify the final result of the Video prior to using it including prior to sending the Video to third parties. Any claims and/or demands You may have regarding the outcomes of the Videos including, but not only, any dispute between You and a third party which received the Videos You create via the Service should be addressed to the third party including such third party.
                                </li>
                                <li>
                                    The Operator enables the User to use its own content during the use of the Service including, but not only, images and audio (“User Content”) for the purpose of creating Videos. The following terms shall apply to the User Content in addition to the Terms.
                                </li>
                                <li>
                                    You hereby warrant to the Operator that You will not upload User Content and will not create Videos which their content and/or purpose is offensive, infringing proprietary rights including content which infringes copyrights or trademarks, pornographic content, content which is illegal to publish due to gag orders issued by a competent court, content which is defaming and/or violating one’s privacy, and computer software and/or computer code  containing viruses, malicious software and harmful applications, passwords, usernames and other identifying data enabling the use of computer software, digital files, websites and services requiring registration of payment, freeware of registration, any information containing harmful, unlawful, obscene, libelous and/or racist content, information which may mislead third parties and/or any information which harms any acceptable norms of using the internet and which may harm the internet users.
                                </li>
                                <li>
                                    It is hereby clarified that the Operator is not liable for any communication between You and third parties which will receive the Video You create.
                                </li>
                                <li>
                                    Should You breach any of the above, by negligence or on purpose, You will be held liable for any loss and damage incurred and/or may be incurred to the Operator which will have the right to terminate Your use of the Website immediately and to block Your access to the Website without prior notice and at the Operator’s sole discretion and You will have no claims against the Operator in such case. Furthermore in such case You will indemnify the Operator, its employees, managers, shareholders and/or anyone on their behalf for all damage, loss, loss of profits, payment and/or expense caused to them including attorney fees and court fees.
                                </li>
                            </ol>
                        </li>
                        <li><h6>Intellectual Property; License of Use of Videos</h6>
                            <ol>
                                <li>
                                    All intellectual property rights, including patent rights, copyright rights, moral rights, rights of publicity, trademark, trade dress and service mark rights, goodwill, trade secret rights and other intellectual property rights as may now exist or hereafter come into existence, are solely reserved to the Operator.
                                </li>
                                <li>
                                    Without derogating from the above, the Website, all the webpages of the Website and their design, and all notices received by the Operator, including email messages, are solely owned by the Operator.
                                </li>
                                <li>
                                    You may not copy, reproduce (except as noted above), publish, transmit, distribute, display, modify, create derivative works from, sell or exploit in any way any of the content appearing in the Website without the Operator’s prior and written consent.
                                </li>
                                <li>
                                    You warrant not to publish the Content or any part of it except as permitted in the Terms, and You warrant not to publish any product and/or output of information, whether tangible or intangible or in any other form, and You will copy and/or duplicate and/or create derivative works and/or modify and/or adapt the Content for publishing, advertising, displaying, performing, transmitting, broadcasting, making available to the public, selling and/or any other action without the Operator’s prior and written consent.
                                </li>
                                <li>
                                    The Operator may, at its sole discretion, add to the Videos its name, logo or any other text.
                                </li>
                                <li>
                                    The ownership and the intellectual property rights of contents of third parties including images displayed in the Website are owned by their proprietors and may be bound to any terms and third party licenses which were granted to the Operator (“Third Party Agreements”). The Operator does not claim any ownership of rights of such content and does not bear any liability for any such content available on the Website. You hereby agree to be bound to any Third Party Agreements regarding Your use of the Website, including any future changes to the Third Party Agreements.
                                </li>
                                <li>
                                    The Operator respects all intellectual property rights of third parties and makes all efforts to not infringe rights of third parties. However, if rights of any third party were mistakenly infringed, please report such infringement to the Operator via email address: contact@toonkids.com.
                                </li>
                                <li>
                                    Videos You create may be used solely for personal non-commercial use. If You wish to use the Videos for commercial purposes, please contact the Operator.
                                </li>
                            </ol>
                        </li>
                        <li><h6>Use of Personal Information</h6>
                            <ol>
                                <li>
                                    The Operator respects the privacy of all users of the Website. Information regarding use of Your information can be found in the Privacy Policy.
                                </li>
                                <li>
                                    Upon registration, You will be requested to login via Your email address in order to allow You to access limited areas in the Website. The Operator and/or anyone on the Operator’s behalf will not be liable for any unauthorized use of Your account and/or personal area by anyone who uses Your details without Your permission.
                                </li>
                            </ol>
                        </li>
                        <li><h6>Mailings</h6>
                            <ol>
                                <li>
                                    The Operator may offer to all its users to receive service notifications and email messages and/or newsletters about updates, improvements and other user notifications regarding the Operator’s activity, including via emails (the “Mailings”).
                                </li>
                                <li>
                                    It is clarified that at any stage You will have the ability to opt out of receiving the Mailings by contacting the Operator and/or by clicking on a link available on the Mailings sent to You by the Operator.
                                </li>
                            </ol>
                        </li>
                        <li><h6>Security</h6>
                            <ol>
                                <li>
                                    The Website is protected and secured using advanced security tools which are intended to ensure safe and secure use of the Website and to protect Your privacy. You hereby warrant that You will not act in any way that will interrupt the activity of the Website including, but not only, theft of information of other users and breaching the security mechanisms of the Website. The Operator will act against You in such case by any means permitted by law including blocking Your access and use of the Website and taking legal actions against You in such case.
                                </li>
                                <li>
                                    Should You breach any of the above, by negligence or on purpose, the Operator will have the right to block Your access to the Website and You will indemnify the Operator, its employees, managers, shareholders and/or anyone on their behalf for all damage, loss, loss of profits, payment and/or expense caused to them including attorney fees and court fees.
                                </li>
                            </ol>
                        </li>
                        <li><h6>Term and Termination</h6>
                            <ol>
                                <li>
                                    This Agreement commences on the day You start using the Website and continues until You refrain from any use of the Website and the Operator’s services.
                                </li>
                                <li>
                                    The Operator may at any time terminate this Agreement with You if: (i) You have breached any provision of the Agreement (or have acted in manner which clearly shows that You do not intend to, or are unable to comply with the provisions of the Agreement); and/or (ii) the Operator is required to do so by law (for example, where the provision of the Operator’s services to You is, or becomes, unlawful); and/or (iii) the provision of the Service is no longer commercially viable. In case of the above the Operator shall, where possible, give reasonable notice of such termination.
                                </li>
                                <li>
                                    The Operator reserves its rights, at its sole discretion, to amend the Terms at any time by publishing such amendments in the Website and notifying of such amendment. Any amendment will become into force immediately upon notifying of such amendment. Your continuation of using the Website will be considered as consent to such amended terms.
                                </li>
                                <li>
                                    The Operator reserves its rights to shut down the Website or any part thereof for without providing any prior notice and in such case the Operator shall have no liability for any damage or loss caused to You or any other third party.
                                </li>
                                <li>
                                    All terms of this agreement referring to intellectual property and limitation of liability shall survive any termination of this agreement.
                                </li>
                            </ol>
                        </li>
                        <li><h6>General Terms, Governing Law and Messages</h6>
                            <ol>
                                <li>
                                    This Agreement, including the Privacy Policy, constitutes the entire agreement between You and the Operator and supersedes all prior and contemporaneous agreements, proposals or representations, written or oral, concerning its subject matter. No modification, amendment, or waiver of any provision of this Agreement shall be effective unless in writing and either signed or accepted electronically by the party against whom the modification, amendment or waiver is to be asserted.
                                </li>
                                <li>
                                    The information and the Service is provided to the User on an as-is basis, and the User agrees that the Operator and its employees, officers, shareholders and/or anyone on the Operator’s behalf shall have no liability for any damages, direct or indirect, caused to You or to any third party on Your behalf resulting from Your use and/or reliance on information available on the Website.
                                </li>
                                <li>
                                    All notices, permissions and approvals hereunder shall be in writing and shall be deemed to have been given upon sending an email with a confirmation of arrival.
                                </li>
                            </ol>
                        </li>
                    </ol>
                </div>
                <div className='full-width text-center title-font font-30 margin-20'>
                    End of Terms of Use
                </div>
                <div className="margin-40"/>
                <div className="margin-40"/>
            </div>
        )
    }
    return (
        <div className="relative full-width-screen flex-col-center">
            <Header/>
            <Text/>
            <Footer/>
        </div>
    )
}
export default Terms;

// end
