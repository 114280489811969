// Close.js
import closeImg from "assets/images/general/close.svg";
import "./Close.css";

const Close = ({action})=>{
    return (
        <img src={closeImg} className="Close" width="50" onClick={action} />
    )
}
export default Close;

// end
