// Footer.js
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Contact from 'comps/Contact/Contact';
import Login from 'comps/Login/Login';
import Logo from "comps/Logo/Logo";
import dragonImg from "assets/images/land/dragon.png";
import unicornImg from "assets/images/land/unicorn.png";
import facebookImg from "assets/images/footer/facebook.svg";
import instaImg from "assets/images/footer/insta.svg";
import youtubeImg from "assets/images/footer/youtube.svg";
import "./Footer.css";

const Footer = ({icons})=>{
    // inits
    const navigate = useNavigate();

    //states
    const [showContact, setShowContact] = useState(false);
    const [showLogin, setShowLogin] = useState(false);

    //functions
    const doShowContact = ()=>{
        setShowContact(true);
        window.setScroll(false);
    }
    const doHideContact = ()=>{
        setShowContact(false);
        window.setScroll(true);
    }
    const doShowLogin = ()=>{
        setShowLogin(true);
        window.setScroll(false);
    }
    const doHideLogin = ()=>{
        setShowLogin(false);
        window.setScroll(true);
    }

    //comps
    const Line = ()=>{
        return(
            <div className="text-white font-10 margin-10">
                |
            </div>
        )
    }
    const Link = ({txt, link, action})=>{
        const goTo = ()=>{
            if(action) action();
            else{
                navigate(link);
                window.scrollTo(0, 0);
            }
        }
        return(
            <div className="text-white font-15 link link-ul" onClick={goTo}>
                {txt}
            </div>
        )
    }
    const Links = ()=>{
        return(
            <div className="flex-row-center flex-wrap">
                <Link txt="Contact Us" action={doShowContact}/>
                <Line/>
                <Link txt="Login" action={doShowLogin}/>
                <Line/>
                <Link txt="Terms of Use" link="/terms"/>
                <Line/>
                <Link txt="Privacy Policy" link="/privacy"/>
                <Line/>
                <Link txt="Refund Policy" link="/refund"/>
            </div>
        )
    }
    const Socials = ()=>{
        const icons = [
            {
                src: facebookImg,
                link: "https://www.facebook.com/toon.kids.official/"
            },
            {
                src: instaImg,
                link: "https://www.instagram.com/toon.kids.official/"
            },
            {
                src: youtubeImg,
                link: "https://www.youtube.com/@toon.kids.official"
            }
        ]
        const Icon = ({icon})=>{
            return <img className="link" src={icon.src} onClick={()=>{window.open(icon.link,"_blank")}} style={{
                width: "30px"
            }}/>
        }
        return(
            <div className='flex-row space-between' style={{
                width: "120px"
            }}>
                {icons.map((icon, index)=><Icon icon={icon} key={index}/>)}
            </div>
        )
    }
    const Dragon = ()=>{
        return(
            <img src={dragonImg} style={{
                width:"20vw",
                maxWidth:"200px",
                position:"absolute",
                right:"0px",
                top:"0px",
                transform:"translate(0%,-100%)"
            }}/>
        )
    }
    const Unicorn = ()=>{
        return(
            <img src={unicornImg} style={{
                width:"20vw",
                maxWidth:"200px",
                position:"absolute",
                left:"0px",
                top:"0px",
                transform:"translate(0%,-100%)"
            }}/>
        )
    }
    
    //render
    return (
        <div className="relative full-width-screen flex-col-center padding-20" style={{
            backgroundColor:"#339dff"
        }}>
            <Links/>
            <div className='margin-5'/>
            <Socials/>
            <div className='margin-5'/>
            <div className="relative" style={{width:"50px"}}>
                <Logo/>
            </div>
            {icons?<Dragon/>:""}
            {icons?<Unicorn/>:""}
            {showContact?<Contact hide={doHideContact} />:""}
            {showLogin?<Login doHideLogin={doHideLogin}/>:""}
        </div>
    )
}
export default Footer;

// end
