// Overlay.js
import "./Overlay.css";

const Overlay = ({closeAction, comp, top})=>{
    return (
        <div className={(top?"flex-col-start-center padding-50":"flex-col-center")+" overlay"}>
            <div className="relative z-1" style={{maxWidth:"95vw"}}>
                {comp}
            </div>   
            <div className='bg-dark-blue' onClick={closeAction}/>
        </div>
    )
}

export default Overlay;

// end
