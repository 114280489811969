const Text = {}

// land page steps
Text.stepsTitles = [
    "Step 1: Pick a Story",
    "Step 2: Customize the Star",
    "Step 3: Sit Back and Enjoy"
];
Text.steps = [
    <div>
        Is your little one a daring dragon rider or a magical fairy princess? Whatever their dream adventure, choose from our selection of exciting stories to bring their imagination to life!
    </div>,
    <div>
        This is where the magic happens! Upload a photo of your child's face and personalize the star of the show with different hairstyles, skin tones, and clothing options. You'll be amazed at how much your kid will love seeing themselves as the hero!
    </div>,
    <div>
        We'll take it from here! In just 10 minutes, we'll create a high-quality animated movie featuring your child as the star! You'll get an email with a link to your custom masterpiece, which you can easily download and share with all your loved ones!
    </div>
]

// land page FAQ
Text.faqTitles = [
    "1. How long does it take to make a movie?",
    "2. Do I need any knowledge in movies or animation to make one?",
    "3. Do I need to pay?",
    "4. If I lose the copy of my movie and the email, can I still get it?",
    "5. Can I share my movie on Facebook, Whatsapp, Email Etc.?",
    "6. Do you offer any refunds?"
];
Text.faq = [
    <div style={{color: "inherit"}}>
        Not long at all! You can have your own customized movie in as little as 4 minutes. That's shorter than the time it takes to brew a good cup of coffee!
    </div>,
    <div style={{color: "inherit"}}>
        Nope, not at all! Our movie maker is designed to be user-friendly and easy-peasy, so anyone can make a movie. You don't need any special skills or knowledge, even your grandma can make one!
    </div>,
    <div style={{color: "inherit"}}>
        We don't believe in hidden fees or sneaky charges. You can create a movie for free and watch {window.autoStart?"half of it":"a short preview"} on the house! If you want to access the Full Movie with the Share & Download options, it's only $4.99 USD. That's less than a fancy cupcake!
    </div>,
    <div style={{color: "inherit"}}>
        Don't worry, we've got you covered! Simply log in using the "Login" link at the bottom of the page with your email address, and you'll be able to access all of your previously created movies. No need to remember any passwords or other details.
    </div>,
    <div style={{color: "inherit"}}>
        Absolutely! Once you've created your movie, you can easily share it on social networks, as well as via email and messaging apps like WhatsApp. Just click the share button and choose the platform you want to share it on. Your friends and family will love seeing your personalized movie!
    </div>,
    <div style={{color: "inherit"}}>
        Oh, you betcha! If for any reason you're not completely over-the-moon with your personalized movie, just give us a holler within 30 days of purchase, and we'll happily refund your money. We take pride in making sure every single one of our customers is thrilled with their movie-making experience. So, no worries, friend! We've got you covered like a blanket on a chilly night.
    </div>
];

// editor stageA
Text.movTitle = [
    "Fairytale Magic",
    "Knights & Dragons",
    "Jungle Adventures",
    "Happy Pirates",
    "Funny Monsters",
    "Mermaids Party"
]
Text.movDesc = [
    <div className="text-white">
        Flying around on a rainbow making unicorn? You got it! We'll play with fairies, gnomes and mermaids! Everyone is invited back to the castle for a big party with cake, presents and dancing!
    </div>,
    <div className="text-white">
        Set forth on a magical odyssey atop a majestic dragon's wings! Journey with wizards, kind ogres, and enchanting fairies. As the adventure unfolds, all roads lead to the castle for a legendary birthday celebration!
    </div>,
    <div className="text-white">
        Embark on a jungle quest! Swing like Tarzan, encounter friendly animals, and explore an Aztec temple. The adventure culminates in a grand birthday celebration!
    </div>
]

export default Text;