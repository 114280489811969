// CheckoutForm.js
import { useEffect, useState, useRef } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useNavigate } from "react-router-dom";
import Globals from "assets/modules/Globals";
import ErrMsg from "comps/ErrMsg/ErrMsg";
import loaderGif from "assets/images/general/loader.gif";

// inits
const checkPaid = httpsCallable(getFunctions(), 'checkPaid');
const checkPromCode = httpsCallable(getFunctions(), 'checkPromCode');

// comps
const Wait = ()=>{
    return(
        <div className="flex-col-center local-overlay z-3 b-r-20" style={{backgroundColor:"rgba(256, 256, 256, .7)"}}>
            <div className="font-30 title-font text-dark-blue text-center">
                Please wait...
            </div>
            <img src={loaderGif} className="margin-10" width="200"/>
        </div>
    )
}
const PromoButton = ({setPromoView})=>{
    return(
        <div className="font-15 link link-ul" onClick={()=>{setPromoView(true)}}>
            I have a promo code
        </div>
    )
}
const Paddle = ({setPromoView, uid, mid, email, transactionId})=>{
    const init = ()=>{
        // checkout settings
        const settings = { //https://developer.paddle.com/paddlejs/methods/paddle-checkout-open
            allowedPaymentMethods:[
                "card",
                "google_pay",
                "paypal",
                "apple_pay"
            ],
            showAddDiscounts: false,
            successUrl: Globals.domain+"/thanks?uid="+uid+"&mid="+mid+"&email="+email,
            displayMode: "inline",
            frameTarget: "checkout-container",
            frameInitialHeight: "450",
            variant: "one-page",
            frameStyle: "width: 100%; max-width: 400px; background-color: transparent; border: none;"
        }
        const checkoutData = {
            settings: settings,
            transactionId: transactionId
        }
        // console.log(JSON.stringify(checkoutData, null, 4));

        // mount 
        // https://developer.paddle.com/paddlejs/methods/paddle-checkout-open
        window.Paddle.Checkout.open(checkoutData);
    } 
    useEffect(() => {
        init();
    }, []);
    
    // render
    return(
        <div className='flex-col-center'>
            <div className="checkout-container"/>
            <PromoButton setPromoView={setPromoView}/>
        </div>
    ) 
}
const Promo = ({setPromoView, uid, mid, email})=>{
    const navigate = useNavigate();
    const [wait, setWait] = useState(false);
    const [errMsg, setErrMsg] = useState();
    const inputRef = useRef();
    const doPromo = async()=>{
        try{
            const code = inputRef.current.value;
            if(code=="") throw Error("Please enter a code.")
            setWait(true);
            window.backFunc = ()=>{
                return true;
            }
            const data = {
                code: code,
                uid: uid,
                mid: mid
            }
            await checkPromCode(data);
            window.open("/finish?uid="+uid+"&mid="+mid+"&email="+email, "_self");
        }
        catch(e){    
            if(e.message=="Error: no-field") setErrMsg("This code has expired.");
            else setErrMsg(e.message);
            setWait(false);
            window.backFunc = ()=>{
                navigate('/editor/f');
            }
        }
    }
    const Back = ()=>{
        return(
            <div className="font-15 link link-ul" onClick={()=>{setPromoView(false)}}>
                Back to payment
            </div>
        )
    }
    return(
        <div className="relative flex-col-center padding-30 b-r-20" style={{width:"400px",maxWidth:"90vw"}}>
            <div className="margin-10"/> 
            <input ref={inputRef} type="text" className="full-width font-20" placeholder="Promo code" style={{border:"solid 2px grey"}}/>
            <div className="margin-10"/>
            <button onClick={doPromo}>
                Submit
            </button>
            <ErrMsg msg={errMsg}/>
            <Back/>
            {wait?<Wait/>:""}
        </div>
    )
}

// main
const CheckoutForm = ({email, uid, mid, transactionId})=>{
    const [wait, setWait] = useState(true);
    const [promoView, setPromoView] = useState(false);
    const init = async()=>{
        const paidRes = await checkPaid({
            uid: uid,
            mid: mid
        })        
        if(paidRes.data.paid) window.open("/finish?uid="+uid+"&mid="+mid+"&email="+email,"_self");
        else setWait(false);
    }
    useEffect(()=>{
        init()
    },[])
    return(
        <>
            {wait?<Wait/>:""}
            {promoView?
                <Promo setPromoView={setPromoView} uid={uid} mid={mid} email={email}/>
                :
                <Paddle setPromoView={setPromoView} uid={uid} mid={mid} email={email} transactionId={transactionId}/>
            }
        </>
    )
}

export default CheckoutForm;

// end
