// SendBut.js
import loaderGif from "assets/images/general/loader.gif";
import "./SendBut.css";

const SendBut = (props)=>{
    return (
        <div className="relative flex-col-center">
            <button className="yellow-button font-20" onClick={props.action} disabled={props.wait}>
                {props.txt}
            </button>
            {props.wait?<img src={loaderGif} className="absolute half-width"/>:""}
        </div>
    )
}
export default SendBut;

// end
