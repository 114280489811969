// EditRouter.js
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useGlobalState } from "./GlobalState";
import Header from "./comps/Header/Header";
import Footer from "./comps/Footer/Footer";
import Land from "./comps/Land/Land";
import StageA from "./comps/StageA/StageA";
import StageB1 from "./comps/StageB1/StageB1";
import StageB2 from "./comps/StageB2/StageB2";
import StageB3 from "./comps/StageB3/StageB3";
import StageB4 from "./comps/StageB4/StageB4";
import StageC from "./comps/StageC/StageC";
import StageD from "./comps/StageD/StageD";
import StageE1 from "./comps/StageE1/StageE1";
import StageE2 from "./comps/StageE2/StageE2";
import StageF from "./comps/StageF/StageF";
// import StageG1 from "./comps/StageG1/StageG1";
// import StageG2 from "./comps/StageG2/StageG2";
// import StageG3 from "./comps/StageG3/StageG3";
import Checkout from "./comps/Checkout/Checkout";

const EditRouter = ()=>{
    //inits
    const [globalState, updateGlobalState] = useGlobalState();
    return (
        <Router>
            <div className="flex-col-center">
                <Header/>
                <div className="margin-10"/>
                <Routes>
                    <Route path='/birthday-movie-maker' element={<Land/>} />
                    <Route path='/editor/a' element={<StageA/>} />
                    <Route path='/editor/b/1' element={globalState.init?<StageB1/>:<Navigate to="/birthday-movie-maker" replace />} />
                    <Route path='/editor/b/2' element={globalState.init?<StageB2/>:<Navigate to="/birthday-movie-maker" replace />} />
                    <Route path='/editor/b/3' element={globalState.init?<StageB3/>:<Navigate to="/birthday-movie-maker" replace />} />
                    <Route path='/editor/b/4' element={globalState.init?<StageB4/>:<Navigate to="/birthday-movie-maker" replace />} />
                    <Route path='/editor/c' element={globalState.init?<StageC/>:<Navigate to="/birthday-movie-maker" replace />} />
                    <Route path='/editor/d' element={globalState.init?<StageD/>:<Navigate to="/birthday-movie-maker" replace />} />
                    <Route path='/editor/e/1' element={globalState.init?<StageE1/>:<Navigate to="/birthday-movie-maker" replace />} />
                    <Route path='/editor/e/2' element={<StageE2/>} />
                    <Route path='/editor/f' element={globalState.init?<StageF/>:<Navigate to="/birthday-movie-maker" replace />} />
                    <Route path='/editor/checkout' element={<Checkout/>} />
                    <Route path="*" element={<Navigate to="/birthday-movie-maker" replace />} />
                </Routes>
                <div className="margin-100"/>
                <Footer/>
            </div>
        </Router>
    )
}

export default EditRouter;

// end
