// Finish.js
import Footer from "comps/Footer/Footer";
import Movie from "comps/Movie/Movie";
import logoImg from "assets/images/general/logo.png";
import confettiImg from "assets/images/general/confeti.svg";
import dividerImg from "assets/images/general/divider.svg";
import "./Finish.css";

const Finish = ()=>{
    // inits
    const email = window.getParam("email");
    const uid = window.getParam("uid");
    const mid = window.getParam("mid");

    // comps
    const Logo = ()=>{
        return(
            <a href="/" target="_blank" className="link grow margin-20">
                <img src={logoImg} width="150"/>
            </a>
        )
    }
    const Confetti = ()=>{
        return(
            <img src={confettiImg} className="absolute full-width" style={{
                top: "0px",
                left: "0px",
                opacity:"0.4"
            }}/>
        )
    }
    const Text = ()=>{
        return(
            <div className="flex-col-center margin-20">
                <div className="font-40 title-font text-dark-blue text-center">
                    🎉 Congratulations! 🎉
                </div>
                <div className="font-25 title-font text-dark-blue text-center">
                    Your movie is being prepared!
                </div>
                <div className="font-20 text-dark-blue text-center">
                It will be ready in just a few minutes - typically around four minutes.<br/>
                Once it’s finished, we’ll send a notification to <u>{email}</u>.<br/>
                If you don’t see the email in your inbox, please check your Spam folder.<br/>
                Feel free to hang out here while we work our magic! 🌈❤️🥰
                </div>
            </div>
        )
    }
    const Mov = ()=>{
        return(
            <div className="relative flex-col-center margin-20" style={{
                width:"700px",
                maxWidth:"90vw"
            }}>
                <Movie uid={uid} mid={mid}/>
            </div>
        )
    }

    // render
    return (
        <div className="relative flex-col-center">
            <div className="relative flex-col-center z-1 padding-20">
                <Logo/>
                <img src={dividerImg} height="50"/>
                <Text/>
                <Mov/>
                <img src={dividerImg} height="50"/>
            </div>
            <Confetti/>
            <Footer/>
        </div>
    )
}

export default Finish;

// end
