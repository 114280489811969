// Checkout.js
import { useState, useEffect } from 'react';
import { useGlobalState } from "../../GlobalState";
import { useNavigate } from "react-router-dom";
import { getFunctions, httpsCallable } from 'firebase/functions';
import Globals from "assets/modules/Globals";
import CheckoutForm from "Editor/comps/CheckoutForm/CheckoutForm";
import ErrMsg from "comps/ErrMsg/ErrMsg";
import loaderGif from "assets/images/general/loader.gif";
import "./Checkout.css";

//global inits
const getTransId = httpsCallable(getFunctions(), 'getTransId');
var uid, mid, email;

//main
const Checkout = ()=>{
    //local inits
    const [globalState, updateGlobalState] = useGlobalState();
    const navigate = useNavigate();
    const [wait, setWait] = useState(true);
    const [globalErrorMsg, setGlobalErrorMsg] = useState();

    // effect
    const init = async()=>{
        if(!window.getParam("uid")) window.open("/birthday-movie-maker","_self");
        else{
            email = window.getParam("email");
            uid = window.getParam("uid");
            mid = window.getParam("mid");
            window.uid = uid;
            window.mid = mid;
            if(!window.transactionId){
                const res = await getTransId({
                    uid: uid,
                    mid: mid
                })
                window.transactionId = res.data.transactionId;
                console.log(window.transactionId);
            }
            setWait(false);
        }
    }
    useEffect(()=>{
        if(globalState.init) updateGlobalState("back", true);
        else updateGlobalState("showFooterButtons", false);
        init();
        updateGlobalState("stage",5);
        updateGlobalState("next", false);
        window.backFunc = ()=>{
            navigate('/editor/e/1');
        }
        window.nextFunc = ()=>{
            return true;
        }  
    },[])

    // comps 
    const Wait = ()=>{
        return(
            <div className="flex-col-center">
                <div className="font-30 title-font text-dark-blue text-center">
                    Please wait...
                </div>
                <img src={loaderGif} className="margin-10" width="200"/>
            </div>
        )
    }
    const Cont = ()=>{
        const Thumb = ()=>{
            const src = Globals.storageDomain+'/users/'+uid+'/movies/'+mid+'/assets/thumb.jpg';
            return <img src={src+"?"+Date.now()} className="full-width b-r-20 margin-10"/>
        }
        const Checkout = ()=>{
            const Title = ()=>{
                const movTitles = {
                    fairytale: "Fairytale Magic",
                    fantasy: "Knights & Dragons",
                    jungle: "Jungle Adventures"
                }
                const toCapital = (str)=>{
                    return ( str.charAt(0).toUpperCase()+str.slice(1) );
                }
                return(
                    <div className="full-width text-dark-blue">
                        <div className="flex row align-start">
                            <div className="font-20 title-font">
                                *
                            </div>
                            <div className='margin-5'/>
                            <div className="font-20 title-font">
                                Only {window.price} For a Limited Time!
                            </div>
                        </div>
                        <div className='margin-10'/>
                        <div className="flex row">
                            <div className="font-20 title-font">
                                *
                            </div>
                            <div className='margin-5'/>
                            <div className="font-20 title-font">
                                30-Day Money Back Guarantee!
                            </div>
                        </div>
                    </div>
                )
            }
            return(
                <div className='relative z-2 flex-col-center bg-creme padding-30 b-r-20 full-width margin-10'>
                    <Title/>
                    <div className='margin-10'/>
                    <CheckoutForm uid={uid} mid={mid} email={email} transactionId={window.transactionId}/>
                </div>
            )
        }
        const PrevButton = ()=>{
            const handleClick = ()=>{
                window.makePreview = true;
                navigate('/editor/e/2');
            }
            return(
                <div className='flex-col-center padding-20 b-r-20 blue-bg full-width'>
                    <div className='font-20 text-white'>
                        Not sure yet? :)
                    </div>
                    <button className="font-20 margin-5" onClick={handleClick}>
                        Get a Free Preview Clip!
                    </button>
                </div>                
            )
        }
        return(
            <div className='relative flex-col-center' style={{
                width: "400px",
                maxWidth: "90vw"
            }}>
                <Thumb/>
                <PrevButton/>
                <Checkout/>
            </div>
        )
    }

    // render
    return wait?(globalErrorMsg?<ErrMsg/>:<Wait/>):<Cont/>
}

export default Checkout;

// end
