// Pricing.js
import Menu from "comps/Menu/Menu";
import Footer from "comps/Footer/Footer";
import StartButton from "comps/StartButton/StartButton";
import confettiImg from "assets/images/general/confeti.svg";
import dividerImg from "assets/images/general/divider.svg";
import dividerSmallImg from "assets/images/general/divider_small.svg";
import "./Pricing.css";

const Pricing = ()=>{
    const Header = ()=>{
        return(
            <div className="flex-col-center hero-container full-width padding-20">
                <Menu page="Pricing"/>
            </div>
        )
    }
    const Confetti = ()=>{
        return(
            <img src={confettiImg} className="absolute full-width" style={{
                top: "0px",
                left: "0px",
                opacity:"0.4"
            }}/>
        )
    }
    const Title = ()=>{
        return(
            <div className="font-40 title-font text-center margin-20" style={{color:"#1a76d2"}}>
                Pricing
            </div>
        )
    }
    const Text = ()=>{
        const TitleA = ()=>{
            return(
                <div className="flex-col" style={{color:"#004975"}}>
                    <div className="font-25 title-font">Create the movie and watch {window.autoStart?"half of it":"a preview"} for FREE!</div>
                    <div className="margin-20"/>
                    <div className="font-25 title-font">If you're hooked and want to unlock the Full Movie with the Download & Share options, it's just {window.price}!</div>
                    <div className="margin-20"/>
                    <div className="font-25 title-font">That's a deal so good, even the movie villains would be jealous!</div>
                </div>
            )
        }
        const TitleA2 = ()=>{
            return(
                <div className="flex-col" style={{color:"#004975"}}>
                    <div className="flex row">
                        <div className="font-25 title-font">
                            *
                        </div>
                        <div className="margin-5"/>
                        <div className="font-25 title-font">
                            Birthday Party Invitation Cards FREE ALWAYS!
                        </div>
                    </div>
                    <div className="margin-20"/>
                    <div className="flex row">
                        <div className="font-25 title-font">
                            *
                        </div>
                        <div className="margin-5"/>
                        <div className="font-25 title-font">
                            Preview Movie for FREE.
                        </div>
                    </div>
                    <div className="margin-20"/>
                    <div className="flex row">
                        <div className="font-25 title-font">
                            *
                        </div>
                        <div className="margin-5"/>
                        <div className="font-25 title-font">
                            Full Movie for only {window.price} with Download & Share options.
                        </div>
                    </div>
                    <div className="margin-20"/>
                    <div className="flex row">
                        <div className="font-25 title-font">
                            *
                        </div>
                        <div className="margin-5"/>
                        <div className="font-25 title-font">
                            30-Day Money Back Guarantee.
                        </div>
                    </div>
                </div>
            )
        }
        const TitleB = ()=>{
            return(
                <div className="font-30 title-font text-center margin-20" style={{color:"#004975"}}>
                    So, what are you waiting for? Let's make some movie magic!
                </div>
            )
        }
        return(
            <div className="font-20 margin-20" style={{color:"#004975", maxWidth:"700px"}}>
                {/* <TitleA/> */}
                <TitleA2/>
                <br/>
                <u>Details:</u>
                <div className="margin-10"/>
                <div className="flex row">
                    <li></li>
                    <div>
                        You can use our movie maker for free! Yes, you heard that right. No hidden costs, no strings attached. It's on the house!
                    </div>
                </div>
                <br/>
                <div className="flex row">
                    <li></li>
                    <div>
                        We know you're skeptical, so we've got your back. You can watch {window.autoStart?"half of":"a short preview of"} the movie for free to make sure it's everything you've ever dreamed of. We don't want you to waste your hard-earned money on something that doesn't give you all the warm fuzzies.
                    </div>
                </div>
                <br/>
                <div className="flex row">
                    <li></li>
                    <div>
                        If you decide that you're ready to take the plunge and unlock the full magic of your movie, it's only a small fee of {window.price}. You'll get access to the full movie and all the download and share options that come with it.
                    </div>
                </div>
                <br/>
                <div className="flex row">
                    <li></li>
                    <div>
                        it's a pay-per-movie model. There are no subscriptions or recurring charges!
                    </div>
                </div>
                <br/>
                <div className="flex row">
                    <li></li>
                    <div>
                        Just in case you're not satisfied, we offer a full refund policy within 30 days of your purchase. No questions asked!
                    </div>
                </div>
                <TitleB/>
            </div>
        )
    }
    return (
        <div className="relative flex-col-center full-width-screen">
            <Header/>
            <div className="relative full-width">
                <div className="relative flex-col-center full-width z-1">
                    <Title/>
                    <img src={dividerImg} height="50"/>
                    <Text/>
                    <StartButton fontSize="25"/>
                    <img src={dividerSmallImg} className="margin-40" height="30"/>
                </div>
                {/* <Confetti/> */}
            </div>
            <Footer/>
        </div>
    )
}

export default Pricing;

// end
