// Globals.js
// init
const Globals = {}

// domain
const domain = "toonkids.com";
Globals.domain = "https://"+domain;
if( window.isDev() ) Globals.domain = "https://dev."+domain;
else if( window.isLocal() ) Globals.domain = "http://localhost:3000";

// storage
var bucketName = "storage";
if( window.isDev() || window.isLocal()) bucketName = "storage-dev";
Globals.storageDomain = "https://"+bucketName+"."+domain
if( window.isLocal() ) Globals.storageDomain = "http://localhost:5002";

// export
export default Globals;