// Editor.js
import { useEffect } from 'react';
import { GlobalState } from './GlobalState';
import EditRouter from './EditRouter';
import "./Editor.css";

window.faceApiInit = false;
window.faceApiLoaded = false;
window.masterHeadSize = 1200;
window.exportData = {
    general:{
        cardToken: null,
        email: null
    },
    movie:{
        type: null,
        name: '',
        age: '',
        cake: null,
        head:{
            eyes: null,
            width: null,
            height: null
        },
        avatar:{
            avatars: 0,
            colors: 0,
            hair: 1,
            hairColor: 0,
            tops: 0
        }
    },
    invite:{
        ampm: "AM"
    }
}
window.localImages = {
    faceImg: null,
    nameImg: null,
    ageImg: null
}
window.backFunc = null;
window.nextFunc = null;
window.setBackFunc = (func)=>{
    window.scrollTo(0, 0);
    window.backFunc = func;
}
window.setNextFunc = (func)=>{
    window.scrollTo(0, 0);
    window.nextFunc = func;
}
const Editor = ()=>{
    const loadFaceApi = async()=>{
        if(!window.faceApiInit){
            window.faceApiInit = true;
            await window.loadScript("/face-api/face-api.js");
            const modelsPath = '/face-api/models';
            await window.faceapi.loadTinyFaceDetectorModel(modelsPath);
            await window.faceapi.loadFaceLandmarkModel(modelsPath);
            window.faceApiLoaded = true;
            console.log("face api loaded");
        }
    }
    useEffect(()=>{
        loadFaceApi();
    },[])
    return (
        <GlobalState>
            <EditRouter/>
        </GlobalState>
    )
}

export default Editor;

// end
