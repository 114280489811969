// Faq.js
import Menu from "comps/Menu/Menu";
import Text from "assets/modules/Text";
import Footer from "comps/Footer/Footer";
import StartButton from "comps/StartButton/StartButton";
import dividerImg from "assets/images/general/divider.svg";
import dividerSmallImg from "assets/images/general/divider_small.svg";
import "./Faq.css";

const Faq = ()=>{
    // comps
    const Header = ()=>{
        return(
            <div className="flex-col-center hero-container full-width padding-20">
                <Menu page="FAQ"/>
            </div>
        )
    }
    const Title = ()=>{
        return(
            <div className="font-40 title-font text-center margin-20" style={{color:"#1a76d2"}}>
                FAQ
            </div>
        )
    }
    const Faqs = ()=>{
        const Question = (props)=>{
            return(
                <div className='flex-col-start full-width'>
                    <div className="font-30 title-font">
                        {Text.faqTitles[props.index]}
                    </div>
                    <div className="font-25">
                        {Text.faq[props.index]}
                    </div>
                </div>
            )
        }
        return(
            <div className='flex-col-center margin-20'  style={{
                width: "900px",
                maxWidth: "90vw"
            }}>
                <Question index={0}/>
                <div className="margin-20"/>
                <Question index={1}/>
                <div className="margin-20"/>
                <Question index={2}/>
                <div className="margin-20"/>
                <Question index={3}/>
                <div className="margin-20"/>
                <Question index={4}/>
                <div className="margin-20"/>
                <Question index={5}/>
            </div>
        )
    }
    const Call = ()=>{
        return(
            <div className="flex-col-center">
                <div className="title-font font-30 text-center">
                    Click the button below to make a magical birthday eCard for your kid!
                </div>
                <StartButton fontSize="30" margin="30"/>
                <img src={dividerSmallImg} className="margin-40" height="30"/>
            </div>
        )     
    }

    // render
    return (
        <div className="flex-col-center">
            <Header/>
            <Title/>
            <img src={dividerImg} height="50"/>
            <Faqs/>
            <div className="margin-20"/>
            <Call/>
            <Footer/>
        </div>
    )
}

// export
export default Faq;

// end
