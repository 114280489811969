// StageB2.js
import { useState, useEffect, useRef } from 'react';
import { useGlobalState } from "../../GlobalState";
import { useNavigate } from "react-router-dom";
import arrowLimg from "assets/images/editor/stageB/arrow-L.svg";
import arrowRimg from "assets/images/editor/stageB/arrow-R.svg";
import loaderGif from "assets/images/general/loader.gif";
import "./StageB2.css";

const StageB2 = ()=>{
    //inits
    const [globalState, updateGlobalState] = useGlobalState();
    const navigate = useNavigate();

    //effect
    useEffect(()=>{
        window.scrollTo(0, 0);
        updateGlobalState("back",true);
        updateGlobalState("next",true);
        window.backFunc = ()=>{
            navigate('/editor/b/1');
        }
        window.nextFunc = ()=>{
            navigate('/editor/b/3');
            updateGlobalState("highNext",false);
        }
        updateGlobalState("highNext",true);
    },[])

    // comps
    const Title = ()=>{
        return(
            <div className="flex-col-center">
                <div className="font-20 title-font text-dark-blue text-center">
                    Picture is on it's side or upside down? Use the curved arrows to rotate it.
                </div>
                <div className="font-20 text-dark-blue text-center">
                    When you are ready click the 'Next' button.
                </div>
            </div>
        )
    }
    const Stage = ()=>{
        //inits
        const [angle, setAngle] = useState(0);
        const faceCanvas = useRef();

        //funcs
        const makeCanvas = ()=>{
            if(window.faceImg){
                const src = window.faceImg;
                const canvas = faceCanvas.current;
                canvas.width = src.width;
                canvas.height = src.height;
                var x = -canvas.width/2;
                var y = -canvas.height/2;
                if(angle==90 || angle==270){
                    canvas.width = src.height;
                    canvas.height = src.width;
                    x = -canvas.height/2;
                    y = -canvas.width/2;
                }
                const ctx = canvas.getContext('2d');
                ctx.fillRect(0,0,canvas.width,canvas.height);
                ctx.translate(canvas.width/2, canvas.height/2);
                ctx.rotate(angle * (Math.PI/180));
                ctx.drawImage(src,x,y);
                window.faceCanvas = faceCanvas.current;
            }
        }

        //effects
        useEffect(makeCanvas,[angle]);

        //comps
        const FaceCanvas = ()=>{
            const prevSize = 500;
            return(
                <canvas ref={faceCanvas} width="0" height="0" className="b-r-20" style={{
                    width: prevSize+"px",
                    maxWidth: "90vw"
                }}/>
            )
        }
        const Arrow = (props)=>{
            const doRot = ()=>{
                var newAngle;
                if(props.left){
                    if(angle==90) newAngle = 0;
                    else if(angle==0) newAngle = 270;
                    else newAngle = angle-90;
                }
                else{
                    if(angle==270) newAngle = 0;
                    else newAngle = angle+90;
                }
                setAngle(newAngle);
            }
            return(
                <img src={props.left?arrowLimg:arrowRimg} onClick={doRot} width="60" className="padding-10 margin-10 absolute blue-bg b-r-20 link grow" style={{
                    top:"0px",
                    left: props.left?"0px":"auto",
                    right: props.left?"auto":"0px"
                }}/>
            )
        }

        //render
        return(
            <div className="relative flex-col-center margin-20">
                <FaceCanvas/>
                <Arrow left={true} />
                <Arrow left={false} />
            </div>
        )
    }

    //render
    return (
        <div className="flex-col-center">
            <Title/>
            <Stage/>
        </div>
    )
}
export default StageB2;

// end
