// MoviePage.js
import Movie from "comps/Movie/Movie";
import Footer from "comps/Footer/Footer";
import logoImg from "assets/images/general/logo.png";
import confettiImg from "assets/images/general/confeti.svg";
import dividerImg from "assets/images/general/divider.svg";
import "./MoviePage.css";

const MoviePage = ()=>{
    const uid = window.getParam("uid");
    const mid = window.getParam("mid");
    const Logo = ()=>{
        return(
            <a href="/" target="_blank" className="link grow margin-20">
                <img src={logoImg} width="150"/>
            </a>
        )
    }
    const Confetti = ()=>{
        return(
            <img src={confettiImg} className="absolute full-width" style={{
                top: "0px",
                left: "0px",
                opacity:"0.4"
            }}/>
        )
    }
    const Mov = ()=>{
        return(
            <div className="relative flex-col-center" style={{
                width:"700px",
                maxWidth:"90vw"
            }}>
                <Movie uid={uid} mid={mid}/>
            </div>
        )
    }
    return (
        <div className="relative flex-col-center">
            <div className="relative flex-col-center z-1 padding-20">
                <Logo/>
                <img src={dividerImg} height="50"/>
                <div className="margin-10"/>
                <Mov/>
                <div className="margin-10"/>
                <img src={dividerImg} height="50"/>
                <div className="margin-100"/>
            </div>
            <Confetti/>
            <Footer/>
        </div>
    )
}
export default MoviePage;

// end
