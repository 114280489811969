// Land.js
import { useEffect } from 'react';
import { useGlobalState } from "../../GlobalState";
import { useNavigate } from "react-router-dom";
import togetherImg from "assets/images/general/together.png";
import "./Land.css";

const Land = ()=>{
    const navigate = useNavigate();
    const [globalState, updateGlobalState] = useGlobalState();
    useEffect(()=>{
        updateGlobalState("highNext",true);
        updateGlobalState("next", true);
        window.nextFunc = ()=>{
            navigate('/editor/a');
            updateGlobalState("highNext",false);
        }
    },[])
    const Title = ()=>{
        return(
            <div className="flex-col-center" style={{maxWidth:"95vw"}}>
                <h1 className="font-30 title-font text-dark-blue text-center">
                    Craft a Magical Birthday Tale with Toon Kids Movie Maker!
                </h1>
                <div className="margin-10"/>
                <h2 className="font-25 title-font text-dark-blue text-center">
                    Turn your child into the star of their own animated adventure!
                </h2>
                <div className="margin-20"/>
                <div className="font-30 text-dark-blue text-center">
                    Click on the 'Next' button at the bottom to start!
                </div>
            </div>
        )
    }
    return (
        <div className="flex-col-center">
            <Title/>
            <img src={togetherImg} className="margin-20" style={{width:"500px", maxWidth:"90%"}}/>
        </div>
    )
}
export default Land;

// end
