// index.js
import ReactDOM from 'react-dom/client';
import App from 'App/App.js';
import 'css/main.css';
import 'css/flex.css';
import 'css/borders.css';
import "css/dimensions.css";
import "css/positions.css";
import "css/special.css";
import "css/text.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);

//end