// StageC.js
import { useState, useEffect, useRef } from 'react';
import { useGlobalState } from "../../GlobalState";
import { useNavigate } from "react-router-dom";
import loaderGif from "assets/images/general/loader.gif";
import "./StageC.css";

const globals = {}

const init = async()=>{
    const parseImage = async(path)=>{
        const images = [];
        const img = await window.loadImage(path);
        const size = img.height;
        const canvas = window.canvas;
        canvas.width = canvas.height = size;
        const ctx = canvas.getContext('2d');
        ctx.resetTransform();
        for (var i = 0; i < (img.width/size); i++) {
            ctx.clearRect(0,0,size,size);
            ctx.drawImage(img,i*size,0,size,size,0,0,size,size);
            images.push( await window.img4src( canvas.toDataURL() ) );
            images[i].style.width = "100%";
            if( path.includes("/hair/front/") ){
                images[i].style.position = "absolute";
                images[i].style.zIndex = "2";
            }
        }
        globals.count++;
        return Promise.resolve(images);
    }
    globals.count = 0;
    const images = {}
    const prefix = "/images/editor/stageC";
    //skin
    images.skin = await parseImage(prefix+"/general/skin.png");
    //ears
    images.earsL = await parseImage(prefix+"/general/earsL.png");
    images.earsR = await parseImage(prefix+"/general/earsR.png");
    //tops
    images.tops = await parseImage(prefix+"/general/tops.png");
    //hair
    images.hair = {}
    images.hair.colors = await parseImage(prefix+"/hair/colors.png");
    images.hair.head = await window.loadImage(prefix+"/hair/head.png");
    images.hair.head.style.width = "100%";
    images.hair.head.style.position = "absolute";
    images.hair.head.style.zIndex = "1";
    images.hair.back = [];
    images.hair.front = [];
    for (var i = 1; i < 5; i++){
        images.hair.back.push( await parseImage(prefix+"/hair/back/"+i+".png") );
        images.hair.front.push( await parseImage(prefix+"/hair/front/"+i+".png") );
    }
    //avatars
    const types = [
        "fairytale",
        "fantasy",
        "jungle"
    ]
    images.avatars = {}
    for (let index = 0; index < types.length; index++) {
        const type = types[index];
        images.avatars[type] = [];
        for (var i = 1; i < 7; i++) images.avatars[type].push( await parseImage(prefix+"/"+type+"/"+i+".png") );
    }
    // assign images
    globals.images = images;
    console.log("Avatar images loaded.");
}
if(window.location.pathname.includes('maker') || window.location.pathname.includes('editor')) init();

const StageC = ()=>{
    // inits
    const [globalState, updateGlobalState] = useGlobalState();
    const navigate = useNavigate();

    // functions
    // effect
    useEffect(()=>{
        window.scrollTo(0, 0);
        updateGlobalState("stage",4);
        updateGlobalState("next", false);
        window.backFunc = ()=>{
            navigate('/editor/b/4');
        }
        window.nextFunc = ()=>{
            navigate('/editor/d');
        }
    },[])

    // comps
    const Title = ()=>{
        return(
            <div className="flex-col-center" style={{maxWidth:"95vw"}}>
                <div className="font-30 title-font text-dark-blue text-center">
                    Perfect! Now let's choose an avatar!
                </div>
                <div className="font-20 text-dark-blue text-center">
                    Click the buttons at the corners to see the different options.
                </div>
            </div>
        )
    }
    const Stage = ()=>{
        // declare
        const [wait, setWait] = useState(true);
        const exportData = window.exportData.movie.avatar;
        const [avatarType, setAvatarType] = useState(exportData.avatars);
        const [skinColor, setSkinColor] = useState(exportData.colors);
        const [hairType, setHairType] = useState(exportData.hair);
        const [hairColor, setHairColor] = useState(exportData.hairColor);
        const [top, setTop] = useState(exportData.tops);
        const stageRef = useRef();

        // functions
        const initStage = async()=>{
            while(!globals.images) await window.sleep(1);
            setWait(false);
            drawStage();          
        }
        const drawStage = async()=>{
            while(!globals.images) await window.sleep(1);
            if(avatarType!=null && skinColor!=null && hairType!=null && hairColor!=null && top!=null){
                const canvas = stageRef.current;
                if(canvas){
                    const headSize = 400;
                    const factor = headSize/window.masterHeadSize;
                    const headWidth = window.exportData.movie.head.width;
                    const headYPos = 145;
                    const earPosY = window.exportData.movie.head.eyes;
                    //stage
                    var x,y,width,height,img;
                    const shiftY = 0;
                    const ctx = canvas.getContext('2d');
                    ctx.clearRect(0,0,canvas.width,canvas.height);
                    //hair back
                    img = globals.images.hair.back[ hairColor ][ hairType ];
                    const hairSize = headWidth*1.1;
                    const hairShift = (hairSize/3.4);
                    x = (canvas.width/2)-(hairSize/2);
                    y = headYPos-hairShift;
                    width = height = hairSize;
                    ctx.drawImage(img,x,y-shiftY,width,height);
                    //avatar
                    img = globals.images.avatars[window.exportData.movie.type][ skinColor ][avatarType];
                    width = height = img.naturalWidth;
                    x = (canvas.width/2)-(width/2);
                    y = canvas.height-height;
                    ctx.drawImage(img,x,y-shiftY,width,height);
                    //left ear
                    img = globals.images.earsL[skinColor];
                    width = height = headSize/5;
                    x = (canvas.width/2)-((headWidth*factor)/2)-(width/1.4);
                    y = headYPos+(earPosY*factor)-(height/5);
                    ctx.drawImage(img,x,y-shiftY,width,height);
                    //right ear
                    img = globals.images.earsR[skinColor];
                    width = height = headSize/5;
                    x = (canvas.width/2)+((headWidth*factor)/2)-(width/3.8);
                    y = headYPos+(earPosY*factor)-(height/5);
                    ctx.drawImage(img,x,y-shiftY,width,height);
                    //head
                    img = window.localImages.faceImg;
                    x = (canvas.width/2)-(headSize/2);
                    y = headYPos;
                    width = height = headSize;
                    ctx.drawImage(img,x,y-shiftY,width,height);
                    //hair front
                    img = globals.images.hair.front[ hairColor ][ hairType ];
                    x = (canvas.width/2)-(hairSize/2);
                    y = headYPos-hairShift;
                    width = height = hairSize;
                    ctx.drawImage(img,x,y-shiftY,width,height);
                    //top
                    img = globals.images.tops[top];
                    width = height = headWidth*0.6;
                    const topShift = (height/2);
                    x = (canvas.width/2)-(width/2);
                    y = headYPos-topShift;
                    ctx.drawImage(img,x,y-shiftY,width,height);
                    updateGlobalState("next", true);
                }
            }        
        }

        // effetcs
        useEffect(()=>{initStage()},[]);
        useEffect(()=>{
            // asign to export data
            const exportData = window.exportData.movie.avatar;
            if(avatarType!=null) exportData.avatars = avatarType;
            if(skinColor!=null) exportData.colors = skinColor;
            if(hairType!=null) exportData.hair = hairType;
            if(hairColor!=null) exportData.hairColor = hairColor;
            if(top!=null) exportData.tops = top;
            //draw stage
            drawStage();
        },[avatarType,skinColor,hairType,hairColor,top])

        // comps
        const Wait = ()=>{
            const ProgBar = ()=>{
                // inits
                const [width, setWidth] = useState("10px");
                const progBarRef = useRef();
                // functions
                const doProg = ()=>{             
                    const newWidth = (globals.count/31)*280;
                    if(newWidth<280) setTimeout(doProg,500);
                    setWidth(newWidth.toString()+"px");
                }
                // effect
                useEffect(doProg,[])
                // return
                return(
                    <div className="full-width b-r-20 grey-bg" style={{width:"300px",height:"20px"}}>
                        <div ref={progBarRef} className="blue-bg" style={{
                            width: width,
                            height:"20px",
                            borderRadius:"20px 0px 0px 20px"
                        }} />
                    </div>
                )
            }
            return(
                <div className="absolute flex-col-center padding-40 b-r-20 margin-20" style={{top:"0px"}}>
                    <div className="font-30 title-font text-white text-center">
                        Please wait...
                    </div>
                    <img src={loaderGif} className="margin-10" width="100"/>
                    <ProgBar/>
                </div>
            )
        }
        const Buttons = ()=>{
            const [overlay, setOverlay] = useState(false);
            const showOverlay = (type)=>{
                setOverlay(type);
            }
            const hideOverlay = ()=>{
                setOverlay(false);
            }
            const Button = (props)=>{
                const myRef = useRef();
                const handleClick = ()=>{
                    showOverlay(props.type);
                }
                const HairColorButton = ()=>{
                    const myRef = useRef();
                    const handleClick = (e)=>{
                        e.stopPropagation();
                        showOverlay("hairColor");
                    }
                    useEffect(()=>{
                        myRef.current.appendChild( globals.images.hair.colors[ hairColor ] );
                    })
                    return(
                        <div className="absolute link flex-col-center blue-bg" onClick={handleClick} style={{
                            padding:"2px",
                            borderRadius: "10px",
                            width:"40%",
                            left:"0px",
                            bottom:"0px",
                            transform:"translate(0%,100%)"
                        }}>
                            <div className="relative flex-col-center full-width grow">
                                <div ref={myRef} className="relative flex-col-center full-width"/>
                            </div>
                        </div>
                    )
                }
                useEffect(()=>{
                    switch(props.type) {
                        case "hair":
                            myRef.current.appendChild( globals.images.hair.back[ hairColor ][ hairType ] );
                            myRef.current.appendChild( globals.images.hair.head );
                            myRef.current.appendChild( globals.images.hair.front[ hairColor ][ hairType ] );
                            break;
                        case "skin":
                            myRef.current.appendChild( globals.images.skin[ skinColor ] );
                            break;
                        case "skin":
                            myRef.current.appendChild( globals.images.skin[ skinColor ] );
                            break;
                        case "top":
                            myRef.current.appendChild( globals.images.tops[ top ] );
                            break;
                        case "avatar":
                            myRef.current.appendChild( globals.images.avatars[ window.exportData.movie.type ][ skinColor ][avatarType] );
                            break;
                        default:
                            break;
                    }
                },[])
                return(
                    <div className="absolute link flex-col-center b-r-30 blue-bg" onClick={handleClick} style={{
                        width:"25%",
                        top:props.top?"0px":"auto",
                        bottom:props.bottom?"0px":"auto",
                        left:props.left?"0px":"auto",
                        right:props.right?"0px":"auto",
                    }}>
                        <div className="relative flex-col-center full-width grow padding-5">
                            <div ref={myRef} className="relative flex-col-center full-width z-1"/>
                        </div>
                        {(props.type=="hair" && !wait)?<HairColorButton/>:""}
                    </div>
                )
            }
            const Overlay = ()=>{
                const Button = (props)=>{
                    const myRef = useRef();
                    const handleClick = ()=>{
                        switch(props.type){
                            case "hair":
                                setHairType(props.index);
                                break;
                            case "hairColor":
                                setHairColor(props.index);
                                break;
                            case "skin":
                                setSkinColor(props.index);
                                break;
                            case "top":
                                setTop(props.index);
                                break;
                            case "avatar":
                                setAvatarType(props.index);
                                break;
                            default:
                                break;
                        }
                        hideOverlay();
                    }
                    useEffect(()=>{
                        if(props.type=="hair"){
                            myRef.current.appendChild( globals.images.hair.back[ hairColor ][ props.index ].cloneNode() );
                            myRef.current.appendChild( globals.images.hair.head.cloneNode() );
                            myRef.current.appendChild( globals.images.hair.front[ hairColor ][ props.index ].cloneNode() );
                        }
                        else myRef.current.appendChild( props.image.cloneNode() );
                    },[])
                    return(
                        <div className="relative link flex-col-center b-r-40 blue-bg margin-5 z-1" onClick={handleClick} style={{
                            width:"25%",
                        }}>
                            <div className="relative flex-col-center full-width grow padding-5">
                                <div ref={myRef} className="relative flex-col-center full-width"/>
                            </div>
                        </div>
                    )
                }
                var buttons;
                switch(overlay){
                    case "hair":
                        buttons = globals.images.hair.back[ hairColor ];
                        break;
                    case "hairColor":
                        buttons = globals.images.hair.colors;
                        break;
                    case "skin":
                        buttons = globals.images.skin;
                        break;
                    case "top":
                        buttons = globals.images.tops;
                        break;
                    case "avatar":
                        buttons = globals.images.avatars[window.exportData.movie.type][ skinColor ];
                        break;
                    default:
                        break;
                }
                return(
                    <div className="local-overlay flex-col-center z-5" >
                        <div className="flex-row-center flex-wrap">
                            { buttons.map((image,index)=><Button image={image} type={overlay} index={index} key={index}/>) }
                        </div>
                        <div className="bg-dark-blue" onClick={hideOverlay}/>
                    </div>
                )
            }
            return(
                <div className="absolute full-width full-height">
                    <Button type="hair" top={true} left={true}/>
                    <Button type="skin" bottom={true} left={true}/>
                    <Button type="top" top={true} right={true}/>
                    <Button type="avatar" bottom={true} right={true}/>
                    {overlay?<Overlay/>:""}
                </div>
            )
        }

        // render
        return(
            <div className="relative flex-col-center padding-10 bg-dark-blue-2 b-r-20 margin-20" style={{
                width:"500px",
                maxWidth:"90vw"
            }}>
                <div className="relative flex-col-center full-width">
                    <canvas ref={stageRef} width="1000" height="1200" className="full-width" />
                    {wait?<Wait/>:<Buttons/>}
                </div>
            </div>
        )
    }

    //render
    return (
        <div className="flex-col-center">
            <Title/>
            <Stage/>
        </div>
    )
}

export default StageC;

// end
