// Privacy.js
import Menu from "comps/Menu/Menu";
import Footer from "comps/Footer/Footer";
import "./Privacy.css";

const Privacy = ()=>{
    const Header = ()=>{
        return(
            <div className="flex-col-center hero-container full-width padding-20">
                <Menu/>
            </div>
        )
    }
    const Text = ()=>{
        return(
            <div className='flex-col-center text-dark-blue' style={{width:"90%",maxWidth:"700px"}}>
                <div className='full-width text-center title-font font-30 margin-20'>
                    Privacy Policy
                </div>
                <div className="full-width font-20" style={{textAlign:"left"}}>
                    This privacy policy (the "Privacy Policy") is an integral part of the Terms of Use and both documents should be read together.
                    <br/><br/>
                    The processing and collection of personal data is governed by the General Data Protection Regulation (the “GDPR”), and whenever you’ll provide the Operator such information, the Operator will only use your information in line with all applicable data protection laws, including the GDPR. At all times You are in control of Your information, and You can withdraw Your consent to provide Your information to the Operator.
                    <br/><br/>
                    The data controller of the Website is TOON KIDS LLC (“the Operator") which is the entity which decides how Your personal information is processed and for what purposes as detailed in the Privacy Policy.
                    <br/>
                    <ol>
                        <li><h6>General</h6>
                            During Your use of the website, the following information about You will be collected:
                            <ul>
                                <li>
                                    Information You chose voluntarily to give Us including identifiable information such as your name and email;
                                </li>
                                <li>
                                    User content which You will send Us;
                                </li>
                                <li>
                                    Non-personal information including location data, IP address, device data such as mobile identifiers, carrier network information, the mobile website or mobile app used and cookie or pixel tag data.
                                </li>
                            </ul>
                        </li>
                        <li><h6>Your Rights Regarding Your Personal Information</h6>
                            <ol>
                                <li>
                                    Under the GDPR and subject to different exemptions, You have the following rights:
                                    <ul>
                                        <li>
                                            To access Your personal data and receive a copy of Your personal data upon Your request;
                                        </li>
                                        <li>
                                            To rectify, erase or restrict Your information upon Your request (right to be forgotten);
                                        </li>
                                        <li>
                                            To object to the processing of Your information;
                                        </li>
                                        <li>
                                            To request that the Operator will provide You with Your personal information and, if possible, to transmit Your data directly to another data controller (right to data portability).
                                        </li>
                                        <li>
                                            To withdraw Your consent to processing and the processing at any time;
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    No fees are required for exercising any of the above rights. However and subject to the GDPR, the Operator may charge You a reasonable fee including administration fees if Your request is repetitive or excessive or demands the Operator to bear excessive and/or expensive efforts.
                                </li>
                            </ol>
                        </li>
                        <li><h6>Database and Use of Information</h6>
                            <ol>
                                <li>
                                    All collected information as stated above is stored in the Operator’s database. With accordance to Your consent, the Operator warrants to use such information for the following purposes:
                                    <ul>
                                        <li>
                                            Enabling convenient and efficient use;
                                        </li>
                                        <li>
                                            Improving and enrich existing services and content;
                                        </li>
                                        <li>
                                            Modify and/or remove existing services and content;
                                        </li>
                                        <li>
                                            Enabling You to edit, view and create Your Videos;
                                        </li>
                                        <li>
                                            Performing research and provide statistical information to third parties (in such case, the provided information will not identify You);
                                        </li>
                                        <li>
                                            Enforcing the Operator’s Terms;
                                        </li>
                                        <li>
                                            Providing You with information about new services and special offers;
                                        </li>
                                        <li>
                                            Any other purpose detailed in the Terms and this Privacy Policy.
                                        </li>
                                    </ul>
                                    Your information is collected and used because the Operator has a legitimate business interest for Your personal data to be used for the above purposes. This enables the Operator to send You relevant and personalized content designed to improve Your use of the website. You have the right to object to this by contacting the Operator via email: contact@toonkids.com. Please note that if you object, this may affect the Operator’s ability to send personalized content to You.
                                </li>
                                <li>
                                    Please note that some of the information You will be requested to provide is necessary for providing You the website’s services. Therefore Your refusal to provide some information may result in You not receiving some services since the Operator will not be practically able to provide you its services.
                                </li>
                                <li>
                                    The Operator may receive Your information from third parties which the Operator cooperates with (e.g. business partners, professional advisors, service providers, etc.) and the Operator may receive Your information from them.
                                </li>
                            </ol>
                        </li>
                        <li><h6>Storing Information and Mailings</h6>
                            User details provided by You will be bound to the Operator’s Privacy Policy, and subject to Your consent which may be withdrawn at any time You hereby agree that such information will be stored in the Operator’s databases for the following purposes:
                            <ul>
                                <li>
                                    Marketing, advertising, sales promotion and addressing the User by any available media (including in writing, by phone, electronic form and any other form);
                                </li>
                                <li>
                                    Encouraging loyalty, research and analyzing of statistics, conducting surveys and any other use regarding registration to the Website and/or Your subscription to the website;
                                </li>
                                <li>
                                    Internal uses including investigating complaints and/or reviews;
                                </li>
                                <li>
                                    Operational marketing and statistical purposes including processing information and mailings for such purpose;
                                </li>
                                <li>
                                    Providing additional services to You.
                                </li>
                            </ul>
                        </li>
                        <li><h6>Transferring Information to Third Parties</h6>
                            Subject to Your consent, the Operator may transfer and/or share Your information in the following events and circumstances and, when applicable, will give you reasonable notice upon doing so:
                            <ul>
                                <li>
                                    When You have permitted the Operator to transfer and/or share Your information, and in such case the Operator will always ensure that Your information will remain protected and secure when being transferred;
                                </li>
                                <li>
                                    When You violate the Terms of Use or act illegally;
                                </li>
                                <li>
                                    To enforce the Terms of Use or the Privacy Policy;
                                </li>
                                <li>
                                    In response to a subpoena or similar investigative demand, a court order, or a request for cooperation from law enforcement or other government agency;
                                </li>
                                <li>
                                    To establish or exercise the Operator’s legal rights;
                                </li>
                                <li>
                                    To defend against legal claims; or as otherwise required by law. In such cases, the Operator may raise or waive any legal objection or right available to the Operator, in the Operator's sole discretion;
                                </li>
                                <li>
                                    When the Operator believes disclosure is appropriate in connection with efforts to investigate, prevent, report or take other action regarding illegal activity, suspected fraud or other wrongdoing;
                                </li>
                                <li>
                                    To protect and defend the rights, property or safety of the Operator, its Users, employees or others;
                                </li>
                                <li>
                                    In connection with a substantial corporate transaction, such as the sale of the Operator’s business, a divestiture, merger, consolidation, or asset sale, or in the event of liquidation.
                                </li>
                            </ul>

                        </li>
                        <li><h6>Third-Parties</h6>
                            <ol>
                                <li>
                                    There are several places on the Website where You may click on a link to access other websites that do not operate under this Privacy Policy and are not related to the Operator including, but not only, links leading to third party Websites. These links may independently solicit and collect information, including personal information, from You and, in some instances, provide the Operator with information about Your activities on those links.
                                </li>
                                <li>
                                    Every third party such as detailed above may have its own privacy policy describing its use of personal information, and such policy will regulate the use, handling and disclosure of such personal information of the User. Should You require additional information regarding any privacy policy of third parties, the Operator recommends that You visit these third-party websites and read their privacy policies. It is clarified that such third parties, unless explicitly mentioned otherwise, are neither controlled nor owned by the Operator
                                </li>
                                <li>
                                    Your information will be handled and used by the Operator’s trusted third parties including the following recipients in order to deliver services provided by the Operator:
                                    <ul>
                                        <li>
                                            Providers, partners and sub-contractors on behalf of the Operator;
                                        </li>
                                        <li>
                                            Analytics and search engine providers that may assist the Operator in improving the optimization and performance of the Website.
                                        </li>
                                    </ul>
                                </li>
                            </ol>
                        </li>
                        <li><h6>Retaining Your Information</h6>
                            <ol>
                                <li>
                                    The Operator will only retain Your personal information for as long as necessary to fulfil the purposes it was collected for including, but not only, for the purposes of complying with legal, accounting, or reporting requirements. The retention period is determined based on the amount, nature, and sensitivity of Your personal information, the potential risk of harm from unauthorized use or disclosure of Your information, the purposes for which the Operator processes Your information and whether such purposes can be achieved through other means with accordance to any applicable law.
                                </li>
                                <li>
                                    Your personal information will be retained for a reasonable period, thereafter all of Your information (other than information which the Operator is legally required to keep retaining by law) will be removed from the Operator’s systems.
                                </li>
                            </ol>
                        </li>
                        <li><h6>Your Choices About Collection and Use of Your Information and Mailings</h6>
                            <ol>
                                <li>
                                    You can choose not to provide the Operator with certain information, but that may result in You being unable to use the website since such information is necessary in order to enable You to subscribe to the Operator’s services.
                                </li>
                                <li>
                                    Upon agreeing to receive Mailings, the Operator may, with accordance to any applicable law, send You Mailings which include email messages and/or newsletters about service updates, improvements, special offers, or special content (“Mailings”). the Operator may send You other types of transactional and relationship email communications, such as service announcements, administrative notices, and surveys. If the Operator has already provided Your information to a third party before You have changed Your preferences or updated Your information, You may have to change You preferences directly with that third party.
                                </li>
                                <li>
                                    It is clarified that at any stage You will have the ability to opt out of receiving the Mailings by contacting the Operator and/or by clicking on a link available on the Mailings sent to You by the Operator.
                                </li>
                            </ol>
                        </li>
                        <li><h6>Use of Cookies</h6>
                            Upon Your consent, the Operator uses various technologies to collect information from Your device and about Your activities while using the Website in order to improve and personalize Your online experience of the Website including by means of storing cookies to Your device. Please note that the cookies the Operator uses are necessary for using the Website.
                        </li>
                        <li><h6>Data Security</h6>
                            <ol>
                                <li>
                                    The Operator takes appropriate security measures for data security purposes to help prevent unauthorized persons from gaining access to Your personal information. However, no system can be completely secure. Therefore the Operator does not warrant that its services will be totally immune to any breaches of security and/or unauthorized access, including access to the data stored by the Operator, although the Operator will contact You upon a breach of Your data security with accordance to any applicable law.
                                </li>
                                <li>
                                    The Operator inspects, from time to time, the data security of the website and performs modifications and upgrades accordingly in order to keep the website’s security. Nonetheless, the Operator will not be liable for any direct or indirect damage caused to You due to exposure of Your information by an unauthorized access to the Operator’s database and/or due to any negligence or act not controlled by the Operator.
                                </li>
                            </ol>
                        </li>
                        <li><h6>Minors</h6>
                            The Operator does not knowingly collect or solicit information from anyone under the age of eighteen (18) or knowingly allows minors to use the Website or any of the Operator’s services. If the Operator becomes aware that information from a minor was collected without the consent of his/her parent or guardian, the Operator will immediately remove such collected information.
                        </li>
                        <li><h6>Changes to This Privacy Policy</h6>
                            The Operator may occasionally update this Privacy Policy. Upon major changes, the Operator will notify You by sending an email to the email address You most recently provided the Operator and/or by prominently posting notice of the changes and will state at the heading of the Privacy Policy the date of change. the Operator reserves its rights, at its sole discretion, to amend the Privacy Policy at any time. You are responsible to check any updates of the Privacy Policy by entering the Privacy Policy from time to time. Your continuation of using the website will be considered as consent to any amended Privacy Policy.
                        </li>
                    </ol>
                </div>
                <div className='full-width text-center title-font font-30 margin-20'>
                    End of Privacy Policy
                </div>
                <div className="margin-40"/>
                <div className="margin-40"/>
            </div>
        )
    }
    return (
        <div className="relative full-width-screen flex-col-center">
            <Header/>
            <Text/>
            <Footer/>
        </div>
    )
}

export default Privacy;

// end
