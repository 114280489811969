// Globals.js
// init
const Globals = {}

// render time
Globals.renderTime = 600;

// storage
var bucketName = "storage";
if( window.isDev() || window.isLocal()) bucketName = "storage-dev";
Globals.storageDomain = "https://storage.googleapis.com/"+bucketName+".toon-kids.com";

// domain
Globals.domain = "https://toonkids.com";
if( window.isDev() ) Globals.domain = "https://dev.toonkids.com";
else if( window.isLocal() ) Globals.domain = "http://localhost:3000";

// export
export default Globals;