// Test.js
import { getFunctions, httpsCallable } from 'firebase/functions';

const makePrevTest = httpsCallable(getFunctions(), 'makePrevTest');

const Test = ()=>{
    const handleClick = async()=>{
        console.log("send..");
        const res = await makePrevTest({
            uid: "uid"
        })
        console.log(res.data);
    }
    return (
        <div>
           <button onClick={handleClick}>
                Test 
            </button>
        </div>
    )
}
export default Test;

// end
