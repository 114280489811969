// Footer.js
import { useState, useEffect } from 'react';
import { useGlobalState } from "../../GlobalState";
import Contact from 'comps/Contact/Contact';
import circleImg from "assets/images/general/circle.png";
import "./Footer.css";

const Footer = ()=>{
    //inits
    const [globalState, updateGlobalState] = useGlobalState();
    
    //states
    const [showContact, setShowContact] = useState(false);
    
    //functions
    const doShowContact = ()=>{
        setShowContact(true);
        window.setScroll(false);
    }
    const doHideContact = ()=>{
        setShowContact(false);
        window.setScroll(true);
    }
    const doBack = ()=>{
        window.backFunc();
    }
    const doNext = ()=>{
        window.nextFunc();
    }
    // comps
    const Highlight = ()=>{
        return(
            <img src={circleImg} className='absolute animate-flicker' width="150"/>
        )
    }
    const Back = ()=>{
        return(
            <button className="font-15" disabled={!globalState.back} onClick={doBack}>
                    Back
            </button>
        )
    }
    const Next = ()=>{
        const [showTooltip, setShowTooltip] = useState();
        const handleOver = ()=>{
            if(window.tooltip) setShowTooltip(true);
        }
        const handleOut = ()=>{
            setShowTooltip(false);
        }
        const Tooltip = ()=>{
            return(
                <div className='absolute text-center padding-10 b-r-20 z-10' style={{
                    width: "100px",
                    top: "20px",
                    right: "20px",
                    transform: "translate(100%,-100%)",
                    backgroundColor: "white",
                    color: "red"
                }}>
                    {window.tooltip}
                </div>
            )
        }
        return(
            <div className='relative flex-col-center' onMouseOver={handleOver} onMouseOut={handleOut}>
                {globalState.highNext?<Highlight/>:""}
                <button className="relative z-1 font-15"  disabled={!globalState.next} onClick={doNext}>
                    Next
                </button>
                {showTooltip?<Tooltip/>:""}
            </div>
        )
    }
    // render
    return (
        <div className="fix-down flex-col-center full-width-screen bg-dark-blue-2 padding-20 z-30">
            {globalState.showFooterButtons?
                <div className="flex-row-center">
                    <Back/>  
                    <div className="margin-10"/>
                    <Next/>
                </div>:""
            }
            <div className="margin-10"/>
            <div className="text-white text-center flex-row-center flex-wrap">
                <div className="text-white">Have a problem or suggestion?</div>
                <div className='margin-5'/>
                <div className="ul link text-white" onClick={doShowContact}>Contact Us!</div>
            </div>
            {showContact?<Contact hide={doHideContact} />:""}
        </div>
    )
}
export default Footer;

// end
