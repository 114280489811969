// Thanks.js
import { useEffect } from 'react';
import Footer from "comps/Footer/Footer";
import logoImg from "assets/images/general/logo.png";
import confettiImg from "assets/images/general/confeti.svg";
import dividerImg from "assets/images/general/divider.svg";
import confettiAnim from "assets/images/menu/confetti.json";
import { useLottie } from "lottie-react";
import "./Thanks.css";

const Thanks = ()=>{
    // inits

    // funcs
    var confetti, timeOut;
    const playConf = ()=>{
        if(timeOut) clearTimeout(timeOut);
        confetti.goToAndPlay(0,true);
        timeOut = setTimeout(()=>{
            confetti.goToAndStop(0,true);
        },confetti.getDuration()*1000)
    }

    // effect
    useEffect(()=>{
        playConf();
    },[])

    // comps
    const Logo = ()=>{
        return(
            <a href="/" target="_blank" className="link grow margin-20">
                <img src={logoImg} width="150"/>
            </a>
        )
    }
    const Confetti = ()=>{
        return(
            <img src={confettiImg} className="absolute full-width" style={{
                top: "0px",
                left: "0px",
                opacity:"0.4"
            }}/>
        )
    }
    const ConfAnim = ()=>{
        const style = {
            width: "100%"
        }
        const options = {
            animationData: confettiAnim,
            loop: false,
            autoplay: false,
        };
        confetti = useLottie(options,style);
        return(
            <div className="absolute z-1" style={{
                width: "700px",
                maxWidth: "90vw",
                pointerEvents: "none",
                top: "-30px"
            }}>
                {confetti.View}
            </div>
        )
    }
    const Text = ()=>{
        return(
            <div className="flex-col-center margin-20">
                <div className="font-40 title-font text-dark-blue text-center">
                    Thank you!
                </div>
                <div className="font-25 title-font text-dark-blue text-center">
                    Welcome to the Toon Kids Family!
                </div>
            </div>
        )
    }
    const Continue = ()=>{
        const action = ()=>{
            const vars = window.location.search;
            var path = "/finish"+vars;
            if(window.autoStart) path = "/movie"+vars;
            window.open(path,"_self");
        }
        return(
            <button className="title-font font-30 padding-20 margin-20" onClick={action}>
                Continue to movie
            </button>
        )
    }

    // render
    return (
        <div className="relative flex-col-center">
            <div className="relative flex-col-center z-1 padding-20">
                <Logo/>
                <img src={dividerImg} height="50"/>
                <Text/>
                <Continue/>
                <img src={dividerImg} height="50"/>
            </div>
            <Confetti/>
            <ConfAnim/>
            <div className='margin-30'/>
            <Footer/>
        </div>
    )
}

export default Thanks;

// end
