// Logo.js
import logoImg from "assets/images/general/logo.png";
import confettiAnim from "assets/images/menu/confetti.json";
import { useLottie } from "lottie-react";
import "./Logo.css";


const Logo = ({action})=>{
    var confetti, timeOut;
    const doAction = ()=>{
        if(action) action();
        playConf();
    }
    const playConf = ()=>{
        if(timeOut) clearTimeout(timeOut);
        confetti.goToAndPlay(0,true);
        timeOut = setTimeout(()=>{
            confetti.goToAndStop(0,true);
        },confetti.getDuration()*1000)
    }
    const Confetti = ()=>{
        const style = {
            width: "100%"
        }
        const options = {
            animationData: confettiAnim,
            loop: false,
            autoplay: false,
        };
        confetti = useLottie(options,style);
        return(
            <div className="absolute z-1" style={{pointerEvents: "none"}}>
                {confetti.View}
            </div>
        )
    }

    // render
    return(
        <div className="flex-col-center full-width">
            <img src={logoImg} className="relative link grow full-width" onClick={doAction}/>
            <Confetti/>
        </div>
    )
}

export default Logo;

// end
