// Contact.js
import { useState, useRef, useEffect } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import ErrMsg from "comps/ErrMsg/ErrMsg";
import SendBut from "comps/SendBut/SendBut";
import vImg from "assets/images/general/v.svg";
import closeImg from "assets/images/general/close.svg";
import "./Contact.css";

const sendContact = httpsCallable(getFunctions(), 'sendContact');

const Contact = (props)=>{
    //states
    const [suc, setSuc] = useState(false);
    const Form = ()=>{
        //states
        const [errMsg, setErrMsg] = useState();
        const [wait, setWait] = useState(false);

        //refs
        const nameInput = useRef();
        const emailInput = useRef();
        const msgInput = useRef();

        //funcs
        const doSend = async(email)=>{
            try{
                //get inputs
                const name = nameInput.current.value;
                const email = emailInput.current.value;
                const msg = msgInput.current.value;
                //check inputs
                if(!name) throw Error('Please enter a name.');
                if(!window.isEmail(email)) throw Error('Please check your email.');
                if(!msg) throw Error('Please enter a message.');
                //make action
                setErrMsg();
                setWait(true);
                nameInput.current.disabled = true;
                emailInput.current.disabled = true;
                msgInput.current.disabled = true;
                await sendContact({
                    name: name,
                    email: email,
                    msg: msg
                });
                setSuc(true);
            }
            catch(err){
                setWait(false);
                setErrMsg(err.message);
                nameInput.current.disabled = false;
                emailInput.current.disabled = false;
                msgInput.current.disabled = false;
            }
        }
        const inputStyle = {
            border:"2px",
            borderStyle:"solid",
            borderColor:"#004975"
        }
        return(
            <div className="flex-col-center">
                <input ref={nameInput} className="full-width font-20"  type="text" placeholder="Name" style={inputStyle}/>
                <input ref={emailInput} className="full-width font-20 margin-20" type="email" placeholder="Email" autoComplete="on" style={inputStyle}/>
                <textarea ref={msgInput} className="relative z-1 full-width font-20" cols="50" rows="10" placeholder="Message" style={inputStyle}/>
                <div className="margin-10"/>
                <ErrMsg msg={errMsg}/>
                <SendBut txt="Send" action={doSend} wait={wait}/>
            </div>
        )
    }
    const Suc = ()=>{
        return(
            <div className="flex-col-center">
                <div className="font-20 text-dark-blue text-center margin-20">
                    We've received your message and will get back to you as soon as possible!
                </div>
                <img src={vImg} width="100"/>
            </div>
        )
    }
    return (
        <div className="overlay flex-col-center">
            <div className="flex-col-center relative z-1 bg-creme b-r-20 padding-20 margin-20" style={{
                width:"400px",
                maxWidth:"90vw"
            }}>
                <div className="relative z-1 font-30 title-font text-dark-blue text-center ul">
                    Contact Us
                </div>
                <div className='margin-10'/>
                <div className="relative z-1 font-20 text-dark-blue text-center">
                    contact@toonkids.com
                </div>
                <div className='margin-10'/>
                {suc?<Suc/>:<Form/>}
                <div className="margin-10"/>
                <img src={closeImg} className="absolute link" width="50" onClick={props.hide} style={{
                    top: "0px",
                    right: "0px",
                    transform: "translate(50%,-50%)"
                }}/>
            </div>
            <div className="bg-dark-blue" onClick={props.hide}/>
        </div>
    )
}

export default Contact;

// end
