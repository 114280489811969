import { initializeApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator, httpsCallable } from 'firebase/functions';
import inviteFont from "assets/fonts/Invite.woff";

//global canvas for reuse
window.canvas = document.createElement('canvas');

//functions
window.isMe = ()=>{
    // set when needed with command: document.cookie = "me=true";
    if(document.cookie.includes("me=true")) return true;
    else return false;
}
window.isDev = ()=>{
    return ( window.location.hostname.includes("dev.") );
}
window.isLocal = ()=>{
    return (
        window.location.hostname === "localhost" ||
        window.location.hostname === "127.0.0.1" ||
        window.location.hostname === "0.0.0.0"
    );
}
window.getParam = (name)=>{
    return new URLSearchParams(window.location.search).get(name)
}
window.setScroll = (flag)=>{
    var state = 'hidden';
    if(flag) state = 'auto';
    document.body.style.overflow = state;
}
window.isEmail = (email)=>{
    if( /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) ) return true;
    else return false;
}
window.sleep = (sec)=>{
    return new Promise( (resolve, reject)=>setTimeout(resolve,sec*1000) );
}
window.loadImage = (path)=>{
    return new Promise((resolve,reject)=>{
        const img  = new Image();
        img.onload = ()=>{
            resolve(img)
        }
        img.onerror = (err)=>{
            reject(err);
        }
        img.src = path;
    })
}
window.img4src = (src)=>{
    return new Promise((resolve,reject)=>{
        const img  = new Image();
        img.onload = ()=>{
            resolve(img)
        }
        img.onerror = (err)=>{
            console.log(err);
            reject(err);
        }
        img.src = src;
    })
}
window.img2data = (img,hq=true)=>{
    const canvas = window.canvas;
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext("2d");
    ctx.resetTransform();
    ctx.clearRect(0,0,canvas.width,canvas.height);
    ctx.drawImage(img,0,0);
    var data;
    if(hq) data = canvas.toDataURL('image/png');
    else data = canvas.toDataURL('image/jpeg',0.8);
    ctx.clearRect(0,0,canvas.width,canvas.height);
    canvas.width = 0;
    canvas.height = 0;
    return data;
}
window.img2blob = (img, quality)=>{
    return new Promise((resolve, reject)=>{
        try{
            const canvas = window.canvas;
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext("2d");
            ctx.resetTransform();
            ctx.clearRect(0,0,canvas.width,canvas.height);
            ctx.drawImage(img,0,0);
            if(quality) canvas.toBlob(resolve,"image/jpeg",quality); // https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob
            else canvas.toBlob(resolve); // png is default
        }
        catch(err){
            reject(err);
        }
    })  
}
window.canv2blob = (canvas, quality)=>{
    return new Promise((resolve, reject)=>{
        try{
            if(quality) canvas.toBlob(resolve,"image/jpeg",quality); // https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob
            else canvas.toBlob(resolve); // png is default
        }
        catch(err){
            reject(err);
        }
    })  
}
window.src2blob = (src, quality)=>{
    return new Promise((resolve, reject)=>{
        try{   
            const img = new Image;
            img.onload = ()=>{
                const canvas = window.canvas;
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext("2d");
                ctx.resetTransform();
                ctx.clearRect(0,0,canvas.width,canvas.height);
                ctx.drawImage(img,0,0);
                if(quality) canvas.toBlob(resolve,"image/jpeg",quality); // https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob
                else canvas.toBlob(resolve); // png is default
            };
            img.src = src;
        }
        catch(err){
            reject(err);
        }
    })  
}
window.loadScript = (path)=>{
    return new Promise((resolve,reject)=>{
        const script  = document.createElement("script");
        script.onload = ()=>{
            resolve();
        }
        script.onerror = (err)=>{
            reject(err);
        }
        script.src = path;
        document.head.appendChild(script);
    })
}
window.pushData = (eventName)=>{
    if(window.dataLayer) window.dataLayer.push({'event': eventName})
}
const loadGtag = ()=>{
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-PGNBVK7');
}
// init firebase
// !!!! change for production - /__/firebase/init.json !!!!!
const prodConfig = {
    apiKey: "AIzaSyCU-f-2vP88YG6oTKxfdbpLtmj4FhFvNIE",
    authDomain: "toonkids.com",
    databaseURL: "https://toon-kids-prod.firebaseio.com",
    projectId: "toon-kids-prod",
    storageBucket: "storage.toonkids.com",
    messagingSenderId: "472671633231",
    appId: "1:472671633231:web:3c3bb65a570a5e994c0f3a"
}
const devConfig = {
    apiKey: "AIzaSyCzT9f2bYxBVMs1OeFevB0LgCfzo6f5pfI",
    authDomain: "dev.toonkids.com",
    databaseURL: "https://toon-kids-dev.firebaseio.com",
    projectId: "toon-kids-dev",
    storageBucket: "storage-dev.toonkids.com",
    messagingSenderId: "274910613203",
    appId: "1:274910613203:web:8f02cd15b6f4e23e458e26"
}
var firebaseConfig = prodConfig;
if( window.isDev() || window.isLocal() ) firebaseConfig = devConfig;
window.firebaseApp = initializeApp(firebaseConfig);
if( window.isLocal() ) connectFunctionsEmulator(getFunctions(), "localhost", 3001);

// auto start flag
window.autoStart = false;
// if(window.location.hostname=="b.toonkids.com" || window.location.hostname=="testb.toonkids.com") window.autoStart = true;
// if(window.location.hostname=="localhost") window.autoStart = true;

// production execution
if( !window.isDev() && !window.isLocal() && !window.isMe() ){
    // google tag manager
    loadGtag();
    
    // disable log
    window.consoleBU = console.log;
    console.log = ()=>{
        return true;
    }
}

// load invite font
const myFont = new FontFace("Invite", 'url('+inviteFont+')');
myFont.load().then(()=>{
    document.fonts.add(myFont);
    console.log("invite font loaded.");
});

// init paddle + get price
window.loadScript("https://cdn.paddle.com/paddle/v2/paddle.js").then(async()=>{
    // callback
    const eventCallback = (event)=>{
        if (event.name == "checkout.completed") window.pushData('purchase');
    }
    const getPricePaddle = ()=>{
        const request = {
            items: [
                {
                    quantity: 1,
                    priceId: window.priceId
                }
            ]
        }
        window.Paddle.PricePreview(request)
            .then((result) => {
                const price = Number(result.data.details.lineItems[0].price.unitPrice.amount);
                var priceStr = "$";
                if(price>=1000) priceStr += price.toString().charAt(0)+price.toString().charAt(1)+"."+price.toString().slice(2);
                else priceStr += price.toString().charAt(0)+"."+price.toString().slice(1);
                window.price = priceStr;
                console.log("Price is: "+window.price);
            })
            .catch((error) => {
              console.error(error);
        });
    }
    
    // init
    var paddleKey = "live_fc9b149dbba78ee7d482c4d2e0e";
    window.priceId = "pri_01j0treyfj28ptp2tcjzc2y0t5";
    if( window.isDev() || window.isLocal() ){
        paddleKey = "test_425b198d5b08050c87526eb9f61";
        window.Paddle.Environment.set("sandbox");
        window.priceId = "pri_01hyt1efkr9zqkg7ff6vg4n2dp";
        console.log("Paddle test env loaded.");
    }
    else{
        if( window.isMe() ) window.priceId = "pri_01j1vcy3q05rh83v0krkvkcr5z";
        console.log("Paddle prod env loaded.");
    }
    window.Paddle.Initialize({
        token: paddleKey,
        eventCallback: eventCallback
    });
 
    // get price
    const getPrice = httpsCallable(getFunctions(), 'getPrice');
    const priceRes = await getPrice();
    const price = priceRes.data.price;
    var priceStr = "$";
    if(price>=1000) priceStr += price.toString().charAt(0)+price.toString().charAt(1)+"."+price.toString().slice(2);
    else priceStr += price.toString().charAt(0)+"."+price.toString().slice(1);
    window.price = priceStr;
    console.log("Price is: "+window.price);

    // done
    console.log("paddle initialized.");
});

//export
const Config = {}
export default Config;