// StageB1.js
import { useState, useEffect, useRef } from 'react';
import { useGlobalState } from "../../GlobalState";
import { useNavigate } from "react-router-dom";
import SendBut from "comps/SendBut/SendBut";
import ErrMsg from "comps/ErrMsg/ErrMsg";
import Close from "comps/Close/Close";
import vImg from "assets/images/general/v.svg";
import togetherImg from "assets/images/general/together.png";
import loaderGif from "assets/images/general/loader.gif";
import "./StageB1.css";

const StageB1 = ()=>{
    //inits
    const [globalState, updateGlobalState] = useGlobalState();
    const navigate = useNavigate();

    //states
    const [another, setAnother] = useState(false);
    const [globWait, setGlobWait] = useState(false);

    //refs
    const inputFile = useRef();

    //funcs
    const selectFile = ()=>{
        inputFile.current.click();
    }
    const clearFile = ()=>{
        inputFile.current.value=null
    }
    const handleFile = ()=>{
        const file = inputFile.current.files[0];
        if(file!=undefined){
            if( file.type.match(/image.*/) ){
                window.faces = false;
                setGlobWait(true);
                const reader = new FileReader();
                reader.onload = (e)=>{
                    window.faceImgData = e.target.result;
                    window.faceImg = new Image();
                    window.faceImg.onload = ()=>{
                        window.newFace = true;
                        navigate('/editor/b/2');
                    }
                    window.faceImg.src = window.faceImgData;
                }
                reader.readAsDataURL(file);
            }
            else{
                alert('The file you chose is not an image.\nPlease select an image.');
            }
        }
    }
    const showAnother = ()=>{
        window.setScroll(false);
        setAnother(true);
    }
    const hideAnother = ()=>{
        window.setScroll(true);
        setAnother(false);
    }

    //effect
    useEffect(()=>{
        window.scrollTo(0, 0);
        updateGlobalState("stage",2);
        updateGlobalState("back",true);
        updateGlobalState("next",false);
        if(window.faceImg) updateGlobalState("next",true);
        window.backFunc = ()=>{
            navigate('/editor/a');
        }
        window.nextFunc = ()=>{
            navigate('/editor/b/2');
        }
    },[])

    //comps
    const Title = ()=>{
        return(
            <div className="flex-col-center" style={{maxWidth:"95vw"}}>
                <div className="font-30 title-font text-dark-blue text-center">
                    Great! Now let's a add a face!
                </div>
            </div>
        )
    }
    const Buttons = ()=>{
        const Button = (props)=>{
            return(
                <div className="relative flex-col-center margin-10 link grow-small shadow selectButton" onClick={props.action}>
                    <div className="font-30 title-font text-white text-center non-select">
                        {props.txt}
                    </div>
                </div>
            )
        }
        return(
            <div className="relative flex-row-center flex-wrap margin-20" style={{
                width: "500px",
                maxWidth: "90vw"
            }}>
                <Button txt="Select/Take a picture" action={selectFile}/>
                {/*<Button txt="Get from another device" action={showAnother}/>*/}
            </div>
        )
    }
    const FaceImg = ()=>{
        const faceCanvas = useRef();
        useEffect(()=>{
            const src = window.faceImg;
            const canvas = faceCanvas.current;
            canvas.width = src.width;
            canvas.height = src.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(src,0,0);
        },[]);
        // render
        return(
            <canvas ref={faceCanvas} className="b-r-20" style={{
                maxWidth:"200px",
                maxHeight:"200px"
            }}/>
        )
    }
    const Another = ()=>{
        //states
        const [suc, setSuc] = useState(false);
        const [globEmail, setGlobEmail] = useState();
        //comps
        const Title = ()=>{
            return(
                <div className="font-20 text-white text-center">
                    To Import a picture from another device we will send you an upload link to your email.<br/>
                    Open it from the device where the picture is located.
                </div>
            )
        }
        const Form = ()=>{
            const [errorMsg, setErrorMsg] = useState();
            const [wait, setWait] = useState(false);
            const emailInput = useRef();
            const doSend = (email)=>{
                try{
                    const email = emailInput.current.value;
                    if( window.isEmail(email) ){
                        setErrorMsg();
                        setWait(true);
                        setTimeout(()=>{
                            setSuc(true);
                            setGlobEmail(email);
                        },3000)
                    }
                    else throw Error('Please check your email.');
                }
                catch(err){
                    setWait(false);
                    setErrorMsg(err.message);
                }
            }
            return(
                <div className="flex-col-center">
                    <input ref={emailInput} className="font-20 margin-10" type="email" autoComplete="on" placeholder="Email" disabled={wait} onInput={()=>{setErrorMsg()}}/>
                    <SendBut txt="Send" action={doSend} wait={wait}/>
                    <div className="absolute non-interact" style={{bottom:"0px",right:"0px"}}>
                        <ErrMsg msg={errorMsg}/>
                    </div>
                </div>
            )
        }
        const Suc = ()=>{
            return(
                <div className="relative flex-col-center">
                    <div className="font-30 title-font text-white text-center">
                        Perfect!
                    </div>
                    <div className="font-20 text-white text-center">
                        We've sent an email to {globEmail}<br/>
                        When you upload the picture it will apear here.<br/>
                        We're waiting :)<br/>
                    </div>
                    <div className="margin-20"/>
                    <img src={loaderGif} width="100"/>
                </div>
            )
        }
        //render
        return(
            <div className="overlay flex-col-center">
                <div className="flex-col-center relative z-1 blue-bg b-r-20 padding-40 margin-20" style={{
                    width:"700px",
                    maxWidth:"90vw"
                }}>
                    {suc?<Suc/>:<Title/>}
                    {suc?"":<Form/>}
                    <Close action={hideAnother}/>
                </div>
                <div className="bg-dark-blue"/>
            </div>
        )
    }
    const File = ()=>{
        return(
            <input type='file' ref={inputFile} hidden onClick={clearFile} onChange={handleFile} />
        )
    }
    const Together = ()=>{
        return(
            <img src={togetherImg} className="margin-20" style={{width:"500px", maxWidth:"90%"}}/>
        )
    }
    const Wait = ()=>{
        return(
            <img src={loaderGif} width="150"/>
        )
    }

    //render
    return (
        <div className="flex-col-center">
            <Title/>
            <Buttons/>
            {(!globWait && window.faceCanvas)?<FaceImg/>:""}
            {globWait?<Wait/>:""}
            {another?<Another/>:""}
            <File/>
            <Together/>
        </div>
    )
}
export default StageB1;

// end
