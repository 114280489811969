// Test.js
import { getFunctions, httpsCallable } from 'firebase/functions';

const initTest = httpsCallable(getFunctions(), 'initTest');

const Test = ()=>{
    const handleClick = async()=>{
        console.log("send..");
        const res = await initTest({
            uid:"0GxDCkL38SeFoPs3okFoyh9R5s03",
            mid:"1724727327317"
        })
        console.log(res.data);
    }
    return (
        <div>
           <button onClick={handleClick}>
                Test 
            </button>
        </div>
    )
}
export default Test;

// end
