// StartButton.js
import "./StartButton.css";

const StartButton = ({fontSize, margin})=>{
    return (
        <a href="/birthday-movie-maker" className="relative z-3 link grow text-white title-font start-button" style={{
            fontSize: fontSize+"px",
            margin: margin+"px"
        }}>
            START NOW!
        </a>
    )
}

export default StartButton;

// end
