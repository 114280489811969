// Login.js
import { useState, useRef } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import SendBut from "comps/SendBut/SendBut";
import ErrMsg from "comps/ErrMsg/ErrMsg";
import closeImg from "assets/images/general/close.svg";
import vImg from "assets/images/general/v.svg";
import "./Login.css";

const sendLoginLink = httpsCallable(getFunctions(), 'sendLoginLink');

const Login = ({doHideLogin})=>{
    //states
    const [suc, setSuc] = useState(false);

    //comps
    const Form = ()=>{
        //states
        const [errMsg, setErrMsg] = useState();
        const [wait, setWait] = useState(false);

        //refs
        const emailInput = useRef();

        //funcs
        const doSend = async(email)=>{
            try{
                const email = emailInput.current.value;
                if( window.isEmail(email) ){
                    setErrMsg();
                    setWait(true);
                    await sendLoginLink({email:email})
                    setSuc(true);
                }
                else throw Error('Please check your email.');
            }
            catch(err){
                setWait(false);
                setErrMsg(err.message);
            }
        }

        //render
        return(
            <div className="flex-col-center">
                <div className="font-20 text-dark-blue text-center margin-20">
                    To receive a login link please enter your email below and press the Send button:
                </div>
                <input ref={emailInput} className="font-20" type="email" name="email" autoComplete="email" placeholder="Email" disabled={wait} style={{
                    border:"2px",
                    borderStyle:"solid",
                    borderColor:"#004975"
                }}/>
                <ErrMsg msg={errMsg}/>
                <SendBut txt="Send" action={doSend} wait={wait}/>
            </div>
        )
    }
    const Suc = ()=>{
        return(
            <div className="flex-col-center">
                <div className="font-20 text-dark-blue text-center margin-20">
                    We've sent you an email with a login link.
                </div>
                <img src={vImg} width="100"/>
            </div>
        )
    }

    //render
    return (
        <div className="overlay flex-col-center z-10">
            <div className="flex-col-center relative z-1 bg-creme b-r-20 padding-20 margin-20" style={{
                width:"600px",
                maxWidth:"90vw"
            }}>
                <div className="font-30 ul title-font text-dark-blue text-center">
                    Login
                </div>
                {suc?<Suc/>:<Form/>}
                <img src={closeImg} className="absolute link" width="50" onClick={doHideLogin} style={{
                    top: "0px",
                    right: "0px",
                    transform: "translate(50%,-50%)"
                }}/>
            </div>
            <div className="bg-dark-blue" onClick={doHideLogin}/>
        </div>
    )
}

export default Login;

// end
