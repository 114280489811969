// StageF.js
import { useState, useEffect, useRef } from 'react';
import { useGlobalState } from "../../GlobalState";
import { useNavigate } from "react-router-dom";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAuth, signInWithCustomToken, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getStorage, ref, uploadBytesResumable, uploadString } from "firebase/storage";
import Loader from "comps/Loader/Loader";
import ErrMsg from "comps/ErrMsg/ErrMsg";
import googleImg from "assets/images/editor/stageF/google.svg";
import "./StageF.css";

const getMid = httpsCallable(getFunctions(), 'getMid');
const makePrevs = httpsCallable(getFunctions(), 'makePrevs');
var headProg, nameProg, ageProg, loginToken, signedIn, exportDataCompare;

// comps
const Google = ({updateGlobalState, makeUpload, setWait, makeFinish})=>{
    const provider = new GoogleAuthProvider();
    const handleClick = ()=>{
        signInWithPopup(getAuth(), provider)
            .then(async(result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;
                const email = user.email;
                console.log(email);
                if(email!=window.email){
                    headProg = 0;
                    nameProg = 0;
                    ageProg = 0;
                    setWait(true);
                    updateGlobalState("next", false);
                    updateGlobalState("back", false);
                    signedIn = false;
                    window.uid = null;
                    window.mid = null;
                    window.intentID = null;
                    makeUpload(email);
                }
                else makeFinish();
                // IdP data available using getAdditionalUserInfo(result)
                // ...
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorMessage);
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
            });
    }
    return <img src={googleImg} className='link grow' onClick={handleClick}/>
}

//main
const StageF = ()=>{
    //inits
    const [globalState, updateGlobalState] = useGlobalState();
    const navigate = useNavigate();
    const emailRef = useRef();
    const [wait, setWait] = useState(false);
    const [errorMsg, setErrorMsg] = useState();
    const [exists, setExists] = useState(false);

    // functions
    const makeFinish = ()=>{
        navigate('/editor/e/1');
        // if(window.autoStart) window.open("/finish?uid="+window.uid+"&mid="+window.mid+"&email="+window.email,"_self");
        // else if(window.makePreview) navigate('/editor/e/2');
        // else navigate('/editor/checkout?uid='+window.uid+"&mid="+window.mid+"&email="+window.email);
    }
    const checkChange = ()=>{
        const isEqual = (objA,objB)=>{
            const jsonA = JSON.stringify(objA);
            const jsonB = JSON.stringify(objB);
            return (jsonA==jsonB);
        }
        const dupObject = (obj)=>{
            return JSON.parse(JSON.stringify(obj));
        }

        window.exportData.movie.faceImg = window.localImages.faceImg;
        window.exportData.movie.nameImg = window.localImages.nameImg;
        window.exportData.movie.ageImg = window.localImages.ageImg;
        const movObj = window.exportData.movie;
        if( !isEqual(movObj, exportDataCompare) ){
            console.log("not equeal");
            window.prevStarted = false;
            window.prevUrl = null;
            window.uploaded = false;
        }
        exportDataCompare = dupObject(movObj);
    }
    const makeUpload = async(email)=>{
        const uploadFile = async(ref, obj)=>{
            return new Promise((resolve, reject)=>{
                try{
                    if(typeof obj === 'string' || obj instanceof String){ //https://firebase.google.com/docs/reference/js/storage.md#uploadstring_277829d
                        uploadString(ref, window.btoa(obj), "base64", {
                            contentType: "application/json"
                        }).then(
                            (snapshot)=>{
                                resolve()
                            }
                        )
                    }
                    else{
                        const uploadTask = uploadBytesResumable(ref, obj);
                        uploadTask.on('state_changed', 
                            (snapshot) => {
                                var factor = 10;
                                if( snapshot.ref.name.includes("head") ) factor = 80;
                                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * factor;
                                if( snapshot.ref.name.includes("head") ) headProg = progress;
                                if( snapshot.ref.name.includes("name") ) nameProg = progress;
                                if( snapshot.ref.name.includes("age") ) ageProg = progress;
                                // console.log('Upload is ' + progress + '% done');
                                // switch (snapshot.state) {
                                // case 'paused':
                                //     console.log('Upload is paused');
                                //     break;
                                // case 'running':
                                //     console.log('Upload is running');
                                //     break;
                                // }
                            }, 
                            (error) => { throw error }, 
                            () => { resolve() }
                        );
                    }
                }
                catch(err){
                    reject(err);
                }
            })      
        }
        try{
            if(!window.uid){
                const res = await getMid({email: email});
                const {uid, mid, token, transactionId} = res.data;
                console.log("uid: "+uid);
                console.log("mid: "+mid);
                window.email = email;
                window.uid = uid;
                window.mid = mid;
                loginToken = token;
                window.transactionId = transactionId;
            }
            if(!signedIn){
                await signInWithCustomToken(getAuth(), loginToken);
                signedIn = true;
                console.log("logged in");
            }
            const path = "users/"+window.uid+"/movies/"+window.mid+"/assets";
            // upload args
            console.log("upload args");
            const argsRef = ref(getStorage(), path+"/args.json");
            const argsObj = {
                family: window.exportData.movie.type,
                head: window.exportData.movie.head,
                clothes: Number(window.exportData.movie.avatar.avatars)+1,
                hair: {
                    type: Number(window.exportData.movie.avatar.hair)+1,
                    color: Number(window.exportData.movie.avatar.hairColor)+1
                },
                tops: Number(window.exportData.movie.avatar.tops)+1,
                colors: Number(window.exportData.movie.avatar.colors)+1,
                cake: Number(window.exportData.movie.cake)+1,
                age: Number(window.exportData.movie.age),
                name: window.exportData.movie.name,
                uid: window.uid,
                mid: window.mid
            }
            await uploadFile(argsRef, JSON.stringify(argsObj));
            // upload face
            console.log("upload face");
            const headRef = ref(getStorage(), path+"/head.png");
            const headBlob = await window.img2blob(window.localImages.faceImg);
            await uploadFile(headRef, headBlob);
            // upload start flag
            if(window.autoStart){ //set in /assets/modules/Config.js
                console.log("upload start flag");
                const startFlagRef = ref(getStorage(), path+"/start.flag");
                await uploadFile(startFlagRef, "start");
            }
            // make prevs
            console.log("make prevs..");
            const prevRes = await makePrevs({
                uid: window.uid,
                mid: window.mid,
                invite: window.makeInvite?window.exportData.invite:null
            })
            window.downLinks = prevRes.data.downLinks;
            
            // finish
            window.uploaded = true;
            window.redraw = true;
            window.prevStarted = false;
            window.prevUrl = false;
            makeFinish();
        }
        catch(err){
            console.log(err);
            return Promise.reject(err);
        }
    }
    
    // effect
    useEffect(()=>{
        window.scrollTo(0, 0);
        updateGlobalState("next", false);
        checkChange();
        console.log("uploaded: "+window.uploaded);
        if(window.email){
            if(window.uploaded){
                setExists(true);
                updateGlobalState("next", true);
            }
            else{
                setWait(true);
                updateGlobalState("back", false);
                headProg = 0;
                nameProg = 0;
                ageProg = 0;
                makeUpload(window.email);
            }
        }
        window.backFunc = ()=>{
            navigate('/editor/d');
        }
        window.nextFunc = ()=>{
            navigate('/editor/e/1');
            // navigate('/editor/checkout?uid='+window.uid+"&mid="+window.mid+"&email="+window.email);
        }
    },[])

    // comps
    const Title = ()=>{
        return(
            <div className="flex-col-center" style={{
                width:"900px",
                maxWidth:"90vw"
            }}>
                <div className="font-30 title-font text-dark-blue text-center">
                    Last step!
                </div>
                <div className="font-20 text-dark-blue text-center">
                    {window.makeInvite
                    ?
                    <>Enter your email to get your cards!</>
                    :
                    <>
                        Enter your email to continue.<br/>
                        <u>You will need access to this email to receive the movie.</u>
                    </>
                    }
                </div>
            </div>
        )
    }
    const Email = ()=>{
        const doChange = ()=>{
            updateGlobalState("next", false);
            setExists(false);
        }
        return(
            <div className="flex-col-center" style={{
                width:"900px",
                maxWidth:"90vw"
            }}>
                <div className="font-25 title-font text-dark-blue text-center">
                    Your email is:
                </div>
                <div className="font-25 text-dark-blue text-center margin-20">
                    {window.email}
                </div>
                <button className="font-20" onClick={doChange}>
                    Change
                </button>
            </div>
        )   
    }
    const Form = ()=>{
        // functions
        const handleSubmit = async () => {
            try{  
                // check email
                const email = emailRef.current.value;
                if(!window.isEmail(email)) throw Error("Please check your email.");
                if(email!=window.email){
                    headProg = 0;
                    nameProg = 0;
                    ageProg = 0;
                    setWait(true);
                    updateGlobalState("next", false);
                    updateGlobalState("back", false);
                    signedIn = false;
                    window.uid = null;
                    window.mid = null;
                    window.intentID = null;
                    makeUpload(email);
                }
                else makeFinish();
            }
            catch(err){
                console.log(err.message);
                setErrorMsg(err.message);
                setWait(false);
            }
        }
        return(
            <div className="flex-col-center">
                <Title/>
                <div className="flex-col-center margin-20" style={{
                    width:"300px",maxWidth:"90vw"
                }}>
                    <div className='relative flex-row-center full-width'>
                        <input ref={emailRef} defaultValue={window.email} type="email" name="email" autoComplete="email" placeholder="Email" className="margin-10 font-20" onInput={()=>{setErrorMsg()}}/>
                        <button className="font-20" onClick={handleSubmit}>
                            Submit
                        </button>
                    </div>               
                    <Google updateGlobalState={updateGlobalState} makeUpload={makeUpload} setWait={setWait} makeFinish={makeFinish}/>
                    <ErrMsg msg={errorMsg} margin={5}/>
                </div>       
            </div>
        )          
    }
    const Wait = ()=>{
        const ProgBar = ()=>{
            // inits
            const [width, setWidth] = useState(10);
            const progBarRef = useRef();
            // functions
            const doProg = ()=>{
                if(progBarRef.current){
                    const curWidth =  Number(progBarRef.current.style.width.split("px")[0])
                    var newWidth = ((headProg+nameProg+ageProg)/100)*280;
                    if( Math.floor(newWidth) <= Math.floor(curWidth) ) newWidth = curWidth+2;
                    setWidth(newWidth);
                    if(newWidth<280) setTimeout(doProg,500);
                }          
            }
            // effect
            useEffect(doProg,[])
            // return
            return(
                <div className="full-width b-r-20 grey-bg" style={{width:"300px",height:"20px"}}>
                    <div ref={progBarRef} className="blue-bg" style={{
                        width: width.toString()+"px",
                        height:"20px",
                        borderRadius:"20px 0px 0px 20px"
                    }} />
                </div>
            )
        }
        return(
            <div className="flex-col-center">
                <div className="font-25 title-font text-dark-blue text-center">
                    Getting everything set up for you!
                </div>
                <Loader width={300}/>
                <ProgBar/>
            </div>
        )
    }
  
    // render
    return wait?<Wait/>:(exists?<Email/>:<Form/>)
}

export default StageF;

// end
