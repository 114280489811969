// StageE2.js
import Globals from "assets/modules/Globals";
import { useState, useEffect, useRef } from 'react';
import { useGlobalState } from "../../GlobalState";
import { useNavigate } from "react-router-dom";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getDatabase, ref, onValue } from "firebase/database";
import Loader from "comps/Loader/Loader";
import loaderGif from "assets/images/general/loader.gif";
import playImg from "assets/images/general/play.svg";
import "./StageE2.css";

const makeVidPrev = httpsCallable(getFunctions(), 'makeVidPrev');
const getPrevDownloadLink = httpsCallable(getFunctions(), 'getPrevDownloadLink');
const database = getDatabase();
var uid, mid, startTime;

const StageE2 = ()=>{
    // inits
    const [globalState, updateGlobalState] = useGlobalState();
    const navigate = useNavigate();
    const [wait, setWait] = useState(true);

    // effect
    useEffect(()=>{
        if(!window.uid) window.open("/birthday-movie-maker","_self");
        window.scrollTo(0, 0);
        updateGlobalState("back", false);
        updateGlobalState("next", false);
        window.backFunc = ()=>{
            navigate('/editor/f');
        }
        window.nextFunc = ()=>{
            window.makePreview = false;
            navigate('/editor/checkout?uid='+window.uid+"&mid="+window.mid+"&email="+window.email);
            // navigate('/editor/f');
        }
    },[])

    // comps
    const TitleA = ()=>{
        return(
            <div className="flex-col-center" style={{
                width:"900px",
                maxWidth:"90vw"
            }}>
                <div className="font-30 title-font text-dark-blue text-center">
                    We're on it!
                </div>
                {/* <div className="font-20 text-dark-blue text-center">
                    Usually it takes around one minute to complete.
                </div> */}
            </div>
        )
    }
    const TitleB = ()=>{
        const handleClick = ()=>{
            window.makePreview = false;
            navigate('/editor/checkout?uid='+window.uid+"&mid="+window.mid+"&email="+window.email);
        }
        return(
            <div className="flex-col-center" style={{
                width:"900px",
                maxWidth:"90vw"
            }}>
                <div className="font-30 title-font text-dark-blue text-center">
                    Your short preview is ready!
                </div>
                <div className="font-20 text-dark-blue text-center">
                    To get the <u onClick={handleClick} className="link grow"><b>Full High Quality 02:15 Minutes Movie</b></u> with <u onClick={handleClick} className="link grow"><b>Download & Share Options</b></u> click the button:<br/>
                </div>
            </div>
        )
    }
    const DownButton = ()=>{
        const [localWait, setLocalWait] = useState(false);
        const doDownload = async()=>{
            setLocalWait(true);
            try{
                const urlRes = await getPrevDownloadLink({uid:uid});
                const url = urlRes.data.url;
                window.open(url,'_self');
            }
            catch(e){
                console.log(e.message);
            }
            setLocalWait(false);
        }
        return(
            <div className="relative flex-col-center">
                <button className="yellow-button font-20 margin-20" disabled={wait || localWait} onClick={doDownload}>
                    Download
                </button>
                {localWait?<img src={loaderGif} width="50" className="absolute"/>:""}
            </div>
        )
    }
    const FullButton = ()=>{
        const handleClick = ()=>{
            window.makePreview = false;
            navigate('/editor/checkout?uid='+window.uid+"&mid="+window.mid+"&email="+window.email);
        }
        return(
            <button className="red-button font-20 margin-5" disabled={wait} onClick={handleClick}>
                Get Full 02:15 Movie! ($4.99)
            </button>
        )
    }
    const Stage = ()=>{
        const init = async()=>{
            const setStatus = (status)=>{
                console.log(status);
            }
            const mountMedia = (urls)=>{
                console.log("Total time front: "+(Date.now()-startTime)/1000);              
                window.prevUrl = Globals.storageDomain+'/'+urls.vid+"?"+Date.now();
                updateGlobalState("back", true);
                updateGlobalState("next", true);
                setWait(false);
            }
            //set flag
            window.prevStarted = true;
            // start time
            startTime = Date.now();        
            // call makePreview
            uid = window.uid;
            mid = window.mid;
            makeVidPrev({
                uid: uid,
                mid: mid
            });
            await window.sleep(5); // wait for db reset
            //register database listeners
            //status
            const statusRef = ref(database, 'prevs/'+uid+"/"+mid+'/status');
            const unsubStatus = onValue(statusRef, (snapshot) => {
                const status = snapshot.val();
                setStatus(status);
            });
            //urls
            const urlsRef = ref(database, 'prevs/'+uid+"/"+mid+'/urls');
            const unsubUrls = onValue(urlsRef, (snapshot) => {
                const urls = snapshot.val();
                if(urls){
                    //stop listeners
                    unsubUrls();
                    unsubStatus();
                    //mountMedia
                    mountMedia(urls);
                }
            });
        }
        useEffect(()=>{
            if(!window.prevStarted) init();
            else if(window.prevUrl){
                updateGlobalState("back", true);
                updateGlobalState("next", true);
                setWait(false);
            }
        },[])

        const Wait = ()=>{
            const [endTime, setEndTime] = useState();
            const TimeMsg = ()=>{
                const renderTime = 40;
                const [remTime, setRemTime] = useState("00:"+renderTime);
                const count = ()=>{
                    if(startTime){
                        const elapSec = Math.floor( Number(Date.now())/1000 - Number(startTime)/1000 );            
                        const remSec = renderTime-elapSec;
                        if(remSec>0){
                            //seperate to minutes and seconds
                            var minutes = Math.floor(remSec/60);
                            var seconds = remSec%60;
                            //set time string
                            if(minutes<10) minutes = '0'+minutes;
                            if(seconds<10) seconds = '0'+seconds;
                            setRemTime(minutes+':'+seconds);
                            setTimeout(count,1000);
                        }
                        else setEndTime(true);
                    }        
                    else setTimeout(count,1000);
                }
                useEffect(count,[]);
                return(
                    <div className="font-40 text-white text-center">
                        {remTime}
                    </div>
                )
            }
            const Count = ()=>{
                return(
                    <>
                        <div className="font-20 text-white">
                            Your preview will be ready in:
                        </div>
                        <TimeMsg/>
                    </>
                )
                
            }
            return(
                <div className="relative flex-col-center full-width">
                    <div className="absolute flex-col-center">
                        <Loader width={"50%"}/>
                        {endTime?<img src={loaderGif} width="100"/>:<Count/>}
                    </div>
                    <canvas width="1920" height="1080" className="full-width bg-dark-blue-2 b-r-20"/>
                </div>
            )
        }
        const Video = ()=>{
            const [locWait, setLocWait] = useState(true);
            const vidRef = useRef();
            const [showPlay, setShowPlay] = useState(true);
            const thumbSrc = Globals.storageDomain+'/users/'+window.uid+'/movies/'+window.mid+'/assets/thumb.jpg?'+Date.now();
            const doPlay = ()=>{
                setShowPlay(false);
                vidRef.current.play();
            }
            const PlayBut = ()=>{
                return(
                    <div className="absolute flex-col-center full-width z-10">
                        <img src={playImg} className="absolute link grow z-10" style={{width:"25%"}} onClick={doPlay}/>
                        {/* <img src={thumbSrc} className='full-width b-r-20'/> */}
                    </div>
                )
            }
            useEffect(()=>{
                vidRef.current.addEventListener('canplaythrough', ()=>{
                    setLocWait(false);
                });
                vidRef.current.addEventListener('pause', function() {
                    setShowPlay(true);
                });
                vidRef.current.addEventListener('playing', function() {
                    setShowPlay(false);
                });
                vidRef.current.addEventListener('contextmenu', function(event) {
                    event.preventDefault(); // Prevent the context menu from showing
                });                
            },[])
            return(
                <div className="relative flex-col-center full-width">
                    {locWait?<img src={loaderGif} width="50" className="absolute"/>:(showPlay?<PlayBut/>:"")}
                    <video ref={vidRef} src={window.prevUrl} className='full-width bg-dark-blue-2 b-r-20' playsInline muted loop preload='auto' style={{height:"auto"}}/>
                </div>
            )
        }
        return(
            <div className="flex-col-center margin-20">
                <div className="relative flex-col-center" style={{
                    width:"500px",
                    maxWidth:"90vw"
                }}>
                    {(!window.prevUrl && wait)?<Wait/>:<Video/>}
                </div>
            </div>
        )
    }

    // render
    return (
        <div className="flex-col-center">
            {wait?<TitleA/>:<TitleB/>}
            {wait?"":<FullButton/>}
            <Stage/>
            {/* <DownButton/> */}     
        </div>
    )
}
export default StageE2;

// end
