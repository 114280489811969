// Wait.js
import loaderGif from "assets/images/general/loader.gif";
import "./Wait.css";

const Wait = ()=>{
    return (
        <div className="relative flex-col-center" style={{
            width:"700px",
            maxWidth:"90vw"
        }}>
            <div className="absolute flex-col-center padding-40 b-r-20 margin-20">
                <div className="font-30 title-font text-white text-center">
                    Please wait...
                </div>
                <img src={loaderGif} className="margin-10" width="100"/>
            </div>
            <canvas width="1920" height="1080" className="full-width bg-dark-blue-2 b-r-20"/>
        </div>
    )
}
export default Wait;

// end
