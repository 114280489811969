// Blog.js
import { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Menu from "comps/Menu/Menu";
import Footer from "comps/Footer/Footer";
import Post_00 from "./Posts/00/Post_00.js";
import Post_01 from "./Posts/01/Post_01.js";
import Post_02 from "./Posts/02/Post_02.js";
import Post_03 from "./Posts/03/Post_03.js";
import Post_04 from "./Posts/04/Post_04.js";
import Post_05 from "./Posts/05/Post_05.js";
import StartButton from "comps/StartButton/StartButton";
import dividerImg from "assets/images/general/divider.svg";
import dividerSmallImg from "assets/images/general/divider_small.svg";
import "./Blog.css";

// global inits
const posts = [
    Post_00,
    Post_01,
    Post_02,
    Post_03,
    Post_04,
    Post_05
]
const paths = []
const path2index = {}
posts.forEach((post, index)=>{
    const path = post.title.split(' ').join('-');
    paths.push(path);
    path2index[path] = index;
})

// main
const Blog = ()=>{
    // inits
    const navigate = useNavigate();
    const location = useLocation();
    const [postNum, setPostNum] = useState(null);
    const { postPath } = useParams();

    // effects
    useEffect(()=>{
        setPostNum(path2index[postPath]);
    },[location]);

    // comps
    const Header = ()=>{
        return(
            <div className="flex-col-center hero-container full-width padding-20">
                <Menu page="Blog"/>
            </div>
        )
    }
    const Title = ()=>{
        return(
            <div className='flex-col-center'>
                <h1 className="font-40 title-font text-center" style={{color:"#1a76d2"}}>
                    Toon Kids Birthday Blog
                </h1>
                <h2 className="font-30 title-font text-center" style={{color:"#1a76d2"}}>
                    Dive into Magical Tales, Tips, and Party Ideas!
                </h2>
            </div>
        )
    }
    const Call = ()=>{
        return(
            <div className='flex-col-center'>
                <div className="font-30 title-font text-center margin-20" style={{color:"#004975"}}>
                    Let's make your child's birthday celebration one they'll never forget!
                </div>
                <StartButton fontSize="25"/>
                <img src={dividerSmallImg} className="margin-40" height="30"/>
            </div>
        )
    }
    const Back = ()=>{
        const action = ()=>{
            navigate("/kids-birthday-ideas-blog");
        }
        return(
            <button className='margin-20 font-20' onClick={action}>
                {"<<"} Back
            </button>
        )
    }
    const Previews = ()=>{
        const Preview = ({post, index})=>{
            const action = ()=>{
                navigate("/kids-birthday-ideas-blog/"+paths[index]);
                window.scrollTo(0, 0);
            }
            return(
                <div className="flex-col-center link blue-bg padding-20 b-r-20 margin-20" onClick={action} style={{
                    width: "400px",
                    maxWidth: "90vw"
                }}>
                    <img src={post.coverImg} className="b-r-20" width="300" />
                    <div className="title-font text-center text-white font-20">
                        {post.title}
                    </div>
                </div>
            )
        }
        return(
            <div className="flex-row-center flex-wrap">
                {posts.map((post,index)=><Preview post={post} index={index} key={index}/>)}
            </div>
        )
    }
    const Post = ()=>{
        return(
            <div className="blog-post relative flex-col-center" style={{
                width: "600px",
                maxWidth: "90vw"
            }}>
                <img src={posts[postNum].coverImg} className='full-width'/>
                {posts[postNum].content}
            </div>
        )
    }

    // render
    return (
        <div className="flex-col-center">
            <Header/>
            <div className='margin-20'/>
            {postNum!=null?<Back/>:<Title/>}
            <img src={dividerImg} className="margin-20" height="50"/>
            {postNum!=null?<Post/>:<Previews/>}
            <img src={dividerImg} className="margin-20" height="50"/>
            <Call/>
            <Footer/>
        </div>
    )
}

export default Blog;

// end
