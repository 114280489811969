// StageB4.js
import { useState, useEffect, useRef } from 'react';
import { useGlobalState } from "../../GlobalState";
import { useNavigate } from "react-router-dom";
import rotImg from "assets/images/editor/stageB/rot.svg";
import scaleImg from "assets/images/editor/stageB/scale.svg";
import loaderGif from "assets/images/general/loader.gif";
import "./StageB4.css";

const globals = {
    chinFactor: 0.5,
    scaleFactor: 1.05,
    dotSize: 80,
    headCorFac: 0.9,
    dotsLoaded: null,
    dotA: null,
    dotB: null,
    mouseDown: null,
    slctPnt: null,
    controlPoints: null,
    maskCtx: null,
    drawData: null,
    dotShiftX: null,
    dotShiftY: null,
    faceExportCanv: document.createElement('canvas')
}
const events = [
    'onmousedown','onmouseup','onmouseout','onmousemove','onmouseleave',
    'ontouchstart','ontouchmove','ontouchend','ontouchcancel'
]

const StageB4 = ()=>{
    //inits
    const [globalState, updateGlobalState] = useGlobalState();
    const navigate = useNavigate();
    const [wait, setWait] = useState(true);

    //functions
    const loadDots = async()=>{
        if(!globals.dotsLoaded){
            globals.dotsLoaded = true;
            const prefix = "/images/editor/StageB";
            globals.dotA = await window.loadImage(prefix+"/dotA.png");
            globals.dotB = await window.loadImage(prefix+"/dotB.png");
        }
        setWait(false);
    }
    const makeFaceExport = (points, faceCanvas)=>{
        window.exportData.movie.head.eyes = points[0].y;
        window.exportData.movie.head.width = points[4].x-points[0].x;
        window.exportData.movie.head.height = points[2].y-points[6].y;
        const exportCanv = globals.faceExportCanv;
        exportCanv.width = faceCanvas.width;
        exportCanv.height = faceCanvas.height;
        const ctx = exportCanv.getContext('2d');
        ctx.clearRect(0,0,exportCanv.width,exportCanv.height);
        ctx.drawImage(faceCanvas,0,0);
        window.localImages.faceImg = exportCanv;
    }

    //effect
    useEffect(()=>{
        window.scrollTo(0, 0);
        loadDots();
        updateGlobalState("stage",2);
        updateGlobalState("back",true);
        updateGlobalState("next",true);
        window.backFunc = ()=>{
            navigate('/editor/b/3');
        }
        window.nextFunc = ()=>{
            navigate('/editor/c');
        }
    },[])

    //comps
    
    const Stage = ()=>{
        //inits
        const stageRef = useRef();
        const rotRef = useRef();
        const scaleRef = useRef();
        const dotARef = useRef();
        const dotBRef = useRef();
        const [editMode, setEditMode] = useState();

        //functions
        const initStage = ()=>{
            const initPoints = ()=>{
                const makePoints = ()=>{
                    //dec vars
                    const masterHeadSize = window.masterHeadSize;
                    const headCorFac = globals.headCorFac;
                    var x,y;
                    const points = [];
                    const height = masterHeadSize*headCorFac;
                    const width = height*0.7;
                    const xStart = (masterHeadSize/2)-(width/2);
                    const yStart = (masterHeadSize-height)/2;

                    //make points
                    //left ear
                    x = xStart;
                    y = (yStart+height)/2;
                    points[0] = {x:x,y:y};
                    //left jaw
                    x = xStart+(width/20);
                    y = (yStart+height)-((height-points[0].y)/2);
                    points[1] = {x:x,y:y};
                    //chin
                    x = xStart+(width/2);
                    y = yStart+height;
                    points[2] = {x:x,y:y};
                    //right jaw
                    x = xStart+width-(width/20);
                    y = points[1].y;
                    points[3] = {x:x,y:y};
                    //right ear
                    x = xStart+width;
                    y = points[0].y;
                    points[4] = {x:x,y:y};

                    //finish head
                    //top points
                    var headTopX = points[2].x;
                    var headTopY = yStart;
                    //round factor
                    var roundGap = points[4].x-headTopX;
                    var roundFactor = roundGap/1.4;
                    //right round head point
                    x = points[4].x;
                    y = headTopY+roundGap;
                    if(points[4].y < y+5){
                        points[4].y = y+5;
                        points[0].y = points[4].y;
                    }
                    points.push({x:x,y:y});
                    //head top point
                    points.push({x:headTopX,y:headTopY});
                    //left round head point
                    x = points[0].x;
                    points.push({x:x,y:y});
                    //finish
                    points.push(points[0]);

                    //return
                    return {
                        points:points,
                        factor:masterHeadSize/window.faceCanvas.height,
                        shiftX:0,
                        shiftY:0,
                        width:width,
                        height:height
                    };
                }
                const loadPoints = (faceData)=>{
                    const pointList = [0,4,8,12,16];//ears(0,16),jaws(4,12),cheen(8) - see: https://ibug.doc.ic.ac.uk/resources/facial-point-annotations/
                    const points = [];
                    pointList.forEach((index, i) => {
                        points.push({
                            x:faceData.landmarks.positions[index].x,
                            y:faceData.landmarks.positions[index].y
                        });
                    });
                    return points;
                }
                const findAngle = (points)=>{
                    const a = points[0];//left ear
                    const b = points[4];//right ear
                    var radians = Math.atan2(a.y - b.y, a.x - b.x);
                    const correct = 180*Math.PI/180;
                    radians = radians-correct;
                    return radians;
                }
                const rotPoints = (angle, points)=>{
                    const rotPt = (point, anchor, radians)=>{
                        var x = point.x;
                        var y = point.y;
                        var cx = anchor.x;
                        var cy = anchor.y;
                        var cos = Math.cos(radians);
                        var sin = Math.sin(radians);
                        var nx = (cos * (x - cx)) + (sin * (y - cy)) + cx;
                        var ny = (cos * (y - cy)) - (sin * (x - cx)) + cy;
                        return {x:nx,y:ny};
                    }
                    const anchor = {
                        x:(points[0].x+points[4].x)/2,
                        y:(points[0].y+points[4].y)/2
                    };
                    const nPoints = [];
                    points.forEach((point, i) => {
                        const np = rotPt(point,anchor,angle);
                        nPoints.push({x:np.x,y:np.y});
                    });
                    return nPoints;
                }
                const finishHead = (tmpPoints)=>{
                    //dup points
                    const points = [];
                    for(var point of tmpPoints) points.push({x:point.x,y:point.y});

                    //make head height
                    const whFactor = 1.9;
                    const headHeight = (points[2].y-points[0].y)*whFactor;

                    //top points
                    var headTopX = (points[0].x+points[4].x)/2;
                    var headTopY = points[2].y-headHeight;
                    //round factor
                    var roundGap = points[4].x-headTopX;
                    var roundFactor = roundGap/1.4;
                    //right round head point
                    var x = points[4].x;
                    var y = headTopY+roundGap;
                    if(points[4].y < y+5){
                        points[4].y = y+5;
                        points[0].y = points[4].y;
                    }
                    points.push({x:x,y:y});
                    //head top point
                    points.push({x:headTopX,y:headTopY});
                    //left round head point
                    x = points[0].x;
                    points.push({x:x,y:y});
                    //finish
                    points.push(points[0]);
                    return points;
                }
                const cropPoints = (points)=>{
                    //find factor
                    const headHeight = (points[2].y-points[6].y);
                    const headWidth = (points[4].x-points[0].x);
                    const factor = window.masterHeadSize/headHeight*globals.headCorFac;
                    const width = headWidth*factor;
                    const height = headHeight*factor;

                    //find
                    const xStart = (window.masterHeadSize-width)/2;
                    const yStart = (window.masterHeadSize-height)/2;
                    const shiftX = points[0].x*factor-xStart;
                    const shiftY = points[6].y*factor-yStart;

                    //crop/scale points
                    const nPoints = [];
                    points.forEach((point, i) => {
                        const np = {
                            x:(point.x*factor)-shiftX,
                            y:(point.y*factor)-shiftY
                        }
                        nPoints.push({x:np.x,y:np.y});
                    });

                    //face pos
                    return {
                        points:nPoints,
                        factor:factor,
                        shiftX:shiftX,
                        shiftY:shiftY,
                        width:width,
                        height:height
                    };
                }

                // init face points
                if(globals.selectedFace=='none'){
                    globals.drawData = makePoints();
                    globals.angle = 0;
                }
                else{
                    var tmpPoints = loadPoints(window.faces[globals.selectedFace]);
                    globals.angle = findAngle(tmpPoints);
                    tmpPoints = rotPoints(globals.angle,tmpPoints);
                    tmpPoints = finishHead(tmpPoints);
                    globals.drawData = cropPoints(tmpPoints);
                }

                // assign to init state for reset button
                globals.initDrawData = JSON.parse( JSON.stringify(globals.drawData) );
                globals.initAnlge = globals.angle;
                globals.initScaleFactor = globals.scaleFactor;
            }

            // add listeners
            if(editMode) addListeners();

            // init face points
            if(window.selectedFace != globals.selectedFace || window.newFace){
                window.newFace = false;
                globals.selectedFace = window.selectedFace;
                initPoints();
            }

            //draw
            drawStage();
        }
        const drawStage = ()=>{
            const drawHead = (drawData, angle)=>{
                // init data
                const faceImg = window.faceCanvas;
                const x = -drawData.shiftX-(window.masterHeadSize/2);
                const y = -drawData.shiftY-(window.masterHeadSize/2);
                const width = faceImg.width*drawData.factor;
                const height = faceImg.height*drawData.factor;
                //draw
                const canvas = stageRef.current;
                canvas.width = window.masterHeadSize;
                canvas.height = window.masterHeadSize;
                const ctx = canvas.getContext('2d');
                ctx.resetTransform();
                ctx.fillRect(0,0,canvas.width,canvas.height);
                ctx.imageSmoothingEnabled = true;
                ctx.imageSmoothingQuality = "high";
                ctx.translate(window.masterHeadSize/2, window.masterHeadSize/2);
                ctx.rotate(-angle);
                ctx.drawImage(faceImg,x*globals.scaleFactor,y*globals.scaleFactor,width*globals.scaleFactor,height*globals.scaleFactor);

                //test
                // const ctx2 = canvas.getContext('2d');
                // ctx2.fillStyle = "#FF0000";
                // ctx2.font = "30px Arial";
                // const sX = 0;
                // const sY = 0;
                // drawData.points.forEach((point, i) => {
                //     ctx2.fillRect(point.x-sX,point.y-sY,10,10);
                //     ctx2.fillText(i, point.x+15-sX, point.y-sY);
                // });

            }
            const drawMask = (nPoints)=>{
                const makeCps = (nPoints)=>{
                    const GCP = (x0,y0,x1,y1,x2,y2,t)=>{
                        var d01=Math.sqrt(Math.pow(x1-x0,2)+Math.pow(y1-y0,2));
                        var d12=Math.sqrt(Math.pow(x2-x1,2)+Math.pow(y2-y1,2));
                        var fa=t*d01/(d01+d12);   // scaling factor for triangle Ta
                        var fb=t*d12/(d01+d12);   // ditto for Tb, simplifies to fb=t-fa
                        var p1x=x1-fa*(x2-x0);    // x2-x0 is the width of triangle T
                        var p1y=y1-fa*(y2-y0);    // y2-y0 is the height of T
                        var p2x=x1+fb*(x2-x0);
                        var p2y=y1+fb*(y2-y0);
                        return [p1x,p1y,p2x,p2y];
                    }

                    const chinFactor = globals.chinFactor;

                    //duplicate points object
                    const points = [];
                    nPoints.forEach((point, i) => {
                        points.push({x:point.x,y:point.y});
                    });

                    //top points
                    var headTopX = (points[0].x+points[4].x)/2;//points[6].x?
                    var headTopY = points[6].y;

                    points.pop();
                    points.pop();
                    points.pop();
                    points.pop();

                    //init cps
                    const cps = [];

                    //round factor
                    var roundGap = points[4].x-headTopX;
                    var roundFactor = roundGap/1.4;

                    //left Ear down cp
                    var x = points[0].x;
                    var y = (points[0].y+points[1].y)/2;
                    cps.push( {x:x,y:y} );

                    //loop threw points
                    var cp;
                    var tension = 0.2;
                    for (var i=1;i<points.length-1;i++){
                        if(i==2){//chin
                            x = points[2].x-((points[2].x-points[1].x)*chinFactor);
                            y = points[2].y;
                            cps.push( {x:x,y:y} );
                            x = points[2].x+((points[3].x-points[2].x)*chinFactor);
                            cps.push( {x:x,y:y} );
                        }
                        else{
                            cp = GCP(points[i-1].x,points[i-1].y,points[i].x,points[i].y,points[i+1].x,points[i+1].y,tension);
                            cps.push({x:cp[0],y:cp[1]});
                            cps.push({x:cp[2],y:cp[3]});
                        }
                    }

                    //complete head points
                    //right round head point
                    x = points[4].x;
                    y = headTopY+roundGap;
                    if(points[4].y < y+5){
                        points[4].y = y+5;
                        points[0].y = points[4].y;
                    }
                    points.push({x:x,y:y});
                    //head top point
                    points.push({x:headTopX,y:headTopY});
                    //left round head point
                    x = points[0].x;
                    points.push({x:x,y:y});
                    //finish
                    points.push(points[0]);

                    //right ear down cp
                    x = points[4].x;
                    y = (points[4].y+points[3].y)/2;
                    cps.push({x:x,y:y});

                    //right ear up cp
                    x = points[4].x;
                    y = points[4].y-1;
                    cps.push({x:x,y:y});

                    //right round head down cp
                    x = points[4].x;
                    y = points[5].y+1;
                    cps.push({x:x,y:y});

                    //right round head up cp
                    x = points[4].x;
                    y = points[5].y-roundFactor;
                    cps.push({x:x,y:y});

                    //top cp right
                    x = (headTopX+points[5].x)/2;
                    y = headTopY;
                    cps.push({x:x,y:y});

                    //top cp left
                    x = (headTopX+points[0].x)/2;
                    y = headTopY;
                    cps.push({x:x,y:y});

                    //left round head up cp
                    x = points[0].x;
                    y = points[7].y-roundFactor;
                    cps.push({x:x,y:y});

                    //left round head down cp
                    x = points[0].x;
                    y = points[7].y+1;
                    cps.push({x:x,y:y});

                    //left ear up cp
                    x = points[0].x;
                    y = points[0].y-1;
                    cps.push({x:x,y:y});
                    return {
                        cps:cps,
                        points:points
                    }
                }

                // make cps
                const res = makeCps(nPoints);
                const points = res.points;
                const cps = res.cps;

                // create mask
                const canv = stageRef.current;
                const ctx = canv.getContext('2d');
                ctx.resetTransform();
                ctx.beginPath();
                ctx.moveTo(points[0].x, points[0].y);
                for(var i=1;i<points.length;i++){
                    const n = (i*2)-2;
                    const p1x = cps[n].x;
                    const p1y = cps[n].y;
                    const p2x = cps[n+1].x;
                    const p2y = cps[n+1].y;
                    const p3x = points[i].x;
                    const p3y = points[i].y;
                    ctx.bezierCurveTo(p1x,p1y,p2x,p2y,p3x,p3y);
                }

                // draw mask
                ctx.globalCompositeOperation = 'destination-in';
                ctx.fill();
                ctx.globalCompositeOperation = 'source-over';

                //assign to export face
                makeFaceExport(points, stageRef.current);

                //asign to global for face hover detection
                globals.maskCtx = ctx;
            }
            const drawDots = async(points)=>{
                const dotSize = globals.dotSize;
                globals.controlPoints = [];
                const canv = stageRef.current;
                const ctx = canv.getContext('2d');
                ctx.resetTransform();
                var dotImg;
                for(var i=0;i<=4;i++){
                    dotImg = globals.dotA;
                    const x = points[i].x-dotSize/2;
                    const y = points[i].y-dotSize/2;
                    globals.controlPoints.push({
                        start:{
                            x: x,
                            y: y
                        },
                        end:{
                            x: x+dotSize,
                            y: y+dotSize
                        }
                    });
                    if(globals.slctPnt!=null){
                        if(i==0 || i==4){
                            if(globals.slctPnt==0 || globals.slctPnt==4){
                                dotImg = globals.dotB;
                            }
                        }
                        else if(i==globals.slctPnt) dotImg = globals.dotB;
                    }
                    ctx.drawImage(dotImg,x,y,dotSize,dotSize);
                }
            }
            drawHead(globals.drawData, globals.angle);
            drawMask(globals.drawData.points);
            if(editMode) drawDots(globals.drawData.points);
        }
        const addListeners = ()=>{
            const getLocalMousePos = (e,parent=false)=>{
                var rect = e.target.getBoundingClientRect();
                if(parent) rect = e.target.parentElement.getBoundingClientRect();
                const x = e.clientX - rect.left;
                const y = e.clientY - rect.top;
                return {x:x,y:y,rect:rect}
            }
            const stageHandler = (e)=>{
                //general
                const changeCursor = (type)=>{
                    stageRef.current.style.cursor = type;
                }
                const getMousePos = (e)=>{
                    const mPos = getLocalMousePos(e);
                    const factor = mPos.rect.width/stageRef.current.width;
                    const x = mPos.x/factor;
                    const y = mPos.y/factor;
                    return {x:x,y:y}
                }
                const checkHover = (e)=>{
                    const mPos = getMousePos(e);
                    var res = false;
                    if(globals.mouseDown && globals.slctPnt!=null){//still dragging same point
                        res = mPos;
                    }
                    else{
                        globals.slctPnt = null;
                        globals.controlPoints.some((point, i) => {
                            if(
                                mPos.x>point.start.x &&
                                mPos.y>point.start.y &&
                                mPos.x<point.end.x &&
                                mPos.y<point.end.y
                            )
                            {
                                if(!globals.mouseDown) globals.slctPnt = i;
                                res = mPos;
                                return true;
                            }
                        });
                        if(!res && globals.maskCtx.isPointInPath(mPos.x,mPos.y) ){//check face hover
                            if(!globals.mouseDown) globals.slctPnt = 5;
                            res = mPos;
                        }
                    }
                    return res;
                }
                //operations
                const rotPt = (point)=>{
                    const anchor = {x:window.masterHeadSize/2,y:window.masterHeadSize/2};
                    const radians = -globals.angle;
                    var x = point.x;
                    var y = point.y;
                    var cx = anchor.x;
                    var cy = anchor.y;
                    var cos = Math.cos(radians);
                    var sin = Math.sin(radians);
                    var nx = (cos * (x - cx)) + (sin * (y - cy)) + cx;
                    var ny = (cos * (y - cy)) - (sin * (x - cx)) + cy;
                    return {x:nx,y:ny};
                }
                const translatePoint = (mPos)=>{
                    const stageSize = window.masterHeadSize;
                    const points = globals.drawData.points;
                    const point = globals.slctPnt;
                    const oldX = points[point].x;
                    const oldY = points[point].y;
                    if(!globals.dotShiftX){
                        globals.dotShiftX = (globals.dotSize/2)-(mPos.x-globals.controlPoints[point].start.x);
                        globals.dotShiftY = (globals.dotSize/2)-(mPos.y-globals.controlPoints[point].start.y);
                    }
                    const newX = mPos.x+globals.dotShiftX;
                    const newY = mPos.y+globals.dotShiftY;
                    const minGap = 100;//100px min gap between points
                    //assign new values
                    points[point].x = newX;
                    if(point!=2) points[point].y = newY;//chin only moves on x
                    //constraints
                    if(point==0 || point==4){//eyes
                        //eyes move together on X and Y
                        points[0].y = points[point].y;
                        points[4].y = points[point].y;
                        const gapX = (points[4].x - points[0].x)/2;
                        points[0].x = points[6].x-gapX;
                        points[4].x = points[6].x+gapX;

                        //eyes can't pass half way to top on Y
                        if(points[0].y < points[5].y+1){
                            points[0].y = points[5].y+1;
                            points[4].y = points[0].y;
                        }

                        //eyes can't pass jaws on Y
                        if(newY > points[1].y-minGap){
                            points[0].y = points[1].y-minGap;
                            points[4].y = points[0].y;
                        }
                        if(newY > points[3].y-minGap){
                            points[0].y = points[3].y-minGap;
                            points[4].y = points[0].y;
                        }

                        //eyes can't pass head top on X
                        if(points[0].x > points[6].x-minGap){
                            points[0].x = points[6].x-minGap;
                            points[4].x = points[6].x+minGap;
                        }

                        //eyes can't get near to canvas edge
                        if(points[0].x<minGap){
                            points[0].x = minGap;
                            points[4].x = stageSize-minGap;
                        }
                    }
                    else if(point==1 || point==3){//jaws
                        //jaw can't pass chin on Y
                        if(newY > points[2].y-minGap){
                            points[point].y = points[2].y-minGap;
                        }
                        //jaw can't pass eyes on Y
                        if(newY < points[0].y+minGap){
                            points[point].y = points[0].y+minGap;
                        }
                        //jaws can't pass chin on X
                        if(point==1){//when left jaw is moved
                            if(points[1].x>points[2].x-minGap){
                                points[1].x = points[2].x-minGap;
                            }
                        }
                        else{//when right jaw is moved
                            if(points[3].x<points[2].x+minGap){
                                points[3].x = points[2].x+minGap;
                            }
                        }
                        //canvas edge
                        if(points[1].x<minGap){
                            points[1].x = minGap;
                        }
                        if(points[3].x>stageSize-minGap){
                            points[3].x =stageSize-minGap;
                        }
                    }
                    else if(point==2){//chin
                        //can't pass jaws on x
                        if(newX < points[1].x+minGap){
                            points[2].x = points[1].x+minGap;
                        }
                        if(newX > points[3].x-minGap){
                            points[2].x = points[3].x-minGap;
                        }
                        //chin factor
                        globals.chinFactor += (globals.oldChin - newY)/500;
                        if(globals.chinFactor<0.3) globals.chinFactor = 0.3;
                        if(globals.chinFactor>0.6) globals.chinFactor = 0.6;
                        globals.oldChin = newY;
                    }
                    //assign back to global
                    globals.drawData.points = points;
                }
                const translateFace = (mPos)=>{
                    const oldPos = globals.oldPosStage;
                    const newMpos = rotPt(mPos);
                    globals.drawData.shiftX -= (newMpos.x-oldPos.x)/globals.scaleFactor;
                    globals.drawData.shiftY -= (newMpos.y-oldPos.y)/globals.scaleFactor;
                    globals.oldPosStage = newMpos;
                }
                //handlers
                const down = (evt)=>{
                    if(globals.slctPnt!=null){
                        if(globals.slctPnt==5) changeCursor('grabbing');
                        else changeCursor('move');
                    }
                    globals.oldPosStage = rotPt(getMousePos(evt));
                    const mPos = checkHover(evt);
                    globals.oldChin = mPos.y;
                    globals.mouseDown = true;
                    globals.newTouch = true;
                }
                const move = (evt)=>{
                    const mPos = checkHover(evt);
                    if(mPos){
                        e.preventDefault();
                        if(//mouse down
                            globals.mouseDown &&
                            globals.slctPnt!=null &&
                            !isNaN(mPos.x) && !isNaN(mPos.y)
                        ){
                            if(globals.slctPnt==5){//head
                                changeCursor('grabbing');
                                //check multi touch
                                if(e.touches && e.targetTouches.length>1){
                                    //functions
                                    const ptDist = (p1,p2)=>{
                                        const distX = p1.x-p2.x;
                                        const distY = p1.y-p2.y;
                                        return Math.sqrt(distX*distX + distY*distY);
                                    }
                                    const pAngleDeg = (p1,p2)=>{
                                        return (Math.atan2(p2.y-p1.y, p2.x-p1.x)*180/Math.PI);
                                    }

                                    //get touches positions
                                    const posA = getMousePos(e.targetTouches[0]);
                                    const posB = getMousePos(e.targetTouches[1]);
                                    const avMpos = {
                                        x:(posA.x+posB.x)/2,
                                        y:(posA.y+posB.y)/2
                                    }
                                    const newMpos = rotPt(avMpos);
                                    //check if new touch
                                    if(globals.newTouch){
                                        globals.oldPosA = posA;
                                        globals.oldPosB = posB;
                                        globals.oldPosStage = newMpos;
                                        globals.newTouch = false;
                                    }
                                    var shift;

                                    //position
                                    translateFace(avMpos);

                                    // //rotation
                                    shift = pAngleDeg(posA,posB)-pAngleDeg(globals.oldPosA,globals.oldPosB);
                                    globals.angle -= shift*Math.PI/180;

                                    //scale
                                    shift = ptDist(posA,posB)/ptDist(globals.oldPosA,globals.oldPosB);
                                    globals.scaleFactor *= shift;
                                    if(globals.scaleFactor<0.005) globals.scaleFactor = 0.005;

                                    //new to old
                                    globals.oldPosA = posA;
                                    globals.oldPosB = posB;
                                }
                                else translateFace(mPos);
                            }
                            else{//a point
                                changeCursor('move');
                                translatePoint(mPos);
                            }
                        }
                        else{//hover
                            if(globals.slctPnt==5) changeCursor('grab');
                            else changeCursor('pointer');
                        }
                    }
                    else changeCursor('default');
                    // either way draw stage
                    drawStage();
                }
                const up = (evt)=>{
                    if(globals.slctPnt!=null){
                        if(globals.slctPnt==5) changeCursor('grab');
                        else changeCursor('pointer');
                    }
                    globals.dotShiftX = null;
                    globals.dotShiftY = null;
                    globals.mouseDown = false;
                }

                //check touch
                var evt = e;
                if(e.touches){
                    if(e.type=='touchend' || e.type=='touchcancel') evt = e.changedTouches[0];
                    else evt = e.targetTouches[0];
                }
                //events
                switch(e.type){
                    case 'mousedown':
                    case 'touchstart':
                        down(evt);
                        break;
                    case 'mousemove':
                    case 'touchmove':
                        move(evt);
                        break;
                    case 'mouseup':
                    case 'mouseout':
                    case 'onmouseleave':
                    case 'touchend':
                    case 'touchcancel':
                        up(evt);
                        break;
                    default:
                        return true;
                }
            }
            const sliderHandler = (e)=>{
                const moveSlider = (mPos)=>{
                    const sliderSize = evt.target.getBoundingClientRect().width;
                    evt.target.style.left = (mPos.x-sliderSize/2)+'px';
                }
                const rotate = (mPos)=>{
                    const factor = 0.3;
                    const shift = (mPos.x-globals.oldSliderPos)*factor;
                    globals.angle += shift*Math.PI/180*(-1);
                }
                const scale = (mPos)=>{
                    const factor = 0.002;
                    const shift = (mPos.x-globals.oldSliderPos)*factor;
                    globals.scaleFactor += shift;
                    if(globals.scaleFactor<0.005) globals.scaleFactor = 0.005;
                }
                const down = (evt)=>{
                    const mPos = getLocalMousePos(evt,true);
                    moveSlider(mPos);
                    globals.oldSliderPos = mPos.x;
                    globals.mouseDown = true;
                }
                const move = (evt)=>{
                    if(globals.mouseDown){
                        const mPos = getLocalMousePos(evt,true);
                        if(mPos.x<=0) return true;
                        if(mPos.x>=mPos.rect.width) return true;
                        moveSlider(mPos);
                        (e.target.id=='rot')?rotate(mPos):scale(mPos);
                        globals.oldSliderPos = mPos.x;
                        drawStage();
                    }
                }
                const up = (evt)=>{
                    evt.target.style.left = 'auto';
                    globals.mouseDown = false;
                }
                //prevent default + check touch
                e.preventDefault();
                var evt = e;
                if(e.touches){
                    if(e.type=='touchend' || e.type=='touchcancel') evt = e.changedTouches[0];
                    else evt = e.targetTouches[0];
                }
                //switch
                switch(e.type){
                    case 'mousedown':
                    case 'touchstart':
                        down(evt);
                        break;
                    case 'mousemove':
                    case 'touchmove':
                        move(evt);
                        break;
                    case 'mouseup':
                    case 'mouseout':
                    case 'onmouseleave':
                    case 'touchend':
                    case 'touchcancel':
                        up(evt);
                        break;
                    default:
                        return true;
                }
            }
            events.forEach((event, i) => {
                stageRef.current[event] = stageHandler;
                rotRef.current[event] = sliderHandler;
                scaleRef.current[event] = sliderHandler;
            });
        }
        const removeListeners = ()=>{
            events.forEach((event, i) => {
                stageRef.current[event] = null;
            });
        }

        //comps
        const TitleA = ()=>{
            return(
                <div className="flex-col-center" style={{maxWidth:"95vw"}}>
                    <div className="font-30 title-font text-dark-blue text-center">
                        Looks good?
                    </div>
                    <div className="font-20 text-dark-blue text-center">
                        click the "Next" button to continue.<br/>
                        Click the "Edit" button to make changes.
                    </div>
                </div>
            )
        }
        const TitleB = ()=>{
            return(
                <div className="flex-col-center" style={{maxWidth:"95vw"}}>
                    <div className="font-30 title-font text-dark-blue text-center">
                        Edit Mode
                    </div>
                    <div className="font-20 text-dark-blue text-center">
                        Use the handlers to adjust the mask.<br/>
                        To cancel the changes click the "Reset" button.<br/>
                        When you're done click the "Save" button.
                    </div>
                </div>
            )
        }
        const Bar = (props)=>{
            return(
                <div className="relative flex-row-center full-width blue-bg b-r-20" style={{height:"20px"}}>
                    <img ref={props.type=="rot"?rotRef:scaleRef} src={props.type=="rot"?rotImg:scaleImg} id={props.type} className="absolute link grow non-drag" draggable="false" height="40" />
                </div>
            )
        }
        const Buttons = ()=>{       
            const Edit = ()=>{
                const edit = ()=>{
                    setEditMode(true)
                }
                return(
                    <button onClick={edit} className="font-20 margin-20">
                        Edit
                    </button>
                )
            }
            const EditButtons = ()=>{
                const Reset = ()=>{
                    const reset = ()=>{
                        globals.drawData = JSON.parse( JSON.stringify(globals.initDrawData) )
                        globals.angle = globals.initAnlge;
                        globals.scaleFactor = globals.initScaleFactor;
                        drawStage();
                    }
                    return(
                        <button onClick={reset} className="font-20 red-button margin-5">
                            Reset
                        </button>
                    )
                }
                const Save = ()=>{
                    const save = ()=>{
                        setEditMode(false);
                    }
                    return(
                        <button onClick={save} className="font-20 margin-5">
                            Save
                        </button>
                    )
                }
                return (
                    <div className='flex-row-center flex-warp margin-20'>
                        <Reset/>
                        <Save/>
                    </div>
                )
            }
            return editMode?<EditButtons/>:<Edit/>;
        }
        

        //effect
        useEffect(initStage,[]);
        useEffect(()=>{
            if(editMode){  
                addListeners();
                drawStage();
            }
            else{
                removeListeners();
                drawStage();
            }
        },[editMode]);

        //render
        return(
            <div className="flex-col-center">
                {editMode?<TitleB/>:<TitleA/>}
                <Buttons/>
                <div className="relative flex-col-center padding-20 bg-red b-r-20" style={{
                    width:"500px",
                    maxWidth:"90vw"
                }}>
                    {editMode?<Bar type="rot"/>:""}
                    <canvas ref={stageRef} className="full-width"/>
                    {editMode?<div className="margin-10"/>:""}
                    {editMode?<Bar type="scale"/>:""}
                </div>
            </div>
        )
    }
    const Wait = ()=>{
        return(
            <div className="flex-col-center">
                <div className="font-30 title-font text-dark-blue text-center">
                    Please wait...
                </div>
                <img src={loaderGif} className="margin-10" width="200"/>
            </div>
        )
    }

    //render
    return (
        wait?<Wait/>:<Stage/>
    )
}

export default StageB4;

// end
