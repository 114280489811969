// Dashboard.js
import { useState, useRef, useEffect } from 'react';
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { getStorage, ref, listAll } from "firebase/storage";
import Footer from "comps/Footer/Footer";
import Globals from "assets/modules/Globals";
import Wait from "comps/Wait/Wait";
import Movie from "comps/Movie/Movie";
import ErrMsg from "comps/ErrMsg/ErrMsg";
import Close from "comps/Close/Close";
import logoImg from "assets/images/general/logo.png";
import dividerImg from "assets/images/general/divider.svg";
import confettiImg from "assets/images/general/confeti.svg";
import loaderGif from "assets/images/general/loader.gif";
import "./Dashboard.css";

const movies = [];
var uid,mid;

const Dashboard = ()=>{
    const [error, setError] = useState();
    const [wait, setWait] = useState(true);
    const [overlayView, setOverlayView] = useState(false);
    
    const init = async()=>{
        const checkExists = async(path)=>{
            try{
                const listRef = ref(getStorage(), path);
                const list = await listAll(listRef);
                const files = list.items;
                var exists = false;
                files.forEach(file => {
                    if(file.name=="final.mp4") exists = true;
                });
                return Promise.resolve(exists);
            }
            catch(e){
                return Promise.reject();
            }     
        }
        try{
            const token = window.getParam('token');
            const userCredential = await signInWithCustomToken(getAuth(), token);
            uid = userCredential.user.uid;
            const listRef = ref(getStorage(), 'users/'+uid+'/movies');
            const list = await listAll(listRef);
            if(list.prefixes.length==0) throw new Error('no movies.');
            var count = 0;
            list.prefixes.forEach(async (movie) => {
                const exists = await checkExists(movie.fullPath);
                console.log(exists);
                if(exists) movies.push(movie.name);
                count++;
            });
            while(count!=list.prefixes.length) await window.sleep(0.5);
        }
        catch(err){
            if(err.message=='movies') setError("You don't have any movies.");
            else if(err.code=='auth/invalid-custom-token') setError("This code has expired.");
            else setError(err.message);
        }
        setWait(false);
    }
    useEffect(()=>{
        init();
    },[])

    // comps
    const Logo = ()=>{
        return(
            <a href="/" target="_blank" className="link grow margin-20">
                <img src={logoImg} width="150"/>
            </a>
        )
    }
    const Confetti = ()=>{
        return(
            <img src={confettiImg} className="absolute full-width" style={{
                top: "0px",
                left: "0px",
                opacity:"0.4"
            }}/>
        )
    }
    const MovOverlay = ()=>{
        const hideOverlay = ()=>{
            setOverlayView(false);
        }
        return(
            <div className="overlay flex-col-center z-10">    
                <div className="relative flex-col-center z-10" style={{
                    width:"700px",
                    maxWidth:"90vw"
                }}>
                    <Close action={hideOverlay}/>
                    <Movie uid={uid} mid={mid}/>
                </div>
                <div className='bg-dark-blue' onClick={hideOverlay}/>
            </div>
        )
    }
    const Cont = ()=>{
        const Mov = (props)=>{
            const showOverlay = ()=>{
                mid = props.mid;
                setOverlayView(true);
            }
            const src = Globals.storageDomain+"/users/"+uid+"/movies/"+props.mid+"/thumb.jpg";
            return(
                <div className="relative flex-col-center margin-10 link grow-small" style={{
                    width:"300px",
                    maxWidth:"90vw"
                }} onClick={showOverlay}>
                    <img src={src} className="absolute full-width b-r-20 z-2"/>
                    <img src={loaderGif} className="absolute z-1" height="10"/>
                    <canvas width={1920} height={1080} className='full-width b-r-20 blue-bg'/>  
                </div>
            )
        }
        return(
            <div className="relative flex-row-center flex-wrap">
                {movies.map((movie)=><Mov mid={movie} key={movie}/>)}
                {error?<ErrMsg msg={error}/>:""}
            </div>
        )
    }

    //render
    return(
        <div className="relative flex-col-center">
            {overlayView?<MovOverlay/>:""}
            <div className="relative flex-col-center z-1 padding-20">
                <Logo/>
                <img src={dividerImg} height="50"/>
                {wait?<Wait/>:<Cont/>}
                <img src={dividerImg} height="50"/>
                <div className="margin-100"/>      
            </div>    
            <Confetti/>
            <Footer/>
        </div>
    )
}
export default Dashboard;

// end
