// Menu.js
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import Logo from "comps/Logo/Logo";
import StartButton from "comps/StartButton/StartButton";
import burgerImg from "assets/images/menu/burger.svg";
import "./Menu.css";

const buttons = [
    {
        text: "About",
        link: "/about"
    },
    {
        text: "Pricing",
        link: "/pricing"
    },
    {
        text: "Blog",
        link: "/kids-birthday-ideas-blog"
    },
    {
        text: "FAQ",
        link: "/faq"
    },
    {
        text: "Free",
        link: "/free-birthday-party-invitations"
    }
]
const Menu = ({page})=>{
    // inits
    const navigate = useNavigate();
    const location = useLocation();

    // states
    const [showMenu, setShowMenu] = useState(false);

    // effects
    useEffect(()=>{
        window.setScroll(!showMenu);
    },[showMenu]);
    useEffect(()=>{
        window.scrollTo(0, 0);
    },[location]);

    // funcs
    const logoAction = ()=>{
        if(location.pathname!="/") navigate("/");
    }

    // comps
    const Burger = ()=>{
        const doMenu = ()=>{
            setShowMenu(!showMenu);
        }
        return(
            <div className="menu-burger link" onClick={doMenu}>
                <img src={burgerImg} />
            </div>
        )
    }
    const Button = (props)=>{
        const goTo = ()=>{
            navigate(props.link);
            if(props.mobile) setShowMenu(!showMenu);
        }
        return(
            <div className={"relative z-1 "+(props.text==page?"menu-button-selected":"menu-button")} onClick={goTo}>
                {props.text}
            </div>
        )
    }
    const Buttons = ()=>{
        return(
            <div className="menu-buttons-container justify-center align-center">
                {buttons.map((button, index)=><Button text={button.text} link={button.link} key={index}/>)}
                <div className="margin-20"/>
                <StartButton fontSize="20"/>
            </div>
        )
    }
    const ButtonsOverlay = ()=>{
        const hideOverlay = ()=>{
            setShowMenu(!showMenu);
        }
        return(
            <div className="menu-buttons-overlay overlay z-8">
                <div className="flex-col-center relative z-1 full-width full-height">
                    {buttons.map((button, index)=><Button text={button.text} link={button.link} mobile={true} key={index}/>)}
                    <div className="margin-20"/>
                    <StartButton fontSize="30"/>
                    <div className="bg-dark-blue" onClick={hideOverlay}/>
                </div>
            </div>
        )
    }
    

    //render
    return(
        <div className="relative">
            <div className="relative menu-land z-10">
                <div className="relative flex row justify-end align-center z-10">
                    <div className="menu-logo">
                        <Logo action={logoAction}/>
                    </div>
                    <Burger/>
                    <Buttons/>
                </div>
            </div>
            {showMenu?<ButtonsOverlay/>:""}
            <div className="menu-bg"/>
        </div>
    )
}

export default Menu;

// end
