// Land.js
import { useEffect, useRef, useState } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useNavigate } from "react-router-dom";
import Menu from "comps/Menu/Menu";
import Footer from "comps/Footer/Footer";
import StartButton from "comps/StartButton/StartButton";
import Text from "assets/modules/Text";
import ErrMsg from "comps/ErrMsg/ErrMsg";
import SendBut from "comps/SendBut/SendBut";
import playImg from "assets/images/general/play.svg";
import trailerPosterImg from "assets/images/land/trailer_poster.jpg";
import confettiImg from "assets/images/general/confeti.svg";
import wizardImg from "assets/images/land/wizard.png";
import fairyImg from "assets/images/land/fairy.png";
import girafeImg from "assets/images/land/girafe.png";
import dividerSmallImg from "assets/images/general/divider_small.svg";
import dividerImg from "assets/images/general/divider.svg";
import dividerBigImg from "assets/images/general/divider_big.svg";
import stepAImg from "assets/images/land/step-A.jpg";
import stepBImg from "assets/images/land/step-B.jpg";
import stepCImg from "assets/images/land/step-C.jpg";
import emailImg from "assets/images/general/email.svg";
import facebookImg from "assets/images/general/facebook.svg";
import twitterImg from "assets/images/general/twitter.svg";
import whatsappImg from "assets/images/general/whatsapp.svg";
import dateImg from "assets/images/land/date.svg";
import togetherImg from "assets/images/general/together.png";
import trailerVid from "assets/videos/trailer.mp4";
import closeImg from "assets/images/general/close.svg";
import vImg from "assets/images/general/v.svg";
import dragonImg from "assets/images/land/dragon.png";
import unicornImg from "assets/images/land/unicorn.png";
import boyImg from "assets/images/land/boy.jpg";
import girlImg from "assets/images/land/girl.jpg";
import "./Land.css";

// global inits
const initRem = httpsCallable(getFunctions(), 'initRem');
const initSub = httpsCallable(getFunctions(), 'initSub');

// main
const Land = ()=>{
    const navigate = useNavigate();
    // comps
    const Images = ()=>{
        return(
            <div className='relative flex-row-center z-2' style={{
                width:"700px",
                maxWidth:"90vw"
            }}>
                <img src={boyImg} className='b-r-20' style={{
                    width:"50%",
                    rotate: "-10deg"
                }}/>
                <img src={girlImg} className='b-r-20'  style={{
                    width:"50%",
                    rotate: "10deg"
                }}/>
            </div>
        )
    }
    const Hero = ()=>{
        const Title = ()=>{
            return(
                <div className="relative flex-col-center margin-40" style={{
                    width: "900px",
                    maxWidth: "95vw"
                }}>
                    <h1 className="title-font text-white font-40 text-center">
                        Personalized Animated Birthday eCards for Kids
                    </h1>
                    <div className='margin-10'/>
                    <h2 className="text-white font-25 text-center">
                        See Their Little Faces Fill With Light and Laughter!
                        <div className='margin-10'/>
                        Try Our Fun & Easy Birthday Movie Maker!
                    </h2>
                    {/* <div className="land-title-bg"/> */}
                </div>
            )
        }
        const TitleB = ()=>{
            return(
                <div className="relative flex-col-center" style={{
                    width: "900px",
                    maxWidth: "95vw"
                }}>
                    <h3 className="title-font text-white font-25 text-center">
                        Get Yours Now For Just $4.99!
                    </h3>
                    <h3 className="title-font text-white font-20 text-center">
                        Not Ready to commit? Get a Live Preview for FREE!
                    </h3>
                </div>
            )
        }
        const TitleC = ()=>{
            return(
                <div className="relative flex-col-center link" style={{
                    width: "900px",
                    maxWidth: "95vw"
                }} onClick={()=>{navigate("/free-birthday-party-invitations")}}>
                    <h3 className="title-font text-white font-20 text-center">
                        * Don't Forget To Try Our FREE Personalized Party Invitation Maker!
                    </h3>
                </div>
            )
        }
        const Trailer = ()=>{
            // state
            const [trailerOverlay, setTrailerOverlay] = useState(false);
            // funcs
            const showTrailer = ()=>{
                setTrailerOverlay(true);
                window.setScroll(false);
            }
            const hideTrailer = ()=>{
                setTrailerOverlay(false);
                window.setScroll(true);
            }
            const TrailerOverlay = ()=>{
                return(
                    <div className="overlay flex-col-center z-10">
                        <div className="relative">
                            <video src={trailerVid} poster={trailerPosterImg} className='relative z-1 b-r-20' controls controlsList="nodownload noplaybackrate" onContextMenu={()=>false} playsInline disablePictureInPicture preload="auto" autoPlay style={{
                                width:"95vw",
                                maxWidth:"800px"
                            }}/>
                            <img src={closeImg} className="absolute link z-2" width="50" onClick={hideTrailer} style={{
                                top: "0px",
                                right: "0px",
                                transform: "translate(50%,-50%)"
                            }}/>
                        </div>
                        <div className="bg-dark-blue" onClick={hideTrailer}/>
                    </div>
                )
            }
            return(
                <div className="relative flex-col-center">
                    <img src={trailerPosterImg} className="b-r-20 shadow" style={{
                        width:"90vw",
                        maxWidth:"500px"
                    }}/>
                    <img src={playImg} className="absolute link grow" style={{width:"25%"}} onClick={showTrailer}/>
                    {trailerOverlay?<TrailerOverlay/>:""}
                </div>
            )
        }
        const Dragon = ()=>{
            return(
                <img src={dragonImg} className="desktop" style={{
                    height:"200px",
                    maxWidth:"200px",
                    position:"absolute",
                    right:"0px",
                    bottom:"0px"
                }}/>
            )
        }
        const Unicorn = ()=>{
            return(
                <img src={unicornImg} className="desktop" style={{
                    height:"200px",
                    maxWidth:"200px",
                    position:"absolute",
                    left:"0px",
                    bottom:"0px"
                }}/>
            )
        }
        return(
            <div className="relative flex-col-center blue-bg full-width">
                <div className='margin-20'/>
                <Menu page="Home"/>
                <Title/>
                <Trailer/>
                <div className="margin-20"/>
                {/*<TitleB/>*/}
                {/* <div className="margin-10"/> */}
                <StartButton fontSize="40"/>
                {/* <div className="margin-10"/>
                <TitleC/> */}
                <div className="margin-20"/>
                <Dragon/>
                <Unicorn/>
            </div>
        )
    }
    const Steps = ()=>{
        const Intro = ()=>{
            return(
                <div>
                    Welcome to Toon Kids, your personalized animated birthday ecards for kids generator! We know how special your child's birthday is, and we want to help you make it even more magical. With our easy-to-use platform, you can create a customized ecard that features your kid as the hero of their own movie. They'll love seeing their face, name, and age in the starring role, and you'll love how easy it is to make their birthday special.
                </div>
            )
        }
        const Title = ()=>{
            return(
                <div className="text-dark-blue text-center title-font font-25 flex-col-center" style={{width:"800px",maxWidth:"90vw"}}>
                    Want to Make Your Kid's Special Day Totally Unforgettable?
                    <div className='margin-5'/>
                    We've Got You Covered With Our Personalized Animations in Just 3 Easy Steps!
                </div>
            )
        }
        const Step = (props)=>{
            const stepImages = [
                stepAImg,
                stepBImg,
                stepCImg
            ]
            const charImages = [
                <img src={wizardImg} className="wizard"/>,
                <img src={fairyImg} className="fairy"/>,
                <img src={girafeImg} className="girafe"/>
            ]
            return(
                <div className="relative full-width flex-col-center">
                    <div className="relative z-1 step-container">
                        <div className='flex-col-start step-text'>
                            <div className="text-dark-blue font-25 title-font">
                                {Text.stepsTitles[props.index]}
                            </div>
                            <div className="text-dark-blue font-20">
                                {Text.steps[props.index]}
                            </div>
                        </div>
                        <div className="relative flex-col-center step-image">
                            <img src={stepImages[props.index]} className="b-r-20 shadow full-width" style={{
                                border: "solid 4px #004975"
                            }}/>
                        </div>
                    </div>
                    {/* {charImages[props.index]} */}
                </div>
            )
        }
        return(
            <div className="relative full-width-screen flex-col-center">
                <div className='relative flex-col-center full-width z-1'>
                    <img src={dividerSmallImg} height="30" className="margin-20"/>
                    <Title/>
                    <div className='margin-10'/>
                    <Step index={0} />
                    <img src={dividerImg} height="50" className="margin-20"/>
                    <Step index={1} />
                    <img src={dividerImg} height="50" className="margin-20"/>
                    <Step index={2} />
                    <div className="margin-30"/>
                    <StartButton fontSize="30"/>
                    <div className="margin-20"/>
                    <img src={dividerSmallImg} height="30"/>
                </div>
                <img src={confettiImg} className="absolute full-width" style={{
                    top: "0px",
                    left: "0px",
                    opacity:"0.4"
                }}/>
            </div>
        )
    }
    const Reviews = ()=>{
        const reviews = [
            {
                text: "\"I ordered a personalized birthday ecard for my son's 5th birthday and it was amazing! He was thrilled to see himself as the hero of the story and the animation was top-notch. I highly recommend Toon Kids for anyone looking for a unique and memorable way to celebrate their child's birthday.\"",
                name: "Sarah W. - UK"
            },
            {
                text: "\"I can't say enough good things about Toon Kids. Their birthday ecards are a huge hit with my kids and their friends. They love seeing themselves as the hero of the story and the messages are always positive and uplifting. We've used Toon Kids for the past three birthdays and plan on using them for many more!\"",
                name: "David K. - UK"
            },
            {
                text: "\"My daughter loves Toon Kids! We've used them for her past two birthdays and she still talks about how much she loved seeing herself in her own personalized movie. The animations are so fun and engaging and the messages are perfect for young children. I highly recommend Toon Kids to anyone looking for a special way to celebrate their child's birthday.\"",
                name: "Emily L. - USA"
            }
        ]
        const Review = ({review})=>{
            return(
                <div className='flex-col padding-20 b-r-20 blue-bg margin-20' style={{
                    width: "800px",
                    maxWidth: "95vw"
                }}>
                    <div className='font-25 text-white'>
                        {review.text}
                    </div>
                    <div className='margin-20'/>
                    <div className='title-font font-25 text-white'>
                        {review.name}
                    </div>
                </div>
                
            )
        }
        const Title = ()=>{
            return(
                <div className='flex-col-center padding-20 blue-bg' style={{width:"100vw"}}>
                    <div className='margin-30'/>
                    <Images/>
                    <div className='margin-20'/>
                    <div className='title-font text-white text-center font-30' style={{
                        width: "800px",
                        maxWidth: "95vw"
                    }}>
                        See what other parents are saying about Toon Kids personalized animated birthday ecards:
                    </div>
                </div>
            )
        }
        return(
            <div className='flex-col-center'>
                <Title/>
                {reviews.map((review, index)=><Review review={review} key={index}/>)}
            </div>
        )
    }
    const Share = ()=>{
        const Icon = (props)=>{
            return(
                <img src={props.src} width="50" className="margin-10"/>
            )
        }
        return(
            <div className="full-width flex-col-center title-font padding-20" style={{
                backgroundColor: "rgba(0,73,117,0.1)"
            }}>
                <div className="font-40 title-font text-dark-blue text-center">
                    Easily share with friends and family!
                </div>
                <div className="font-25 title-font text-dark-blue text-center">
                    Send it as a birthday party invitation or greeting!<br/>
                    Share on Facebook, Whatsapp and Twitter!
                </div>
                <div className="flex-row-center">
                    <Icon src={emailImg}/>
                    <Icon src={facebookImg}/>
                    <Icon src={whatsappImg}/>
                    <Icon src={twitterImg}/>
                </div>
            </div>
        )
    }
    const Reminder = ()=>{
        //states
        const [suc, setSuc] = useState(false);
        const [remDateGlobal, setRemDateGlobal] = useState();

        //comps
        const Form = ()=>{
            // refs
            const dateInput = useRef();
            const emailInput = useRef();
            const datePicker = useRef();
            const promRef = useRef();
            const subBut = useRef();

            //states
            const [dateState, setDate] = useState('');
            const [sendProm, setSendProm] = useState(true);
            const [errorMsg, setErrorMsg] = useState();
            const [wait, setWait] = useState(false);

            // funcs
            const doReminder = async()=>{
                const makeDate = (dateVal)=>{
                    const date = dateVal.split('-');
                    date[0] = Number(date[0]);
                    date[1] = Number(date[1])-1;
                    date[2] = Number(date[2]);
                    const months = [
                        '',
                        'Jan',
                        'Feb',
                        'March',
                        'April',
                        'May',
                        'June',
                        'July',
                        'Aug',
                        'Sept',
                        'Oct',
                        'Nov',
                        'Dec'
                    ]
                    //birth date
                    const birthdayDate = new Date();
                    birthdayDate.setFullYear( date[0] );
                    birthdayDate.setMonth( date[1] );
                    birthdayDate.setDate( date[2] );
                    var year = birthdayDate.getFullYear();
                    var month = birthdayDate.getMonth();
                    var day = birthdayDate.getDate();
                    var dayStr = day;
                    if(day==1) dayStr += 'st';
                    else if(day==2) dayStr += 'nd';
                    else if(day==3) dayStr += 'rd';
                    else dayStr += 'th';
                    const birthDateText = dayStr+' of '+months[month-1]+' '+year;
                    //reminder date
                    const offsetDays = 7;
                    const remDate = new Date();
                    remDate.setFullYear( date[0] );
                    remDate.setMonth( date[1] );
                    remDate.setDate( date[2]-offsetDays );
                    year = remDate.getFullYear();
                    month = remDate.getMonth()+1;
                    day = remDate.getDate();
                    dayStr = day;
                    if(day==1) dayStr += 'st';
                    else if(day==2) dayStr += 'nd';
                    else if(day==3) dayStr += 'rd';
                    else dayStr += 'th';
                    const remDateText = dayStr+' of '+months[month]+' '+year;
                    return {
                        remDate: [day,month,year],
                        birthDateText: birthDateText,
                        remDateText: remDateText
                    }
                }
                try{
                    const dateVal = dateInput.current.value;
                    const email = emailInput.current.value;
                    if(dateVal){
                        if( window.isEmail(email) ){
                            setErrorMsg();
                            setWait(true);
                            // make date
                            const { remDate, birthDateText, remDateText } = makeDate(dateVal) ;
                            //send to server
                            await initRem({
                                email: email,
                                sendProm: sendProm,
                                remDate: remDate,
                                birthDateText: birthDateText
                            });
                            setSuc(true);
                            setRemDateGlobal(remDateText);
                        }
                        else throw Error('Please check your email.');
                    }
                    else throw Error('Please choose a date.');
                }
                catch(err){
                    setWait(false);
                    setErrorMsg(err.message);
                }
            }

            // comps
            const DateHolder = ()=>{
                useEffect(()=>{
                    datePicker.current.min = new Date().toLocaleDateString('en-ca')
                },[])
                return(
                    <div className="relative flex-col-center margin-10">
                        <div className="relative z-1 flex-row-center" onClick={()=>{if(!wait)datePicker.current.showPicker()}}>
                            <input
                                type="text"
                                ref={dateInput}
                                value={dateState}
                                placeholder="Date of birth"
                                className={"z-1 font-20 "+(wait?"defaultCursor grey-bg":"link")}
                                readOnly
                                disabled={wait}
                            />
                            <img src={dateImg} className="absolute z-1 link" height="15px" style={{right:"10px"}}/>
                        </div>
                        <input
                            type="date"
                            ref={datePicker}
                            className="absolute"
                            style={{
                                bottom:"0px",
                                left:"0px",
                                visibility: "hidden"
                            }}
                            onChange={(e)=>{
                                setErrorMsg();
                                setDate(e.target.value);
                            }}
                            disabled={wait}
                        />
                    </div>
                )
            }
            //render
            return(
                <div className="flex-col-center">
                    <div className="font-40 title-font text-dark-blue text-center">
                        Not their birthday yet?
                    </div>
                    <div className="font-25 title-font text-dark-blue text-center">
                        You can still make it NOW and send it later!<br/>
                        We will remind you when the day comes!
                    </div>
                    <div className="font-20 text-dark-blue text-center">
                        (The reminder will be sent a week before)
                    </div>
                    <div className="flex-row-center flex-wrap">
                        <DateHolder/>
                        <input ref={emailInput} onInput={()=>{setErrorMsg()}} className="font-20" type="email" autoComplete="on" disabled={wait} placeholder="Email" />
                    </div>
                    <div className="flex-row-center flex-wrap link margin-10" onClick={()=>{setSendProm(!sendProm)}}>
                        <input className="link" ref={promRef} type="checkbox" checked={sendProm} onChange={()=>{setSendProm(!sendProm)}}/>
                        <div className="font-15 text-dark-blue text-center link">
                             I also want to receive updates and promotions
                        </div>
                    </div>
                    {errorMsg?<ErrMsg msg={errorMsg}/>:""}
                    <SendBut txt="Remind Me" action={doReminder} wait={wait}/>
                </div>
            )
        }
        const Suc = ()=>{
            return(
                <div className="flex-col-center">
                    <div className="font-30 title-font text-dark-blue text-center">
                        You will be reminded on the {remDateGlobal}.
                    </div>
                    <img src={vImg} width="100"/>
                </div>
            )
        }
        // render
        return(
            <div className="full-width flex-col-center margin-20">
                <img src={dividerBigImg} height="40" className="margin-20" style={{maxWidth:"90vw"}}/>
                <div className="margin-10"/>
                {suc?<Suc/>:<Form/>}
                <img src={dividerBigImg} height="40" className="margin-10" style={{maxWidth:"90vw"}}/>
            </div>
        )
    }
    const Faq = ()=>{
        const Question = (props)=>{
            return(
                <div className='flex-col-start full-width'>
                    <div className="font-30 title-font text-white">
                        {Text.faqTitles[props.index]}
                    </div>
                    <div className="font-25 text-white">
                        {Text.faq[props.index]}
                    </div>
                </div>
            )
        }
        return(
            <div className='flex-col-center b-r-20 faq'>
                <div className="font-40 title-font text-white text-center">
                    F.A.Q.
                </div>
                <Question index={0}/>
                <div className="margin-20"/>
                <Question index={1}/>
                <div className="margin-20"/>
                <Question index={2}/>
                <div className="margin-20"/>
                <Question index={3}/>
                <div className="margin-20"/>
                <Question index={4}/>
                <div className="margin-20"/>
                <Question index={5}/>
            </div>
        )
    }
    const Between = ()=>{
        return(
            <div className="flex-col-center">
                <div className="margin-20"/>
                <div className="margin-20">
                    <StartButton fontSize="30"/>
                </div>
                <img src={togetherImg} className="margin-20" style={{
                    width:"500px",
                    maxWidth:"90vw"
                }}/>
            </div>
        )
    }
    const Intouch = ()=>{
        const [suc, setSuc] = useState(false);
        const Form = ()=>{
            const [errorMsg, setErrorMsg] = useState();
            const [wait, setWait] = useState(false);
            const emailInput = useRef();
            const doSubscribe = async(email)=>{
                try{
                    const email = emailInput.current.value;
                    if( window.isEmail(email) ){
                        setErrorMsg();
                        setWait(true);
                        await initSub({email: email});
                        setSuc(true);
                    }
                    else throw Error('Please check your email.');
                }
                catch(err){
                    setWait(false);
                    setErrorMsg(err.message);
                }
            }
            return(
                <div className="flex-col-center">
                    <div className="font-40 title-font text-white text-center">
                        Stay intouch!
                    </div>
                    <div className="font-25 title-font text-white text-center">
                        Enter your email below to receive updates and promotions
                    </div>
                    <input ref={emailInput} className="font-20 margin-10" type="email" autoComplete="on" placeholder="Email" disabled={wait} onInput={()=>{setErrorMsg()}}/>
                    <SendBut txt="Subscribe" action={doSubscribe} wait={wait}/>
                    <ErrMsg msg={errorMsg}/>
                </div>
            )
        }
        const Suc = ()=>{
            return(
                <div className="flex-col-center">
                    <div className="font-30 title-font text-white text-center">
                        Thank you for subscribing!
                    </div>
                    <img src={vImg} className="white-bg b-r-100" width="50"/>
                </div>
            )
        }
        return(
            <div className="flex-col-center">
                <img src={dividerBigImg} height="40" className="margin-20" style={{maxWidth:"90vw"}}/>
                <div className="margin-10"/>
                {suc?<Suc/>:<Form/>}
                <img src={dividerBigImg} height="40" className="" style={{maxWidth:"90vw"}}/>
            </div>
        )
    }

    // render
    return (
        <div className="relative flex-col-center full-width-screen">
            <Hero/>          
            <Steps/>
            <div className='margin-20'/>
            <Share/>
            {/* <Reminder/> */}
            <Reviews/>
            <Between/>
            <Intouch/>
            <div className="margin-20"/>
            <Footer/>
        </div>
    )
}

//export
export default Land;

// end
