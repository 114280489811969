// Movie.js
import { useState, useEffect, useRef } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getDatabase, ref, onValue } from "firebase/database";
import Globals from "assets/modules/Globals";
import Close from "comps/Close/Close";
import ErrMsg from "comps/ErrMsg/ErrMsg";
import SendBut from "comps/SendBut/SendBut";
import loaderGif from "assets/images/general/loader.gif";
import Loader from "comps/Loader/Loader";
import downloadImg from "assets/images/movie/download.svg";
import emailImg from "assets/images/movie/email.svg";
import facebookImg from "assets/images/movie/facebook.svg";
import shareImg from "assets/images/movie/share.svg";
import twitterImg from "assets/images/movie/twitter.svg";
import whatsappImg from "assets/images/movie/whatsapp.svg";
import vImg from "assets/images/general/v.svg";
import playImg from "assets/images/general/play.svg";
import "./Movie.css";

const movExists = httpsCallable(getFunctions(), 'movExists');
const getMovDownloadLink = httpsCallable(getFunctions(), 'getMovDownloadLink');
const sendShareEmail = httpsCallable(getFunctions(), 'sendShareEmail');

var renderTime = 4*60;
var startTime;
const Movie = (props)=>{
    // inits
    const [wait, setWait] = useState(true);
    const uid = props.uid;
    const mid = props.mid;

    // comps
    const Buttons = ()=>{
        const [showTooltip, setShowTooltip] = useState(false);
        const handleOver = ()=>{
            setShowTooltip(true);
        }
        const handleOut = ()=>{
            setShowTooltip(false);
        }
        const Tooltip = ()=>{
            return(
                <div className='absolute text-center padding-10 b-r-20 z-10' style={{
                    width: "300px",
                    top: "20px",
                    backgroundColor: "white",
                    color: "red"
                }}>
                    These will be enabled when the movie is ready.
                </div>
            )
        }
        const Download = ()=>{
            const [localWait, setLocalWait] = useState(false);
            const doDownload = async()=>{
                setLocalWait(true);
                const res = await getMovDownloadLink({uid: uid, mid:mid});
                const url = res.data.url;
                window.open(url,'_self');
                setLocalWait(false);
            }
            const LocalWait = ()=>{
                return(
                    <img src={loaderGif} className="absolute" height="10"/>
                )
            }
            return(
                <div className="relative flex-col-center">
                    <button disabled={wait || localWait} className="yellow-button flex-row-center" onClick={doDownload}>
                        <div className='text-white'>Download</div>
                        <div className='margin-5'/>
                        <img src={downloadImg} height="20"/>
                    </button>
                    {localWait?<LocalWait/>:""}
                </div>
            )
        }
        const Share = ()=>{
            const [overlay, setOverlay] = useState(false);
            const ShareOverlay = ()=>{
                const [emailOver, setEmailOver] = useState(false);
                const doShare = (type)=>{
                    const args = [
                        'uid='+uid,
                        'mid='+mid,
                        'type='+type
                    ]
                    const url = Globals.domain+'/share?'+args.join('&');
                    var link;
                    switch(type){
                        case 'f':
                            link = 'https://www.facebook.com/sharer/sharer.php?u=';
                            break;
                        case 'w':
                            link = 'https://wa.me/?text=';
                            break;
                        case 't':
                            link = 'https://twitter.com/intent/tweet?text=';
                            break;

                    }
                    window.open(link+encodeURIComponent(url),'_blank');
                }

                const Main = ()=>{
                    const Button = (props)=>{
                        return(
                            <button className="yellow-button flex-row-center margin-10" onClick={props.action}>
                                <div>{props.label}</div>
                                <div className='margin-5'/>
                                <img src={props.img} height="20"/>
                            </button>
                        )
                    }
                    return(
                        <div className="relative flex-col-center z-1 bg-creme b-r-20 padding-20">
                            <Button label="Email" img={emailImg} action={()=>{setEmailOver(true)}}/>
                            <Button label="Facebook" img={facebookImg} action={()=>{ doShare("f") }}/>
                            <Button label="Whatsapp" img={whatsappImg} action={()=>{ doShare("w") }}/>
                            <Button label="Twitter" img={twitterImg} action={()=>{ doShare("t") }}/>
                            <Close action={ ()=>{setOverlay(false)} }/>
                        </div>
                    )
                }
                const EmailOver = ()=>{
                    const [suc, setSuc] = useState(false);
                    const Form = ()=>{
                        //states
                        const [errMsg, setErrMsg] = useState();
                        const [wait, setWait] = useState(false);

                        //refs
                        const nameAinput = useRef();
                        const nameBinput = useRef();
                        const emailInput = useRef();
                        const msgInput = useRef();

                        //funcs
                        const doSend = async(email)=>{
                            try{
                                //get inputs
                                const nameA = nameAinput.current.value;
                                const nameB = nameBinput.current.value;
                                const email = emailInput.current.value;
                                const msg = msgInput.current.value;
                                //check inputs
                                if(!nameA) throw Error('Please enter your name.');
                                if(!nameB) throw Error('Please enter recepient name.');
                                if(!window.isEmail(email)) throw Error('Please check recepient email.');
                                if(!msg) throw Error('Please enter a message.');
                                //make action
                                setErrMsg();
                                setWait(true);
                                nameAinput.current.disabled = true;
                                nameBinput.current.disabled = true;
                                emailInput.current.disabled = true;
                                msgInput.current.disabled = true;
                                await sendShareEmail({
                                    uid: uid,
                                    mid: mid,
                                    fromName: nameA,
                                    toName: nameB,
                                    email: email,
                                    msg: msg
                                });
                                setSuc(true);
                            }
                            catch(err){
                                setWait(false);
                                setErrMsg(err.message);
                                nameAinput.current.disabled = false;
                                nameBinput.current.disabled = false;
                                emailInput.current.disabled = false;
                                msgInput.current.disabled = false;
                            }
                        }
                        const inputStyle = {
                            border:"2px",
                            borderStyle:"solid",
                            borderColor:"#004975"
                        }
                        return(
                            <div className="relative flex-col-center full-width">
                                <div className="flex-row-center full-width">
                                    <input ref={nameAinput} className="full-width font-20"  type="text" placeholder="Your Name" style={inputStyle}/>
                                    <div className="margin-5"/>
                                    <input ref={nameBinput} className="full-width font-20"  type="text" placeholder="Recepient Name" style={inputStyle}/>
                                </div>
                                <input ref={emailInput} className="full-width font-20 margin-20" type="email" placeholder="Recepient Email" autoComplete="on" style={inputStyle}/>
                                <textarea ref={msgInput} className="relative z-1 full-width font-20" cols="50" rows="10" placeholder="Message" style={inputStyle}/>
                                <div className="margin-10"/>
                                <ErrMsg msg={errMsg}/>
                                <SendBut txt="Send" action={doSend} wait={wait}/>
                            </div>
                        )
                    }
                    const Suc = ()=>{
                        return(
                            <div className="flex-col-center">
                                <div className="font-20 text-dark-blue text-center margin-20">
                                    You're message has been sent!
                                </div>
                                <img src={vImg} width="100"/>
                            </div>
                        )
                    }
                    return(
                        <div className="relative flex-col-center z-1 bg-creme b-r-20 padding-20" style={{
                            width:"400px",
                            maxWidth:"90vw"
                        }}>
                            {suc?<Suc/>:<Form/>}
                            <Close action={ ()=>{setEmailOver(false)} }/>
                        </div>
                    )
                }
                return(
                    <div className="overlay flex-col-center">
                        {emailOver?<EmailOver/>:<Main/>}
                        <div className="bg-dark-blue" onClick={()=>{setOverlay(false)}}/>
                    </div>
                )
            }
            useEffect(()=>{
                window.setScroll(!overlay);
            },[overlay]);
            return(
                <div className="relative flex-col-center">
                    <button disabled={wait} className="yellow-button flex-row-center" onClick={()=>{setOverlay(true)}}>
                        <div className='text-white'>Share</div>
                        <div className='margin-5'/>
                        <img src={shareImg} height="20"/>
                    </button>
                    {overlay?<ShareOverlay/>:""}
                </div>
            )
        }
        return(
            <div className="relative flex-row-center margin-20" onMouseOver={handleOver} onMouseOut={handleOut}>
                {(wait && showTooltip)?<Tooltip/>:""}
                <Download/>
                <div className="margin-10"/>
                <Share/>
            </div>
        )
    }
    const LoadImg = ()=>{
        return(
            <img src={loaderGif} className="margin-10" style={{
                width:"30%"
            }}/>
        )
    }
    const Video = ()=>{
        // inits
        const [checkExist, setCheckExist] = useState(false);
        const [vidSrc, setVidSrc] = useState();
        const [posterSrc, setPosterSrc] = useState()
        const [play, setPlay] = useState(false);
        const vidRef = useRef();
        const path = Globals.storageDomain+'/users/'+uid+'/movies/'+mid;
        const vSrc = path+'/final.mp4';
        const pSrc = path+'/thumb.jpg';

        // funcs
        const doFinish = ()=>{
            setVidSrc(vSrc);
            setPosterSrc(pSrc);
            setCheckExist(true);
            setWait(false);
        }
        const init = async()=>{
            const existsRes = await movExists({
                uid: uid,
                mid: mid
            })
            if(existsRes.data.exists) doFinish();
            else{
                // started listener
                const startedRef = ref(getDatabase(), 'movies/'+uid+'/'+mid+'/started');
                const unsubStarted = onValue(startedRef, async(snapshot) => {
                    const startedFlag = snapshot.val();
                    if(startedFlag){
                        const res = await fetch(Globals.storageDomain+'/users/'+uid+'/movies/'+mid+'/start.time');
                        const txt = await res.text();                               
                        startTime = Number(txt)*1000;
                        console.log("start time: "+startTime);
                        renderTime = 3*60;
                        unsubStarted();
                    }
                    else if(!startTime) startTime = Date.now();
                    setCheckExist(true);
                });
                // finished listener
                const finishedRef = ref(getDatabase(), 'movies/'+uid+'/'+mid+'/finished');
                const unsubFinished = onValue(finishedRef, (snapshot) => {
                    const finished = snapshot.val();
                    if(finished){
                        doFinish();
                        unsubFinished();
                    }
                });
            }
        }
        useEffect(()=>{
            init();
        },[])

        // comps
        const Wait = ()=>{
            const [timeEnd, setTimeEnd] = useState(false);
            const TimeMsg = ()=>{
                const [remTime, setRemTime] = useState("04:00");
                const count = ()=>{
                    // javascript Date.now() return miliseconds since the epoch
                    // python time.time() returns seconds since the epoch
                    // devide Date.now() by 1000 to match python (startTime is uploaded from main.py)
                    // epoch = January 1, 1970, 00:00:00 (UTC)
    
                    //check remaining time
                    const elapSec = Math.floor( (Date.now() - Number(startTime))/1000 );
                    const remSec = renderTime-elapSec;
                    if(remSec>0){
                        //seperate to minutes and seconds
                        var minutes = Math.floor(remSec/60);
                        var seconds = remSec%60;
                        //set time string
                        if(minutes<10) minutes = '0'+minutes;
                        if(seconds<10) seconds = '0'+seconds;
                        setRemTime(minutes+':'+seconds);
                        setTimeout(count,1000);
                    }
                    else setTimeEnd(true);
                }
                useEffect(count,[]);
                return(
                    <div className='font-60 text-white text-center'>
                        {remTime}
                    </div>
                )
            }
            return(
                <div className="absolute flex-col-center full-width" style={{
                    zIndex: 5
                }}>
                    <div className="absolute flex-col-center">
                        <Loader width={"60%"}/>
                        {!checkExist || timeEnd?<LoadImg/>:<TimeMsg/>}
                    </div>
                    <canvas width="1920" height="1080" className="full-width bg-dark-blue-2 b-r-20"/>
                </div>
            )
        }
        const Poster = ()=>{
            const doPlay = ()=>{
                setPlay(true);
                vidRef.current.play();
            }
            return(
                <div className="absolute flex-col-center full-width" style={{
                    zIndex: 3
                }}>
                    <img src={playImg} className="absolute link grow z-2" style={{width:"25%"}} onClick={doPlay}/>
                    <img src={posterSrc} className="absolute full-width b-r-20"/>
                    <canvas width="1920" height="1080" className="full-width bg-dark-blue-2 b-r-20"/>
                </div>
            )
        }
        
        // render
        return(
            <div className="relative flex-col-center full-width">
                {wait?<Wait/>:""}
                {play?"":<Poster/>}
                <video
                    className="absolute full-width b-r-20 bg-dark-blue-2 z-1"
                    ref={vidRef}
                    src={vidSrc}
                    poster={posterSrc}
                    controls controlsList="nodownload noplaybackrate"
                    playsInline disablePictureInPicture preload="auto"
                    onContextMenu={e => e.preventDefault()}
                />
                <canvas width="1920" height="1080" className="full-width bg-dark-blue-2 b-r-20"/>
            </div>
        )
    }
    
    // render
    return (
        <div className="relative flex-col-center full-width">
            <Video/>
            <Buttons/>
        </div>
    )
}

export default Movie;

// end
