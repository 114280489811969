// StageB3.js
import { useState, useEffect, useRef } from 'react';
import { useGlobalState } from "../../GlobalState";
import { useNavigate } from "react-router-dom";
import qImg from "assets/images/editor/stageB/q.jpg";
import vImg from "assets/images/general/v.svg";
import loaderGif from "assets/images/general/loader.gif";
import "./StageB3.css";

const StageB3 = ()=>{
    //inits
    const [globalState, updateGlobalState] = useGlobalState();
    const navigate = useNavigate();
    const [wait, setWait] = useState(true);
    const [chosenFace, setChosenFace] = useState();

    //functions
    const findFaces = async()=>{
        const makeFace = (face)=>{
            const box = face.alignedRect.box;
            const canv = window.canvas;
            canv.width = box.width;
            canv.height = box.height;
            const ctx = canv.getContext('2d');
            ctx.drawImage(window.faceCanvas,box.x,box.y,box.width,box.height,0,0,box.width,box.height);
            return canv.toDataURL();
        }
        await window.sleep(0.5);
        while(!window.faceApiLoaded) await window.sleep(1); // loaded in Editor.js
        window.faces = await window.faceapi.detectAllFaces(window.faceCanvas,new window.faceapi.TinyFaceDetectorOptions()).withFaceLandmarks();
        window.faceThumbs = window.faces.map( (face, index)=>makeFace(face) );
        if(window.faces.length==1){
            window.selectedFace = 0;
            navigate('/editor/b/4');
        }
        else{
            updateGlobalState("back",true);
            setWait(false);
        }
    }
    const chooseFace = (faceIndex)=>{
        window.selectedFace = faceIndex;
        setChosenFace(faceIndex);
        updateGlobalState("next",true);
    }

    //effects
    useEffect(()=>{
        window.scrollTo(0, 0);
        updateGlobalState("back",false);
        updateGlobalState("next",false);
        window.backFunc = ()=>{
            navigate('/editor/b/2');
        }
        window.nextFunc = ()=>{
            navigate('/editor/b/4');
        }
        if(!window.faces) findFaces();
        else{
            if(window.selectedFace || window.selectedFace==0){
                setChosenFace(window.selectedFace);
                updateGlobalState("back",true);
                updateGlobalState("next",true);
            }
            setWait(false);
        }
    },[])

    //comps
    const Wait = ()=>{
        return(
            <div className="relative flex-col-center">
                <div className="font-30 title-font text-dark-blue text-center">
                    Finding faces...
                </div>
                <div className="relative flex-col-center margin-20" style={{width:"300px",maxWidth:"90vw"}}> 
                    <img src={window.faceImgData} className="full-width b-r-20" style={{opacity:"0.7"}}/>
                    <img src={loaderGif} className="absolute" style={{width:"50%"}}/>
                </div>   
            </div>       
        )
    }
    const Title = ()=>{
        return(
            <div className="flex-col-center">
                <div className="font-30 title-font text-dark-blue text-center">
                    Beautiful! Choose the face you want to use!
                </div>
                <div className="font-25 text-dark-blue text-center">
                    Can't see the face you want? click the '?' button.
                </div>
                <div className="margin-10"/>
                <div className="font-20 text-dark-blue text-center">
                    When you are ready click the 'Next' button.
                </div>
            </div>
        )
    }
    const Faces = ()=>{
        const Face = (props)=>{
            const choose = ()=>{
                chooseFace(props.index);
            }
            const Chosen = ()=>{
                return(
                    <div className="local-overlay green-border b-r-20">
                        <img src={vImg} className="absolute" style={{height:"30%",top:"2px",right:"2px"}} />
                    </div>
                )
            }
            return(
                <div className="relative flex-col-center margin-10">
                    <img src={props.src} className="b-r-20 link grow" style={{height:"100px"}} onClick={choose}/>
                    {chosenFace==props.index?<Chosen/>:""}
                </div>
            )
        }
        return(
            <div className="flex-row-center flex-wrap padding-20 bg-red margin-20 b-r-20" style={{
                width:"500px",
                maxWidth:"90vw"
            }}>
                <Face src={qImg} index={'none'}/>
                {window.faceThumbs.map((src, index)=><Face src={src} index={index} key={index}/>)}
            </div>
        )
    }
    const Cont = ()=>{
        return(
            <div className="flex-col-center">
                <Title/>
                <Faces/>
            </div>
        )
    }

    //render
    return (
        wait?<Wait/>:<Cont/>
    )
}

export default StageB3;

// end
