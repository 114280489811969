// StageE2.js
import Globals from "assets/modules/Globals";
import { useState, useEffect, useRef } from 'react';
import { useGlobalState } from "../../GlobalState";
import { useNavigate } from "react-router-dom";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getDatabase, ref, onValue } from "firebase/database";
import loaderGif from "assets/images/general/loader.gif";
import playImg from "assets/images/general/play.svg";
import "./StageE2.css";

const initPrev = httpsCallable(getFunctions(), 'initPrev');
const getPrevDownloadLink = httpsCallable(getFunctions(), 'getPrevDownloadLink');
const database = getDatabase();
var uid, mid;

const StageE2 = ()=>{
    // inits
    const [globalState, updateGlobalState] = useGlobalState();
    const navigate = useNavigate();
    const [wait, setWait] = useState(true);
    const [msg, setMsg] = useState();
    const progBarRef = useRef();

    // effect
    useEffect(()=>{
        window.scrollTo(0, 0);
        updateGlobalState("back", false);
        updateGlobalState("next", false);
        window.backFunc = ()=>{
            navigate('/editor/f');
        }
        window.nextFunc = ()=>{
            window.makePreview = false;
            navigate('/editor/checkout?uid='+window.uid+"&mid="+window.mid+"&email="+window.email);
            // navigate('/editor/f');
        }
    },[])

    // comps
    const TitleA = ()=>{
        return(
            <div className="flex-col-center" style={{
                width:"900px",
                maxWidth:"90vw"
            }}>
                <div className="font-30 title-font text-dark-blue text-center">
                    We're on it!
                </div>
                <div className="font-20 text-dark-blue text-center">
                    Usually it takes around one minute to complete.
                </div>
            </div>
        )
    }
    const TitleB = ()=>{
        return(
            <div className="flex-col-center" style={{
                width:"900px",
                maxWidth:"90vw"
            }}>
                <div className="font-30 title-font text-dark-blue text-center">
                    Your short preview is ready!
                </div>
                <div className="font-20 text-dark-blue text-center">
                    To get the <u>Full 02:15 Minutes Shareable Downloadable Movie</u> click the "Get FULL movie!" button.<br/>
                </div>
            </div>
        )
    }
    const DownButton = ()=>{
        const [localWait, setLocalWait] = useState(false);
        const doDownload = async()=>{
            setLocalWait(true);
            try{
                const urlRes = await getPrevDownloadLink({uid:uid});
                const url = urlRes.data.url;
                window.open(url,'_self');
            }
            catch(e){
                console.log(e.message);
            }
            setLocalWait(false);
        }
        return(
            <div className="relative flex-col-center">
                <button className="yellow-button font-20 margin-20" disabled={wait || localWait} onClick={doDownload}>
                    Download
                </button>
                {localWait?<img src={loaderGif} width="50" className="absolute"/>:""}
            </div>
        )
    }
    const FullButton = ()=>{
        const handleClick = ()=>{
            window.makePreview = false;
            navigate('/editor/checkout?uid='+window.uid+"&mid="+window.mid+"&email="+window.email);
        }
        return(
            <button className="red-button font-20 margin-5" disabled={wait} onClick={handleClick}>
                Get Full 02:15 Movie! ($4.99)
            </button>
        )
    }
    const Stage = ()=>{
        const init = async()=>{
            const incProg = ()=>{
                prog += 0.5;
                makeProg();
            }
            const setProg = (progVal)=>{
                if(progVal>prog) prog = progVal;
                makeProg();
            }
            const setStatus = (status)=>{
                console.log(status);
            }
            const makeProg = ()=>{
                const makeMsg = ()=>{
                    setMsg( msgs[msgIndex] );
                    if(msgIndex<msgs.length-1) msgIndex++;
                }

                const convProg = (prog*280)/100;//full prog from makePrev is 100
                if(!msgIntrvl){
                    if(convProg>fullProg){
                        //stop prog inc
                        clearInterval(incProgIntrvl);
                        //set msgs and start
                        makeMsg();
                        msgIntrvl = setInterval(makeMsg,5000);
                    }
                    else{
                        progBarRef.current.style.width = convProg+'px';
                    }
                }
            }
            const data2img = (data)=>{
                return new Promise((resolve,reject)=>{
                    const img = document.createElement('img');
                    img.onload = ()=>{
                        resolve(img);
                    }
                    img.src = data;
                })
            }
            const mountMedia = (urls)=>{
                //video
                window.prevUrl = Globals.storageDomain+'/'+urls.vid;
                // window.thumbUrl = Globals.storageDomain+'/'+urls.thumb;
                updateGlobalState("back", true);
                updateGlobalState("next", true);
                setWait(false);
                clearInterval(msgIntrvl);
                //gif
                // getElm('prev-gif-5').onload = ()=>{
                //     clearInterval(msgIntrvl);
                //     hide('play-button-5');
                //     hide('prev-vid-poster-5');
                //     hide('stage_5-1-load');
                //     show('stage_5-1-cont');
                //     show('prev-gif-5');
                // }
                // getElm('prev-gif-5').src = urls.gif;
            }
            //set flag
            window.prevStarted = true;
            //start progress interface
            const fullProg = 280;
            var msgIntrvl = false;
            var prog = 10;
            makeProg();
            const incProgIntrvl = setInterval(incProg,500);
            var msgIndex = 0;
            const msgs = [
                'Just a moment',
                'Still working :)',
                'Just a few more seconds...',
                'Ok here it comes!',
                'ฅ^•ﻌ•^ฅ'
            ]
            // call makePreview
            uid = window.uid;
            mid = window.mid;
            await initPrev({mid: mid});
            //register database listeners
            //prog
            const progRef = ref(database, 'prevs/'+uid+"/"+mid+'/prog');
            const unsubProg = onValue(progRef, (snapshot) => {
                const progVal = snapshot.val();
                console.log(progVal);
                setProg(progVal);
            });
            //urls
            const urlsRef = ref(database, 'prevs/'+uid+"/"+mid+'/urls');
            const unsubUrls = onValue(urlsRef, (snapshot) => {
                const urls = snapshot.val();
                if(urls){
                    //stop listeners
                    unsubProg();
                    unsubUrls();
                    // statusRef.off();
                    //mountMedia
                    mountMedia(urls);
                }
            });
            //status
            // const statusRef = backend.database.ref(uid).child('status');// created in backend function initPrev and updated in makePrev
            // statusRef.on('value', (snapshot) => {
            //   const status = snapshot.val();
            //   setStatus(status);
            // });
        }
        useEffect(()=>{
            if(!window.prevStarted) init();
            else if(window.prevUrl){
                updateGlobalState("back", true);
                updateGlobalState("next", true);
                setWait(false);
            }
        },[])

        const Wait = ()=>{
            const ProgBar = ()=>{
                return(
                    <div className="full-width b-r-20 grey-bg" style={{width:"300px",height:"20px"}}>
                        <div ref={progBarRef} className="blue-bg" style={{
                            width:"10px",
                            height:"20px",
                            borderRadius:"20px 0px 0px 20px"
                        }} />
                    </div>
                )
            }
            const WaitMsg = ()=>{
                return(
                    <div className="font-15 text-white text-center">
                        {msg}
                    </div>
                )
            }
            return(
                <div className="relative flex-col-center full-width">
                    <div className="absolute flex-col-center padding-40 b-r-20 margin-20">
                        <div className="font-30 title-font text-white text-center">
                            Please wait...
                        </div>
                        <div className="margin-10"/>
                        {msg?<WaitMsg/>:<ProgBar/>}
                        <div className="margin-10"/>
                        <img src={loaderGif} width="100"/>
                    </div>
                    <canvas width="1920" height="1080" className="full-width bg-dark-blue-2 b-r-20"/>
                </div>
            )
        }
        const Video = ()=>{
            const vidRef = useRef();
            const [showPlay, setShowPlay] = useState(true);
            const doPlay = ()=>{
                setShowPlay(false);
                vidRef.current.play();
            }
            const PlayBut = ()=>{
                return(
                    <div className="absolute flex-col-center full-width z-10">
                        <img src={playImg} className="absolute link grow z-10" style={{width:"25%"}} onClick={doPlay}/>
                        <img src={window.prevImgSrc} className='full-width b-r-20'/>
                    </div>
                )
            }
            return(
                <div className="relative flex-col-center full-width">
                    {showPlay?<PlayBut/>:""}
                    <video ref={vidRef} src={window.prevUrl} className='full-width bg-dark-blue-2 b-r-20' playsInline muted loop preload='auto' style={{height:"auto"}}/>
                </div>
            )
        }
        return(
            <div className="flex-col-center margin-20">
                <div className="relative flex-col-center" style={{
                    width:"500px",
                    maxWidth:"90vw"
                }}>
                    {wait?<Wait/>:<Video/>}
                </div>
            </div>
        )
    }

    // render
    return (
        <div className="flex-col-center">
            {wait?<TitleA/>:<TitleB/>}
            <Stage/>
            {/* <DownButton/> */}
            {wait?"":<FullButton/>}
        </div>
    )
}
export default StageE2;

// end
