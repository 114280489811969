// About.js
import Menu from "comps/Menu/Menu";
import Footer from "comps/Footer/Footer";
import StartButton from "comps/StartButton/StartButton";
import confettiImg from "assets/images/general/confeti.svg";
import dividerImg from "assets/images/general/divider.svg";
import dividerSmallImg from "assets/images/general/divider_small.svg";
import profileImg from "assets/images/about/profile.jpg";
import "./About.css";

const About = ()=>{
    const Header = ()=>{
        return(
            <div className="flex-col-center hero-container full-width padding-20">
                <Menu page="About"/>
            </div>
        )
    }
    const Confetti = ()=>{
        return(
            <img src={confettiImg} className="absolute full-width" style={{
                top: "0px",
                left: "0px",
                opacity:"0.4"
            }}/>
        )
    }
    const Title = ()=>{
        return(
            <div className="font-40 title-font text-center margin-20" style={{color:"#1a76d2"}}>
                ABOUT US
            </div>
        )
    }
    const TitleA = ()=>{
        return(
            <div className="font-30 title-font text-center margin-20" style={{color:"#004975"}}>
                Welcome, Amazing Parents!
            </div>
        )
    }
    const TitleB = ()=>{
        return(
            <div className="font-30 title-font text-center margin-20" style={{color:"#004975"}}>
                Let's make this birthday one for the books!
            </div>
        )
    }
    const Text = ()=>{
        return(
            <div className="font-25 margin-20" style={{
                color:"#004975",
                maxWidth:"90vw",
                width:"800px",
                lineHeight:"1.3em"
            }}>
                <TitleA/>
                <p>We know the quest: each year, you're searching for that unique touch to make your child's birthday stand out. Look no further! Our personalized birthday ecard animations are here to sprinkle that extra bit of magic—captivating adventures without the fuss and muss.</p>
                <p>Crafted with passion and precision, our team of expert designers and animators have conjured up ecards that transport your child into worlds of wonder. Dive into a treasure trove of themes—from enchanting fairytales to thrilling jungles and mystical dragons.</p>
                <p>Customization? It's a delightful breeze! Choose an avatar that mirrors your little star, and watch as their beaming face lights up the animation, making them the true hero of their special day.</p>
                <p>Concerned about navigating the digital realm? Fret not. Our platform boasts user-friendly charm, ensuring that creating magic is a joy for everyone—whether you're tech-savvy or a complete newbie. And should you ever find yourself in a maze of questions, our top-notch customer service team is on standby, ready to guide you with a smile.</p>
                <p>So, why wait? Join us in crafting unforgettable birthday moments that will be cherished for years to come.</p>
                <TitleB/>
            </div>
        )
    }
    const Avatar = ()=>{
        return(
            <div className="flex-col-center">
                <img src={profileImg} width="140" style={{borderRadius:"200px"}}/>
                <div className="font-30" style={{
                    fontFamily: 'Sacramento',
                    color:"#004975"
                }}>
                    Dan Berger - Founder
                </div>
            </div>
        )
    }
    return (
        <div className="relative flex-col-center full-width-screen">
            <Header/>
            <div className="relative full-width">
                <div className="relative flex-col-center full-width z-1">
                    <Title/>
                    <img src={dividerImg} height="50"/>
                    <Text/>
                    <StartButton fontSize="25"/>
                    <img src={dividerSmallImg} className="margin-40" height="30"/>
                </div>
                <Confetti/>
            </div>
            <Footer/>
        </div>
    )
}

export default About;

// end
