// StageD.js
import { useState, useEffect, useRef } from 'react';
import { useGlobalState } from "../../GlobalState";
import { useNavigate } from "react-router-dom";
import cakeA from "assets/images/editor/stageD/cakeA.png";
import cakeB from "assets/images/editor/stageD/cakeB.png";
import cakeC from "assets/images/editor/stageD/cakeC.png";
import vImg from "assets/images/general/v.svg";
import "./StageD.css";

const nameCanv = document.createElement('canvas');
const nameBgCanv = document.createElement('canvas');
const nameCanvasExport = document.createElement('canvas');
const nameBgCanvasExport = document.createElement('canvas');
const ageCanvasExport = document.createElement('canvas');
const ageCanvasExportB = document.createElement('canvas');
const dateTimeCanvas = document.createElement('canvas');
const adrsCanvas = document.createElement('canvas');
const comntCanvas = document.createElement('canvas');

const StageD = ()=>{
    // inits
    const [globalState, updateGlobalState] = useGlobalState();
    const navigate = useNavigate();

    // functions
    const drawAll = ()=>{
        const drawName = ()=>{
            const name = window.exportData.movie.name;
            const fontSize = 600;
            const lineWidth = fontSize/5;
            const textMargin = lineWidth/2;
            const nameMasterHeight = 350;
    
            //init name canvases
            const nameCtx = nameCanv.getContext('2d');
            const nameBgCtx = nameBgCanv.getContext('2d');
            nameCanv.height = fontSize+lineWidth*1.5;
            nameBgCanv.height = fontSize+lineWidth*1.5;
            nameCtx.font = 'bold '+fontSize+'px "Varela Round"';
            nameBgCtx.font = 'bold '+fontSize+'px "Varela Round"';
    
            const nameBufCtx = nameCanvasExport.getContext('2d');
            const nameBgBufCtx = nameBgCanvasExport.getContext('2d');
            nameCanvasExport.width = 1;
            nameCanvasExport.height = 1;
            nameBgCanvasExport.width = 1;
            nameBgCanvasExport.height = 1;
    
            nameCanv.width = nameCtx.measureText(name).width+textMargin*3;
            nameCanv.style.letterSpacing = '10px';
            nameBgCanv.width = nameBgCtx.measureText(name).width+textMargin*3;
            nameBgCanv.style.letterSpacing = '10px';
    
            nameCtx.lineCap = 'round';
            nameCtx.lineJoin = 'round';
            nameCtx.miterLimit = 1;
            nameCtx.font = fontSize+'px "Varela Round"';
            nameCtx.textAlign = "center";
            nameCtx.textBaseline = 'middle';
    
            nameBgCtx.lineCap = 'round';
            nameBgCtx.lineJoin = 'round';
            nameBgCtx.miterLimit = 1;
            nameBgCtx.font = fontSize+'px "Varela Round"';
            nameBgCtx.textAlign = "center";
            nameBgCtx.textBaseline = 'middle';
    
            nameCtx.strokeStyle = '#ff5858'//red
            nameCtx.lineWidth = lineWidth;
            nameCtx.strokeText(name,nameCanv.width/2,nameCanv.height/2);
            
            nameBgCtx.strokeStyle = '#ffffff'//white
            nameBgCtx.lineWidth = lineWidth;
            nameBgCtx.strokeText(name,nameBgCanv.width/2,nameBgCanv.height/2);
    
            nameCtx.strokeStyle = '#ffe058';//yellow
            nameCtx.lineWidth = lineWidth/1.5;
            nameCtx.strokeText(name,nameCanv.width/2,nameCanv.height/2);
    
            nameCtx.strokeStyle = '#58a3ff';//blue
            nameCtx.lineWidth = lineWidth/3;
            nameCtx.strokeText(name,nameCanv.width/2,nameCanv.height/2);
    
            //nameCtx.setLineDash([20, 10]);
            nameCtx.strokeStyle = '#ffffff';//white
            nameCtx.lineWidth = lineWidth/8;
            nameCtx.strokeText(name,nameCanv.width/2,nameCanv.height/2);
    
            nameCtx.fillStyle = '#58a3ff';//blue
            nameCtx.fillText(name,nameCanv.width/2,nameCanv.height/2);
    
            var factor = 1;
            var nWidth = nameCanv.width;
            var nHeight = nameCanv.height;
            if(nHeight>nameMasterHeight){//size for video
                factor = nameMasterHeight/nHeight;
                nWidth = nWidth*factor;
                nHeight = nHeight*factor;
            }
    
            nameCanvasExport.width = nWidth;
            nameCanvasExport.height = nHeight;
            nameBufCtx.imageSmoothingEnabled = true;
            nameBufCtx.imageSmoothingQuality = "high";
            nameBufCtx.clearRect(0,0,nWidth,nHeight);
            nameBufCtx.drawImage(nameCanv,0,0,nameCanv.width,nameCanv.height,0,0,nWidth,nHeight);
    
            nameBgCanvasExport.width = nWidth*1.3;
            nameBgCanvasExport.height = nHeight*1.3;
            nameBgBufCtx.imageSmoothingEnabled = true;
            nameBgBufCtx.imageSmoothingQuality = "high";
            nameBgBufCtx.clearRect(0,0,nWidth,nHeight);
            const x = (nameBgCanvasExport.width/2)-(nWidth/2);
            const y = (nameBgCanvasExport.height/2)-(nHeight/2);
            nameBgBufCtx.filter = 'blur(40px)';
            nameBgBufCtx.drawImage(nameBgCanv,0,0,nameBgCanv.width,nameBgCanv.height,x,y,nWidth,nHeight);
            nameBgBufCtx.drawImage(nameBgCanv,0,0,nameBgCanv.width,nameBgCanv.height,x,y,nWidth,nHeight);
            nameBgBufCtx.drawImage(nameBgCanv,0,0,nameBgCanv.width,nameBgCanv.height,x,y,nWidth,nHeight);
            nameBgBufCtx.filter = 'blur(0px)';
            nameBgBufCtx.drawImage(nameCanv,0,0,nameCanv.width,nameCanv.height,x,y,nWidth,nHeight);
    
            window.localImages.nameImg = nameCanvasExport;
            if(window.makeInvite) window.localImages.nameImgInvite = nameBgCanvasExport;
        }
        const drawAge = ()=>{
            const drawTextAlongArc = (context, str, gap, centerX, centerY, radius)=>{
                var len = str.length;
                var letterAngle = 0;
                var s;
    
                context.save();
                context.translate(centerX, centerY);
                for (var n = len-1; n >= 0; n--) {
                    s = str[n];
                    letterAngle = letterAngle+0.5*((context.measureText(s).width+gap) / radius);
                }
                context.rotate(-letterAngle);
    
                for (var n = len-1; n >= 0; n--) {
                    s = str[n];
                    letterAngle = 0.5*((context.measureText(s).width+gap) / radius);
    
                    context.rotate(letterAngle);
                    context.save();
    
                    context.translate(0, radius);
                    context.fillText(s, 0, 0);
                    context.restore();
    
                    context.rotate(letterAngle);
                }
                context.restore();
            }
            const age = window.exportData.movie.age;
            if(window.makeInvite){
                // text to write
                const txt = "IS TURNING "+age+"!";
                // set sizes
                const ageFontSize = 100;
                //init age canvases
                const ageBufCtx = ageCanvasExportB.getContext('2d');
                //text font
                ageBufCtx.font = ageFontSize+'px "Invite"';
                //measure width
                ageCanvasExportB.width = ageBufCtx.measureText(txt).width;
                ageCanvasExportB.height = ageFontSize;
                //text style again
                ageBufCtx.font = ageFontSize+'px "Invite"';
                ageBufCtx.fillStyle = '#ffffff';
                ageBufCtx.textAlign = "center";
                ageBufCtx.textBaseline = 'middle';
                //draw text
                ageBufCtx.fillText(txt,ageCanvasExportB.width/2,ageCanvasExportB.height/2);
            }
            const ageMasterSize = {w:486,h:158};
            const ageFontSize = 60;
            //init age canvases
            const ageBufCtx = ageCanvasExport.getContext('2d');
            ageCanvasExport.width = ageMasterSize.w;
            ageCanvasExport.height = ageMasterSize.h;

            //text style
            ageBufCtx.fillStyle = '#ffffff';
            ageBufCtx.font = 'bold '+ageFontSize+'px "Varela Round"';
            ageBufCtx.lineJoin = 'round';
            ageBufCtx.miterLimit = 1;
            ageBufCtx.textAlign = "center";
            ageBufCtx.textBaseline = 'top';

            if(age=='Age:')
                age = '';
            var txt = age+' YEAR';
            if(age!='1') txt = txt+'S';
            txt = txt+' OLD';
            var ageLen = age.length;
            var gap = 0;
            if(ageLen==2) gap = -2;
            else if(ageLen==3) gap = -5;

            const radius = 500;//size of circle
            const centerX = ageCanvasExport.width / 2;
            const centerY = ageCanvasExport.height/2-radius;

            ageBufCtx.clearRect(0,0,ageCanvasExport.width,ageCanvasExport.height);
            drawTextAlongArc(ageBufCtx, txt, gap, centerX, centerY, radius);
            
            window.localImages.ageImgInvite = ageCanvasExportB;
            window.localImages.ageImg = ageCanvasExport;
        }
        const drawDateTime = ()=>{
            // get data
            const day = window.exportData.invite.day;
            const month = window.exportData.invite.month;
            const time = window.exportData.invite.time;
            const ampm = window.exportData.invite.ampm;
            if(day && month && time && ampm){
                // set text
                const dateTime = day+" "+month+"  /  "+time+" "+ampm;
                // set sizes
                const fontSize = 300;
                const lineWidth = fontSize/3;
                const letterSpacing = "5px"
    
                // measure dimensions
                const ctx = dateTimeCanvas.getContext('2d');
                ctx.font = 'bold '+fontSize+'px "Varela Round"';
                ctx.letterSpacing = letterSpacing;
                ctx.lineCap = 'round';
                ctx.lineJoin = 'round';
                ctx.miterLimit = 1;
                ctx.textAlign = "center";
                ctx.textBaseline = 'middle';
                dateTimeCanvas.width = ctx.measureText(dateTime).width+lineWidth;
                dateTimeCanvas.height = fontSize+lineWidth;
    
                // reset style
                ctx.font = 'bold '+fontSize+'px "Varela Round"';
                ctx.letterSpacing = letterSpacing;
                ctx.lineCap = 'round';
                ctx.lineJoin = 'round';
                ctx.miterLimit = 1;
                ctx.textAlign = "center";
                ctx.textBaseline = 'middle';
    
                // set pos
                const dateX = dateTimeCanvas.width/2;
                const dateY = dateTimeCanvas.height/2;
    
                // draw
                // ctx.fillStyle = "black";
                // ctx.fillRect(0,0,dateTimeCanvas.width,dateTimeCanvas.height);
                ctx.strokeStyle = '#3288CE';
                ctx.lineWidth = lineWidth;
                ctx.strokeText(dateTime,dateX,dateY);
                ctx.strokeStyle = '#ffffff'
                ctx.lineWidth = lineWidth/15;
                ctx.strokeText(dateTime,dateX,dateY);
                ctx.fillStyle = '#ffffff';
                ctx.fillText(dateTime,dateX,dateY);
    
                // assign to window
                window.localImages.dateTimeImage = dateTimeCanvas;
            }
        }
        const drawAdrs = ()=>{
            // get data
            const lineA = window.exportData.invite.adrsA?window.exportData.invite.adrsA:"";
            const lineB = window.exportData.invite.adrsB?window.exportData.invite.adrsB:"";
            
            // set sizes
            const fontSize = 300;
            const lineWidth = fontSize/3;
            const letterSpacing = "30px"
    
            // measure dimensions
            const ctx = adrsCanvas.getContext('2d');
            ctx.font = 'bold '+fontSize+'px "Varela Round"';
            ctx.letterSpacing = letterSpacing;
            ctx.lineCap = 'round';
            ctx.lineJoin = 'round';
            ctx.miterLimit = 1;
            ctx.textAlign = "center";
            ctx.textBaseline = 'top';
            const widthA = ctx.measureText(lineA).width+lineWidth;
            const widthB = ctx.measureText(lineB).width+lineWidth;
            adrsCanvas.width = widthA>widthB?widthA:widthB;
            adrsCanvas.height = (fontSize+(lineWidth*2))*2;
    
            // reset style
            ctx.font = 'bold '+fontSize+'px "Varela Round"';
            ctx.letterSpacing = letterSpacing;
            ctx.lineCap = 'round';
            ctx.lineJoin = 'round';
            ctx.miterLimit = 1;
            ctx.textAlign = "center";
            ctx.textBaseline = 'top';
    
            // set pos
            const tmpX = adrsCanvas.width/2;
            var tmpY = lineWidth;
    
            // draw
            // ctx.fillStyle = "black";
            // ctx.fillRect(0,0,adrsCanvas.width,adrsCanvas.height);
            ctx.clearRect(0,0,adrsCanvas.width,adrsCanvas.height);
            ctx.strokeStyle = '#3288CE';
            ctx.lineWidth = lineWidth;
            ctx.strokeText(lineA,tmpX,tmpY);
            // ctx.strokeStyle = '#ffffff'
            // ctx.lineWidth = lineWidth/15;
            // ctx.strokeText(lineA,tmpX,tmpY);
            ctx.fillStyle = '#ffffff';
            ctx.fillText(lineA,tmpX,tmpY);
    
            tmpY = tmpY+fontSize+lineWidth;
            ctx.strokeStyle = '#3288CE';
            ctx.lineWidth = lineWidth;
            ctx.strokeText(lineB,tmpX,tmpY);
            // ctx.strokeStyle = '#ffffff'
            // ctx.lineWidth = lineWidth/15;
            // ctx.strokeText(lineB,tmpX,tmpY);
            ctx.fillStyle = '#ffffff';
            ctx.fillText(lineB,tmpX,tmpY);
    
            // assign to window
            window.localImages.adrsImage = adrsCanvas;
        }
        const drawComnt = ()=>{
            // get data
            const lineA = window.exportData.invite.comntA?window.exportData.invite.comntA:"";
            const lineB = window.exportData.invite.comntB?window.exportData.invite.comntB:"";
            
            // set sizes
            const fontSize = 300;
            const lineWidth = fontSize/4;
            const letterSpacing = "10px"
    
            // measure dimensions
            const ctx = comntCanvas.getContext('2d');
            ctx.font = 'bold '+fontSize+'px "Varela Round"';
            ctx.letterSpacing = letterSpacing;
            ctx.lineCap = 'round';
            ctx.lineJoin = 'round';
            ctx.miterLimit = 1;
            ctx.textAlign = "center";
            ctx.textBaseline = 'top';
            const widthA = ctx.measureText(lineA).width+lineWidth;
            const widthB = ctx.measureText(lineB).width+lineWidth;
            comntCanvas.width = widthA>widthB?widthA:widthB;
            comntCanvas.height = (fontSize+(lineWidth*2))*2;
    
            // reset style
            ctx.font = 'bold '+fontSize+'px "Varela Round"';
            ctx.letterSpacing = letterSpacing;
            ctx.lineCap = 'round';
            ctx.lineJoin = 'round';
            ctx.miterLimit = 1;
            ctx.textAlign = "center";
            ctx.textBaseline = 'top';
    
            // set pos
            const tmpX = comntCanvas.width/2;
            var tmpY = lineWidth;
    
            // draw
            // ctx.fillStyle = "black";
            // ctx.fillRect(0,0,comntCanvas.width,comntCanvas.height);
            ctx.clearRect(0,0,comntCanvas.width,comntCanvas.height);
            ctx.strokeStyle = '#3288CE';
            ctx.lineWidth = lineWidth;
            ctx.strokeText(lineA,tmpX,tmpY);
            // ctx.strokeStyle = '#ffffff'
            // ctx.lineWidth = lineWidth/15;
            // ctx.strokeText(lineA,tmpX,tmpY);
            ctx.fillStyle = '#ffffff';
            ctx.fillText(lineA,tmpX,tmpY);
    
            tmpY = tmpY+fontSize+lineWidth;
            ctx.strokeStyle = '#3288CE';
            ctx.lineWidth = lineWidth;
            ctx.strokeText(lineB,tmpX,tmpY);
            // ctx.strokeStyle = '#ffffff'
            // ctx.lineWidth = lineWidth/15;
            // ctx.strokeText(lineB,tmpX,tmpY);
            ctx.fillStyle = '#ffffff';
            ctx.fillText(lineB,tmpX,tmpY);
    
            // assign to window
            window.localImages.comntImage = comntCanvas;
        }
        drawName();
        drawAge();
        drawDateTime();
        drawAdrs();
        drawComnt();
    } 
    const checkNext = ()=>{
        try{
            if(window.exportData.movie.name=='') throw Error("Enter a name.");
            else if(window.exportData.movie.age=='') throw Error("Choose an age.");
            else if(window.makeInvite){
                if(!window.exportData.invite.day || window.exportData.invite.day=='') throw Error("Choose a day.");
                else if(!window.exportData.invite.month || window.exportData.invite.month=='') throw Error("Choose a month.");
                else if(!window.exportData.invite.time || window.exportData.invite.time=='') throw Error("Choose a time.");
                else if(!window.exportData.invite.adrsA || window.exportData.invite.adrsA=='') throw Error("Enter an Address in line 1.");
                else if(!window.exportData.invite.adrsB || window.exportData.invite.adrsB=='') throw Error("Enter an Address in line 2.");
                else if(!window.exportData.invite.comntA || window.exportData.invite.comntA=='') throw Error("Enter a Comment in line 1.");
                else if(!window.exportData.invite.comntB || window.exportData.invite.comntB=='') throw Error("Enter a Comment in line 2.");
                else if(window.exportData.movie.cake==null) throw Error("Choose a cake.");
                else{
                    window.tooltip = null;
                    updateGlobalState("next", true);
                }
            }
            else if(window.exportData.movie.cake==null) throw Error("Choose a cake.");
            else{
                window.tooltip = null;
                updateGlobalState("next", true);
            }
        }
        catch(err){
            window.tooltip = err.message;
            updateGlobalState("next", false);
        }     
    }

    // effect
    useEffect(()=>{
        window.scrollTo(0, 0);
        updateGlobalState("stage",4);
        checkNext();
        window.backFunc = ()=>{
            window.tooltip = null;
            navigate('/editor/c');
        }
        window.nextFunc = ()=>{
            drawAll();
            // if(window.makeInvite) navigate('/editor/f');
            // else navigate('/editor/e/1');
            // navigate('/editor/e/1');
            navigate('/editor/f');
        }
    },[])

    // comps
    const Title = ()=>{
        return(
            <div className="flex-col-center" style={{maxWidth:"90vw"}}>
                <div className="font-30 title-font text-dark-blue text-center">
                    Almost done!
                </div>
                <div className="font-20 text-dark-blue text-center">
                    {window.makeInvite?"Enter the details below.":"Enter the Name, Age and choose a cake."}
                </div>
            </div>
        )
    }
    const Stage = ()=>{
        const Inputs = ()=>{
            const NameInput = ()=>{
                const input = useRef();
                const handleChange = (e)=>{
                    const name = e.target.value;
                    window.exportData.movie.name = name;
                    checkNext();
                }
                useEffect(()=>{
                    if(window.exportData.movie.name) input.current.value = window.exportData.movie.name;
                },[])
                return(
                    <input type="text" ref={input} className="font-20 margin-10" placeholder="Name" onInput={handleChange} style={{width:"150px"}}/>
                )
            }
            const AgeInput = ()=>{
                const input = useRef();
                const handleChange = (e)=>{
                    const age = e.target.value;
                    window.exportData.movie.age = age;
                    checkNext();
                }
                useEffect(()=>{
                    for(var i = 1; i <= 120; i++) {
                        const option = document.createElement('option');
                        option.text = option.value = i;
                        input.current.add(option);
                    }
                    if(window.exportData.movie.age) input.current.value = window.exportData.movie.age;
                },[])
                return(
                    <select ref={input} defaultValue='' className='font-20' onChange={handleChange}>
                        <option disabled value=''>Age:</option>
                    </select>
                )
            }
            const InviteInputs = ()=>{
                const DayInput = ()=>{
                    const input = useRef();
                    const handleChange = (e)=>{
                        const day = e.target.value;
                        window.exportData.invite.day = day;
                        checkNext();
                    }
                    useEffect(()=>{
                        for(var i = 1; i <= 31; i++) {
                            const option = document.createElement('option');
                            option.text = option.value = i;
                            input.current.add(option);
                        }
                        if(window.exportData.invite.day) input.current.value = window.exportData.invite.day;
                    },[])
                    return(
                        <select ref={input} defaultValue='' className='font-20' onChange={handleChange}>
                            <option disabled value=''>Day:</option>
                        </select>
                    )
                }
                const MonthInput = ()=>{
                    const input = useRef();
                    const months = [
                        "JAN",
                        "FEB",
                        "MAR",
                        "APR",
                        "MAY",
                        "JUN",
                        "JUL",
                        "AUG",
                        "SEP",
                        "OCT",
                        "NOV",
                        "DEC"
                    ]
                    const handleChange = (e)=>{
                        const month = e.target.value;
                        window.exportData.invite.month = month;
                        checkNext();
                    }
                    const Option = ({label})=>{
                        return(
                            <option value={label}>
                                {label}
                            </option>
                        )
                    }
                    return(
                        <select ref={input} defaultValue={window.exportData.invite.month?window.exportData.invite.month:""} className='font-20' onChange={handleChange}>
                            <option disabled value=''>Month:</option>
                            {months.map((month, index)=><Option label={month} key={index}/>)}
                        </select>
                    )
                }
                const TimeInput = ()=>{
                    const input = useRef();
                    const handleChange = (e)=>{
                        const time = e.target.value;
                        window.exportData.invite.time = time;
                        checkNext();
                    }
                    useEffect(()=>{
                        const minutes = [
                            "",
                            ":15",
                            ":30",
                            ":45"
                        ]
                        for(var i = 1; i <= 12; i++){
                            const hour = i;
                            minutes.forEach(minute => {
                                const option = document.createElement('option');
                                option.text = option.value = hour+minute;
                                input.current.add(option);
                            });
                        }
                        if(window.exportData.invite.time) input.current.value = window.exportData.invite.time;
                    },[])
                    return(
                        <select ref={input} defaultValue='' className='font-20' onChange={handleChange}>
                            <option disabled value=''>Time:</option>
                        </select>
                    )
                }
                const AmpmInput = ()=>{
                    const handleChange = (e)=>{
                        const ampm = e.target.value;
                        window.exportData.invite.ampm = ampm;
                    }
                    return(
                        <select defaultValue={window.exportData.invite.ampm?window.exportData.invite.ampm:"AM"} className='font-20' onChange={handleChange}>
                            <option value='AM'>AM</option>
                            <option value='PM'>PM</option>
                        </select>
                    )
                }
                const AdrsInput = ()=>{
                    const inputAref = useRef();
                    const inputBref = useRef();
                    const handleChange = (e)=>{
                        const value = e.target.value;
                        window.exportData.invite["adrs"+e.target.id] = value;
                        checkNext();
                    }
                    return(
                        <div className='flex-col-center margin-10'>
                            <input ref={inputAref} id="A" className="font-20" type="text" defaultValue={window.exportData.invite.adrsA?window.exportData.invite.adrsA:""} placeholder='Address Line 1' onChange={handleChange}/>
                            <div className='margin-5'/>
                            <input ref={inputBref} id="B" className="font-20" type="text" defaultValue={window.exportData.invite.adrsB?window.exportData.invite.adrsB:""} placeholder='Address Line 2' onChange={handleChange}/>
                        </div>
                    )
                }
                const ComntInput = ()=>{
                    const inputAref = useRef();
                    const inputBref = useRef();
                    const handleChange = (e)=>{
                        const value = e.target.value;
                        window.exportData.invite["comnt"+e.target.id] = value;
                        checkNext();
                    }
                    return(
                        <div className='flex-col-center'>
                            <input ref={inputAref} id="A" className="font-20" type="text" defaultValue={window.exportData.invite.comntA?window.exportData.invite.comntA:""} placeholder='Comment Line 1' onChange={handleChange}/>
                            <div className='margin-5'/>
                            <input ref={inputBref} id="B" className="font-20" type="text" defaultValue={window.exportData.invite.comntB?window.exportData.invite.comntB:""} placeholder='Comment Line 2' onChange={handleChange}/>
                        </div>
                    )
                }
                return(
                    <div className='flex-col-center'>
                        <div className="flex-row-center">
                            <DayInput/>
                            <div className='margin-5'/>
                            <MonthInput/>
                        </div>
                        <div className='margin-5'/>
                        <div className="flex-row-center">
                            <TimeInput/>
                            <div className='margin-5'/>
                            <AmpmInput/>
                        </div>
                        <AdrsInput/>
                        <ComntInput/>
                    </div>
                )
            }
            
            return(
                <div className="flex-col-center">
                    <div className="flex-row-center flex-wrap">
                        <NameInput/>
                        <AgeInput/>
                    </div>
                    {window.makeInvite?<InviteInputs/>:""}
                </div>
            )
        }
        const Cakes = ()=>{
            const [cake, setCake] = useState();
            const cakes = [
                cakeA,
                cakeB,
                cakeC
            ]
            const Cake = (props)=>{
                const Chosen = ()=>{
                    return(
                        <div className="local-overlay green-border b-r-20">
                            <img src={vImg} className="absolute" style={{height:"20%",top:"2px",right:"2px"}} />
                        </div>
                    )
                }
                const handleClick = ()=>{
                    window.exportData.movie.cake = props.index;
                    checkNext();
                    setCake(props.index);
                }
                useEffect(()=>{
                    setCake(window.exportData.movie.cake);
                },[])
                return(
                    <div className="relative flex-col-center" style={{width:"30%"}}>
                        <img src={props.src} className="link grow-small full-width" onClick={handleClick}/>
                        {cake==props.index?<Chosen/>:""}
                    </div>
                )
            }
            return(
                <div className="flex-row-center flex-wrap margin-10">
                    {cakes.map((src,index)=><Cake src={src} index={index} key={index}/>)}
                </div>
            )
        }
        return(
            <div className="relative flex-col-center bg-dark-blue-2 padding-20 b-r-20 margin-10" style={{
                width:"700px",
                maxWidth:"95vw"
            }}>
                <Inputs/>
                <Cakes/>
            </div>
        )
    }

    // render
    return (
        <div className="flex-col-center">
            <Title/>
            <Stage/>
        </div>
    )
}
export default StageD;

// end
