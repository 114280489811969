// Refund.js
import Menu from "comps/Menu/Menu";
import Footer from "comps/Footer/Footer";
import "./Refund.css";

const Refund = ()=>{
    const Header = ()=>{
        return(
            <div className="flex-col-center hero-container full-width padding-20">
                <Menu/>
            </div>
        )
    }
    const Text = ()=>{
        return(
            <div className='flex-col-center text-dark-blue' style={{width:"90%",maxWidth:"700px"}}>
                <div className='full-width text-center title-font font-30 margin-20'>
                    Toon Kids Refund Policy
                </div>
                <div className="full-width font-20" style={{textAlign:"left"}}>
                <p>At Toon Kids, we are committed to providing a delightful and personalized experience for your child’s special day. We understand that circumstances may arise where you may need to request a refund. Our refund policy is designed to be clear and fair, ensuring customer satisfaction while maintaining the quality of our service.</p>
                    <h2>Refund Policy</h2>
                    <h3>Eligibility for Refunds</h3>
                    <ul>
                        <li>Refunds requested within the 30 days from the time of purchase are automatically granted.</li>
                        <li>Refunds requested after that period will be considered if the service was not delivered as described or if there were significant issues with the animation quality or customization.</li>
                    </ul>

                    <h3>Non-Refundable Cases (for claims made after 30 days from the time of purchase)</h3>
                    <ul>
                        <li>Customized animations that have been successfully created and delivered as per the order specifications.</li>
                        <li>Change of mind after the animation has been delivered.</li>
                        <li>Issues caused by incorrect information provided by the user during the customization process.</li>
                    </ul>

                    <h3>How to Request a Refund</h3>
                    <p>To request a refund, please contact our customer support team at <a href="mailto:support@toonkids.com">support@toonkids.com</a> with your order details, the reason for the refund request, and any supporting evidence if applicable. Our team will review your request and respond within 3-5 business days.</p>

                    <h3>Refund Process</h3>
                    <ul>
                        <li>If your refund request is approved, the refund will be processed, and a credit will automatically be applied to your original method of payment within 10 business days.</li>
                        <li>You will receive an email notification once the refund has been processed.</li>
                    </ul>

                    <h3>Partial Refunds</h3>
                    <p>In some cases, only partial refunds may be granted (if applicable), such as for services that were partially delivered.</p>

                    <h3>Contact Us</h3>
                    <p>If you have any questions about our refund policy, please contact us at <a href="mailto:support@toonkids.com">support@toonkids.com</a>.</p>

                    <p>We value your satisfaction and are here to ensure your experience with Toon Kids is a positive one. Thank you for choosing our platform to create memorable birthday animations for your children.</p>
                </div>
            </div>
        )
    }
    return (
        <div className="relative full-width-screen flex-col-center">
            <Header/>
            <Text/>
            <Footer/>
        </div>
    )
}
export default Refund;

// end
