// StageE1.js
import { useState, useEffect, useRef } from 'react';
import { useGlobalState } from "../../GlobalState";
import { useNavigate } from "react-router-dom";
import Globals from "assets/modules/Globals";
import Overlay from 'comps/Overlay/Overlay';
import Close from 'comps/Close/Close';
import playImg from "assets/images/general/play.svg";
import trailerVid from "assets/videos/trailer.mp4";
import loaderGif from "assets/images/general/loader.gif";
import "./StageE1.css";

const StageE1 = ()=>{
    // inits
    const [globalState, updateGlobalState] = useGlobalState();
    const navigate = useNavigate();

    // effect
    useEffect(()=>{
        window.scrollTo(0, 0);
        updateGlobalState("stage",5);
        updateGlobalState("back", true);
        if(window.prevUrl) navigate('/editor/e/2');
        else{
            updateGlobalState("next", false);
            window.backFunc = ()=>{
                navigate('/editor/f');
            }
            window.nextFunc = ()=>{
                window.makePreview = false;
                navigate('/editor/checkout?uid='+window.uid+"&mid="+window.mid+"&email="+window.email);
                // navigate('/editor/f');
            }
        }
    },[])

    // comps
    const Title = ()=>{
        return(
            <div className="flex-col-center" style={{
                width:"700px",
                maxWidth:"90vw"
            }}>
                <div className="font-30 title-font text-dark-blue text-center">
                    {window.makeInvite?"Here are your cards!":"Here's a little taste!"}
                </div>
                <div className="font-20 text-dark-blue text-center">
                    {window.makeInvite?"Click an image to download & save it.":'To get your movie click one of the buttons below :)'}
                    <br/>
                    To make changes click the "Back" button.
                </div>
            </div>
        )
    }
    
    const Stage = ()=>{
        // init
        const [showOvr, setShowOvr] = useState();        

        // comps
        const MovOverlay = ()=>{
            const Comp = ()=>{
                const [showPlay, setShowPlay] = useState(true);      
                const Button = ()=>{
                    const handleClick = ()=>{
                        navigate('/editor/f');
                    }
                    return(
                        <div onClick={handleClick} className="relative font-20 z-1 link grow text-white title-font start-button margin-10">
                            Yes I want one!
                        </div>
                    )
                }
                const Cover = ()=>{
                    const handlePlay = ()=>{
                        setShowPlay(false);
                    }
                    return(
                        <div className="relative flex-col-center">
                            <img src={window.thumbImgSrc} className="b-r-20 shadow" style={{
                                width: "500px",
                                maxWidth: "100%"
                            }}/>
                            <img src={playImg} className="absolute link grow z-10" style={{width:"25%"}} onClick={handlePlay}/>
                        </div>
                    )
                }
                const Video = ()=>{
                    return <video src={trailerVid} poster={window.thumbImgSrc} className="b-r-20" controlsList="nodownload noplaybackrate" onContextMenu={()=>false} playsInline controls autoPlay disablePictureInPicture preload="auto" style={{
                        width: "500px",
                        maxWidth: "100%"
                    }}/>
                }
                return(
                    <div className='relative flex-col-center padding-30 blue-bg b-r-20' style={{
                        width: "700px",
                        maxWidth: "90vw"
                    }}>
                        <div className='font-20 text-white text-center'>
                            The card has been saved to your device!
                        </div>
                        <div className='font-25 title-font text-white text-center'>
                            Add a Matching Personalized Birthday Animation!
                        </div>
                        <div className='font-20 text-white text-center'>
                            (**The final movie will feature your kid with their name, age and the choices you've made)
                        </div>
                        <Button/>
                        {showPlay?<Cover/>:<Video/>}
                        <Close action={()=>{setShowOvr(false)}}/>
                    </div>
                )
            }
            return <Overlay top={10} comp={<Comp/>} closeAction={()=>{setShowOvr(false)}}/>
        }
        const Invites = ()=>{
            const handleDown = (evt)=>{
                window.pushData("invite-download");
                const type = evt.target.id;
                const link = document.createElement('a');
                link.href = window.downLinks[type];
                link.click();
            }
            const path = Globals.storageDomain+'/users/'+window.uid+'/movies/'+window.mid+'/invites';
            return(
                <>
                        <div onClick={()=>{navigate('/editor/checkout?uid='+window.uid+"&mid="+window.mid+"&email="+window.email)}} className="relative font-20 text-center z-1 link grow text-white title-font start-button margin-10" style={{
                            padding: "20px"
                        }}>
                            Get a Matching Personalized Animated Movie!
                        </div>
                        <div className='relative flex-col-center full-width'>
                            <img src={path+"/landscape.jpg"} id="landscape" className="full-width b-r-20 link" onClick={handleDown}/>
                        </div>
                        <div className='margin-10'/>
                        <div className='relative flex-col-center full-width'>
                            <img src={path+"/portrait.jpg"} id="portrait" className="full-width b-r-20 link" onClick={handleDown}/>
                        </div>         
                </>
            )
        }
        const Thumb = ()=>{
            const src = Globals.storageDomain+'/users/'+window.uid+'/movies/'+window.mid+'/assets/thumb.jpg';
            return(
                <div className='relative full-width flex-col-center'>
                    <img src={src} className="absolute full-width b-r-20 z-2"/>
                    <img src={loaderGif} className="absolute z-1" height="30"/>
                    <canvas width={1920} height={1080} className='full-width b-r-20 blue-bg'/>
                </div>
            )
        }

        // main
        return(
            <div className="flex-col-center margin-10">
                <div className="relative flex-col-center" style={{
                    width:"500px",
                    maxWidth:"90vw"
                }}>
                    {window.makeInvite?<Invites/>:<Thumb/>}
                </div>
                {showOvr?<MovOverlay/>:""}
            </div>
        )
    }
    const Buttons = ()=>{
        const PrevButton = ()=>{
            const handleClick = ()=>{
                window.makePreview = true;
                navigate('/editor/e/2');
                // navigate('/editor/f');
            }
            return(
                <button className="font-20 margin-5" onClick={handleClick}>
                    Get Preview 00:05 Movie! (Free)
                </button>
            )
        }
        const FullButton = ()=>{
            const handleClick = ()=>{
                window.makePreview = false;
                navigate('/editor/checkout?uid='+window.uid+"&mid="+window.mid+"&email="+window.email);
                // navigate('/editor/f');
            }
            return(
                <button className="red-button font-20 margin-5" onClick={handleClick}>
                    Get Full 02:15 Movie! ($4.99)
                </button>
            )
        }
        return(
            <div className="flex-row-center flex-wrap">
                    <FullButton/>
                    <PrevButton/>
            </div>
        )
    }

    // render
    return(
        <div className="flex-col-center">
            <Title/>
            {window.makeInvite?"":<Buttons/>}
            <Stage/>          
        </div>
    )
}

export default StageE1;

// end
