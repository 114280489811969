// ErrMsg.js
import "./ErrMsg.css";

const ErrMsg = (props)=>{
    var margin = 10;
    if(props.margin) margin = props.margin;
    const Msg = ()=>{
        return(
            <div className="font-15 text-center text-red white-bg b-r-20 padding-10" style={{display:"inline-block",margin: (margin+"px")}}>
                {props.msg}
            </div>
        )
    }
    const Holder = ()=>{
        return(
            <div className="font-15 text-center text-red b-r-20 padding-10" style={{margin: (margin+"px")}}>
                &nbsp;
            </div>
        )
    }
    return (
        props.msg?<Msg/>:<Holder/>
    )
}
export default ErrMsg;

// end
