// StageA.js
import { useState, useEffect } from 'react';
import { useGlobalState } from "../../GlobalState";
import { useNavigate, useSearchParams } from "react-router-dom";
import Invite from "comps/Invite/Invite";
import Overlay from 'comps/Overlay/Overlay';
import Text from "assets/modules/Text";
import arrowImg from "assets/images/general/arrow.png";
import playImg from "assets/images/general/play.svg";
import fairyPoster from "assets/images/editor/stageA/fairytale_poster.jpg";
import fantasyPoster from "assets/images/editor/stageA/fantasy_poster.jpg";
import junglePoster from "assets/images/editor/stageA/jungle_poster.jpg";
import soonPoster from "assets/images/editor/stageA/soon_poster.jpg";
import fairyVid from "assets/videos/fairytale.mp4";
import fantasyVid from "assets/videos/fantasy.mp4";
import jungleVid from "assets/videos/jungle.mp4";
import closeImg from "assets/images/general/close.svg";
import vImg from "assets/images/general/v.svg";
import "./StageA.css";

var init = true;

const StageA = ()=>{
    //inits
    const [globalState, updateGlobalState] = useGlobalState();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const movTypes = [
        {
            name: "fairytale",
            poster: fairyPoster,
            vid: fairyVid
        },
        {
            name: "fantasy",
            poster: fantasyPoster,
            vid: fantasyVid
        },
        {
            name: "jungle",
            poster: junglePoster,
            vid: jungleVid
        }
    ]
    //states
    const [initMsg, setInitMsg] = useState(init);
    const [selected, setSelected] = useState();

    // effect
    useEffect(()=>{
        updateGlobalState('init', true);
        const inviteType = searchParams.get('invite');
        if(inviteType){
            init = false;
            window.makeInvite = true;
            window.inviteType = inviteType;
            window.exportData.movie.type = inviteType;
            navigate('/editor/b/1');
        }
        else{
            window.scrollTo(0, 0);
            updateGlobalState("stage",1);
            updateGlobalState("next", false);
            updateGlobalState("back", false);
            window.backFunc = ()=>{
                return true;
            }
            window.nextFunc = ()=>{
                window.setScroll(true);
                navigate('/editor/b/1');
                updateGlobalState("highNext",false);
            }
            if(window.exportData.movie.type){
                setSelected(window.exportData.movie.type);
                updateGlobalState("next", true);
            }
        }
    },[])

    //comps
    
    const Main = ()=>{
        const Title = ()=>{
            return(
                <div className="flex-col-center" style={{maxWidth:"95vw"}}>
                    <div className="font-30 title-font text-dark-blue text-center">
                        First let's choose a story!
                    </div>
                    <div className="font-20 text-dark-blue text-center">
                        When you are ready click the "Next" button at the bottom.
                    </div>
                </div>
            )
        }
        const Movies = ()=>{
            const Movie = (props)=>{
                const [showVid, setShowVid] = useState(false);
    
                const doShowVid = ()=>{
                    setShowVid(true);
                }
                const doHideVid = ()=>{
                    setShowVid(false);
                    chooseMovie();
                }
                const chooseMovie = ()=>{
                    window.exportData.movie.type = movTypes[props.index].name;
                    setSelected(window.exportData.movie.type);
                    updateGlobalState("highNext",true);
                    updateGlobalState("next",true);
                }
    
                const VidOverlay = ()=>{
                    return(
                        <div className="overlay flex-col-center">
                            <div className="relative z-1" style={{
                                width:"900px",
                                maxWidth:"95%"
                            }}>
                                <video src={movTypes[props.index].vid} poster={movTypes[props.index].poster} className="full-width b-r-20" controls controlsList="nodownload noplaybackrate" onContextMenu={()=>false} playsInline disablePictureInPicture preload="auto" autoPlay />
                                <img src={closeImg} className="absolute link z-2" width="50" onClick={doHideVid} style={{
                                    top: "0px",
                                    right: "0px",
                                    transform: "translate(50%,-50%)"
                                }}/>
                            </div>
                            <div className="bg-dark-blue" onClick={doHideVid}/>
                        </div>
                    )
                }
                const Selected = ()=>{
                    return(
                        <div className="flex-col-center local-overlay green-border z-5 b-r-20 non-interact"/>
                    )
                }
                const SelV = ()=>{
                    return(
                        <img src={vImg} className="absolute link grow z-5" style={{width:"25%"}}/>
                    )
                }
                return(
                    <div
                        className="relative flex-col-start-center padding-20 b-r-20 blue-bg margin-10"
                        style={{
                            width:"300px",
                            maxWidth:"90%",
                            cursor:"default"
                    }}>
                        <div className="font-20 ul title-font text-white text-center link" onClick={chooseMovie}>
                            {Text.movTitle[props.index]}
                        </div>
                        <div className="relative flex-col-center full-width margin-10 z-2" onClick={doShowVid}>
                            {movTypes[props.index].name==selected?<SelV/>:""}
                            <img src={playImg} className="absolute link grow" style={{width:"25%"}}/>
                            <img src={movTypes[props.index].poster} className="b-r-20 full-width link"/>
                        </div>
                        <div className="font-20 text-white link" onClick={chooseMovie}>
                            {Text.movDesc[props.index]}
                        </div>
                        {movTypes[props.index].name==selected?<Selected/>:""}
                        {showVid?<VidOverlay/>:""}
                        <div className='local-overlay link' onClick={chooseMovie}/>
                    </div>
                )
            }
            const Soon = (props)=>{
                return(
                    <div className="relative flex-col-start-center padding-20 b-r-20 blue-bg margin-10" style={{
                        width:"300px",
                        maxWidth:"90%"
                    }}>
                        <div className="font-20 ul title-font text-white text-center">
                            {Text.movTitle[props.index]}
                        </div>
                        <div className="relative flex-col-center full-width margin-10">
                            <img src={soonPoster} className="b-r-20 full-width"/>
                        </div>
                    </div>
                )
            }
            return(
                <div className="flex-row-center flex-wrap" style={{alignItems: "stretch",maxWidth:"1000px"}}>
                    <Movie index={0}/>
                    <Movie index={1}/>
                    <Movie index={2}/>
                    <Soon index={3}/>
                    <Soon index={4}/>
                    <Soon index={5}/>
                </div>
            )
        }
        const InitMessage = ()=>{
            const closeAction = ()=>{
                init = false;
                window.setScroll(true);
                setInitMsg(false)
            }
            const Message = ()=>{
                return(
                    <div className='flex-col-center'>
                        <div className='flex-col-center padding-20 blue-bg b-r-20'>
                            <div className='title-font text-white font-25 text-center'>
                                <span className='ul title-font text-white font-25 text-center'>After</span> you choose a movie click the "Next" button at the bottom!
                            </div>
                            <div className='margin-10'/>
                            <button className='relative title-font text-white font-30 text-center' onClick={closeAction}>
                                OK got it!
                            </button>
                        </div>
                        <div className='margin-10'/>
                        <img src={arrowImg} style={{height:"20vh"}}/>
                    </div>
                )
            }
            return <Overlay comp={<Message/>} closeAction={()=>{return true;}}/>
        }
        //render
        return (
            <div className="flex-col-center">
                <Title/>
                <Movies/>
                {initMsg?<InitMessage/>:""}
            </div>
        )
    }
    //render
    return window.makeInvite?<Invite/>:<Main/>
}

export default StageA;

// end
